import { defineMessages } from 'react-intl';

export default defineMessages({
  footerViewAllSites: {
    defaultMessage: 'View all sites',
    description: 'Footer - view all sites',
    id: 'footer.viewAllSites',
  },
  recentSitesDataGridColumnsSiteName: {
    defaultMessage: 'Site name',
    description: 'Recent Sites Data Grid - site name column',
    id: 'recentSitesDataGrid.columns.siteName',
  },
  recentSitesDataGridColumnsSiteType: {
    defaultMessage: 'Site type',
    description: 'Recent Sites Data Grid - site type column',
    id: 'recentSitesDataGrid.columns.siteType',
  },
  recentSitesDataGridColumnsStatus: {
    defaultMessage: 'Mobile Video status',
    description: 'Recent Sites Data Grid - mobile video status',
    id: 'recentSitesDataGrid.columns.status',
  },
});
