import { yupResolver } from '@hookform/resolvers/yup';
import DoneIcon from '@mui/icons-material/Done';
import { MenuItem, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useGetCompanyQuery } from '../../apiRtk/legacyFeApi';
import getFilteredCountries from '../../config/countries';
import currencies from '../../config/currencies';
import { useGetLoadedParams } from '../../hooks/paramsHooks';
import actions from '../../modules/billing';
import { IModalBase } from '../../modules/commonTypes';
import ActionModal from '../_DesignSystemWrappers/Modal/ActionModal';
import Select from '../HookFormFields/Select';
import TextField from '../HookFormFields/TextField';
import VatInfoText from '../VatInfoText';
import messages from './messages';
import { getDefaultValues, getValidationSchema } from './validationSchema';

interface IBillingInfoModal extends IModalBase {
  editableCurrency: boolean;
}

const BillingInfoModal = ({ editableCurrency = true, onClose, open }: IBillingInfoModal) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { companyId } = useGetLoadedParams();
  const { data: companyData } = useGetCompanyQuery(
    {
      companyId: companyId!.toString(),
    },
    { refetchOnMountOrArgChange: true }
  );

  const formMethods = useForm({
    defaultValues: getDefaultValues(companyData),
    mode: 'onChange',
    resolver: yupResolver(getValidationSchema(formatMessage)),
  });

  useEffect(() => {
    formMethods.reset(getDefaultValues(companyData));
  }, [companyData]);

  function handleSubmit() {
    const formValues = formMethods.getValues();
    dispatch(actions.editBillingFormRequest(formValues));
    onClose();
  }

  return (
    <FormProvider {...formMethods}>
      <ActionModal
        icon={<DoneIcon />}
        muiDialogProps={{ maxWidth: 'xs' }}
        isPrimaryButtonDisabled={!formMethods.formState.isDirty || !formMethods.formState.isValid}
        onClose={onClose}
        primaryButtonAction={handleSubmit}
        primaryButtonText={formatMessage(messages.editBillingModallSave)}
        secondaryButtonText={formatMessage(messages.editBillingModalCancel)}
        open={open}
        title={formatMessage(messages.editBillingModalTitle)}
      >
        <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <Stack>
            <TextField
              id="name-input"
              label={<FormattedMessage {...messages.editBillingModalLabelCompanyFamily} />}
              name="name"
              required
            />

            <Select
              disabled={!editableCurrency}
              id="currency-select"
              label={<FormattedMessage {...messages.editBillingModalLabelCurrency} />}
              name="currency"
              required
            >
              {currencies.map((currency, index) => (
                <MenuItem key={index} value={currency.value}>
                  {currency.name}
                </MenuItem>
              ))}
            </Select>
            <Typography sx={{ mb: 2 }} variant="subtitle1">
              <FormattedMessage {...messages.editBillingModalAddressTitle} />
            </Typography>
            <TextField
              required
              id="street-input"
              label={<FormattedMessage {...messages.editBillingModalAddressLabelStreet} />}
              name="street"
            />
            <TextField
              id="city-input"
              label={<FormattedMessage {...messages.editBillingModalAddressLabelCity} />}
              name="city"
              required
            />
            <TextField
              id="postalcode-input"
              label={<FormattedMessage {...messages.editBillingModalAddressLabelPostCode} />}
              name="postalCode"
              required
            />
            <Select
              name="country"
              required
              id="country-select"
              label={<FormattedMessage {...messages.editBillingModalAddressLabelCountry} />}
            >
              {getFilteredCountries.map((filteredCountry, index) => (
                <MenuItem key={index} value={filteredCountry.key}>
                  {filteredCountry.name}
                </MenuItem>
              ))}
            </Select>
            <Typography sx={{ mb: 2 }} variant="subtitle1">
              <FormattedMessage {...messages.editBillingModalVatInformation} />
            </Typography>
            <TextField
              disabled={!editableCurrency}
              id="vat-input"
              label={<FormattedMessage {...messages.editBillingModalLabelVatNumber} />}
              name="vatNumber"
            />
            <VatInfoText />
          </Stack>
        </form>
      </ActionModal>
    </FormProvider>
  );
};

export default BillingInfoModal;
