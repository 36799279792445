import * as Yup from 'yup';
import { IDevice } from '../../../modules/devices/store/deviceStore';

export function getDefaultValues(data: IDevice) {
  return {
    sipProfile: data?.services?.GSM_CALLS?.sipProfileNumber ?? '',
  };
}

export function getValidationSchema() {
  return Yup.object().shape({
    sipProfile: Yup.string(),
  });
}
