import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSiteContext } from '../../context/useSiteContext';
import messages from './messages';

interface ILicenseModel {
  licensedCount: number;
  paidCount: number;
  unlicensedCount: number;
}

interface IDevicesAndLicensesCardProps {
  licenseModel: ILicenseModel | undefined;
}

const styles = {
  content: {
    align: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  count: {
    fontSize: '48px',
    lineHeight: 'initial',
  },
  licenseGroup: {
    textAlign: 'center',
  },
  licenses: {
    display: 'flex',
    justifyContent: 'space-around',
    ml: 3,
  },
  root: {
    height: '180px',
  },
  title: {
    fontWeight: 'bold',
    mb: 1,
    mt: 1,
    textAlign: 'center',
  },
};

function DevicesAndLicensesCard({ licenseModel }: IDevicesAndLicensesCardProps) {
  const { formatMessage } = useIntl();
  const isPaymentPerApartment = useSiteContext().hasSiteBillingType('PER_APARTMENT');

  return (
    <Card sx={styles.root}>
      <CardContent sx={styles.content}>
        <Typography color="card.header" sx={styles.title} variant="body2">
          {formatMessage(
            isPaymentPerApartment
              ? messages.DevicesAndLicensesCardTitleApartments
              : messages.DevicesAndLicensesCardTitle
          )}
        </Typography>
        <Box sx={styles.licenses}>
          <Box sx={styles.licenseGroup}>
            <Typography color="primary" sx={styles.count}>
              {licenseModel ? licenseModel.licensedCount - licenseModel.paidCount : ''}
            </Typography>
            <Typography color="secondary" variant="body2">
              {formatMessage(
                isPaymentPerApartment
                  ? messages.DevicesAndLicensesCard2NdevicesApartments
                  : messages.DevicesAndLicensesCard2Ndevices,
                { br: <br /> }
              )}
            </Typography>
          </Box>
          <Box sx={styles.licenseGroup}>
            <Typography color="primary" sx={styles.count}>
              {licenseModel?.paidCount}
            </Typography>
            <Typography color="secondary" variant="body2">
              {formatMessage(
                isPaymentPerApartment
                  ? messages.DevicesAndLicensesCardLicensedApartments
                  : messages.DevicesAndLicensesCardLicensedDevices,
                { br: <br /> }
              )}
            </Typography>
          </Box>
          <Box sx={styles.licenseGroup}>
            <Typography color={licenseModel?.unlicensedCount === 0 ? 'secondary' : 'error'} sx={styles.count}>
              {licenseModel?.unlicensedCount}
            </Typography>
            <Typography color="secondary" variant="body2">
              {formatMessage(
                licenseModel?.unlicensedCount === 0
                  ? messages.DevicesAndLicensesCardDevicesAboveLimitZero
                  : messages.DevicesAndLicensesCardDevicesAboveLimitNotZero,
                { br: <br />, link: (chunks) => <Link href="mailto:customercare@2n.com">{chunks}</Link> }
              )}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default DevicesAndLicensesCard;
