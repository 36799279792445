import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import { SiteBasicInfo } from '../../../../apiRtk/be4feApi';
import { useModal } from '../../../../hooks/useModal';
import CreateCompanyDialog from '../../../CreateCompanyModal';
import { SidebarMobile } from '../../../Sidebar/SidebarMobile';
import { CreateSiteModal as CreateSiteDialog } from '../../../SiteModal/CreateSiteModal';

interface ISidebarMobileButton {
  isVersionWarningDisplayed: boolean;
  sitesList?: SiteBasicInfo[];
}

function SidebarMobileButton({ isVersionWarningDisplayed, sitesList }: ISidebarMobileButton) {
  const [open, setOpen] = useState(false);

  const { Modal: CreateSiteModal, onOpen: openCreateSiteModal } = useModal({
    Modal: CreateSiteDialog,
  });
  const { Modal: CreateCompanyModal, onOpen: onOpenCreateCompanyModal } = useModal({
    Modal: CreateCompanyDialog,
  });

  return (
    <Box sx={{ display: { md: 'none', xs: 'flex' } }}>
      <Box sx={{ color: 'common.white', pl: 1 }}>
        <IconButton aria-label="menu" onClick={() => setOpen((prevState) => !prevState)}>
          {open ? (
            <ArrowBackIcon sx={{ color: 'common.white', opacity: 1 }} />
          ) : (
            <MenuIcon sx={{ color: 'primary.contrastText', opacity: '0.45' }} />
          )}
        </IconButton>
        {open && (
          <SidebarMobile
            isVersionWarningDisplayed={isVersionWarningDisplayed}
            onClose={() => setOpen((prevState) => !prevState)}
            onOpenCreateSiteModal={openCreateSiteModal}
            onOpenCreateCompanyModal={onOpenCreateCompanyModal}
            sitesList={sitesList}
          />
        )}
      </Box>
      <CreateSiteModal />
      <CreateCompanyModal />
    </Box>
  );
}

export default SidebarMobileButton;
