import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from '../../../components';
import Checkbox from '../../../components/HookFormFields/Checkbox';
import { PATHS } from '../../../config/consts';
import messages from '../messages';

export interface IGTCAgreeProps {
  companyId: string | number;
  name: string;
}

export function GTCAgree({ companyId, name }: IGTCAgreeProps) {
  return (
    <Grid item sx={{ mb: 3, pt: 0 }}>
      <Checkbox
        label={
          <Typography component="span" variant="body2">
            <FormattedMessage
              {...messages.agreeGTCLabel}
              values={{
                cloudLink: (chunks) => (
                  <Link
                    target="_blank"
                    to={PATHS.CLOUD_AGREEMENT_WITH_COMPANY.replace(':companyId', companyId.toString())}
                  >
                    {chunks}
                  </Link>
                ),
                consumerLink: (chunks) => (
                  <Link
                    target="_blank"
                    to={PATHS.INFORMATION_FOR_CONSUMERS_WITH_COMPANY.replace(':companyId', companyId.toString())}
                  >
                    {chunks}
                  </Link>
                ),
              }}
            />
          </Typography>
        }
        name={name}
        required
        sx={{
          '& .MuiStack-root': {
            display: 'block !important',
          },
          '& span': {
            pt: 0,
          },
          alignItems: 'flex-start',
          display: 'flex',
        }}
      />
    </Grid>
  );
}
