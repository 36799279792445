import CheckIcon from '@mui/icons-material/Check';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { difference } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import apartmentActions from '../../modules/apartments';
import userActions from '../../modules/user';
import ActionModal from '../_DesignSystemWrappers/Modal/ActionModal';
import ApartmentAutocomplete from '../ApartmentAutocomplete';
import Loader from '../Loader';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import { useGetUserApartmentModalData } from './hooks/getUserApartmentModalData';
import LoginLessUser from './LoginLessUser';
import messages from './messages';
import StandardUser from './StandardUser';

function UserApartmentModal({ onClose, open }) {
  const hasFormItemKey = 'hasForm';
  const newItemId = 'newItemId';
  const { dispatch, formatMessage, generalInfo, methods } = useGetGlobalHooks();
  const { isDirty, isValid } = methods.formState;
  const { initialData, isApartmentLoading, isModalFetching, optionsData, userData } = useGetUserApartmentModalData();

  useEffect(() => {
    if (open) {
      dispatch(apartmentActions.getApartmentsList(generalInfo.companyId, generalInfo.siteId));
    }
  }, [open]);

  useEffect(() => {
    methods.reset({
      apartment: initialData,
      floor: '',
      [hasFormItemKey]: false,
      name: '',
      number: '',
    });
  }, [initialData]);

  function handleSubmit(data) {
    const { floor, name, number } = data;
    const formApts = data.apartment.filter((item) => item.id !== newItemId).map((item) => item.id);
    const apartments = {
      added: difference(
        formApts,
        initialData.map((item) => item.id)
      ),
      removed: difference(
        initialData.map((item) => item.id),
        formApts
      ),
    };

    dispatch(
      userActions.setApartmentToUser(userData.id, apartments, data.hasForm ? { floor, name, number } : null, true)
    );

    onCloseModal();
  }

  function onCloseModal() {
    onClose();
    dispatch(apartmentActions.clearApartmentsList());
  }

  return (
    <ActionModal
      icon={isApartmentLoading || isModalFetching ? <Loader size={20} /> : <CheckIcon />}
      isPrimaryButtonDisabled={!isDirty || !isValid}
      muiDialogProps={{ maxWidth: 'xs' }}
      onClose={onCloseModal}
      open={open || isModalFetching}
      primaryButtonAction={methods.handleSubmit(handleSubmit)}
      primaryButtonText={formatMessage(messages.UserApartmentModalSetApartmentButton)}
      secondaryButtonText={formatMessage(messages.UserApartmentModalCancelButton)}
      title={formatMessage(messages.UserApartmentModalHeader)}
    >
      <Stack direction="column" spacing={2}>
        <Typography component="span" variant="body1">
          <FormattedMessage {...messages.UserApartmentModalDescription} />
        </Typography>
        {!userData?.email ? (
          <LoginLessUser firstName={userData?.firstName} lastName={userData?.lastName} />
        ) : (
          <StandardUser email={userData?.email} firstName={userData?.firstName} lastName={userData?.lastName} />
        )}
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <ApartmentAutocomplete hasFormItemKey={hasFormItemKey} newItemId={newItemId} optionsData={optionsData} />
          </form>
        </FormProvider>
      </Stack>
    </ActionModal>
  );
}

UserApartmentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default UserApartmentModal;
