export enum LICENSE {
  APARTMENT_MOBILE_VIDEO_MONTH = 'APARTMENT_MOBILE_VIDEO_MONTH',
  APARTMENT_MOBILE_VIDEO_YEAR = 'APARTMENT_MOBILE_VIDEO_YEAR',
  MOBILE_VIDEO_MONTH = 'MOBILE_VIDEO_MONTH',
  MOBILE_VIDEO_YEAR = 'MOBILE_VIDEO_YEAR',
}

export interface IUnitPurchaseProps {
  licencesPool: number;
  licences: number;
  price: number;
  priceVat: number;
  unitFullPrice: number;
  unitFullPriceVat: number;
}

export interface IUnitNextPurchaseProps {
  calculation: IUnitPurchaseProps;
  date: string;
}

export interface IUnitPricesProps {
  chargeVat: boolean;
  unitFullPrice: number;
  unitFullPriceVat: number;
}

export interface IPricesProps {
  [LICENSE.APARTMENT_MOBILE_VIDEO_MONTH]: IUnitPricesProps;
  [LICENSE.APARTMENT_MOBILE_VIDEO_YEAR]: IUnitPricesProps;
  [LICENSE.MOBILE_VIDEO_MONTH]: IUnitPricesProps;
  [LICENSE.MOBILE_VIDEO_YEAR]: IUnitPricesProps;
}

export interface IPaymentProps {
  [LICENSE.APARTMENT_MOBILE_VIDEO_MONTH]: IUnitPurchaseProps;
  [LICENSE.APARTMENT_MOBILE_VIDEO_YEAR]: IUnitPurchaseProps;
  [LICENSE.MOBILE_VIDEO_MONTH]: IUnitPurchaseProps;
  [LICENSE.MOBILE_VIDEO_YEAR]: IUnitPurchaseProps;
}

export interface INextPaymentProps {
  [LICENSE.APARTMENT_MOBILE_VIDEO_MONTH]: IUnitNextPurchaseProps;
  [LICENSE.APARTMENT_MOBILE_VIDEO_YEAR]: IUnitNextPurchaseProps;
  [LICENSE.MOBILE_VIDEO_MONTH]: IUnitNextPurchaseProps;
  [LICENSE.MOBILE_VIDEO_YEAR]: IUnitNextPurchaseProps;
}

export interface IMobileVideoOrderContentProps {
  licences: number;
  percentage: number;
  price: number;
  priceVat: number;
  product: string;
  unitFullPrice: number;
  unitFullPriceVat: number;
}

export interface IPurchasePaymentResultProps {
  paymentResult: string;
  purchaseState: string;
}
