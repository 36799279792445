import { SERVICE } from '../../config/devices';
import { SITE_TYPE } from '../../config/sites';
import {
  ApartmentStatus,
  BillingType,
  DeviceStatus,
  DeviceType,
  getInitialReduxBaseStore,
  IReduxStoreBase,
  MobileVideoStatus,
  MobileVideoType,
  TId,
} from '../commonTypes';
import { initialState } from './reducer';

export interface ISite {
  name?: string;
  id: number;
  services?: {
    [SERVICE.MOBILE_VIDEO]?: {
      licenceModel: IMVLicenceModel;
    };
  };
  type?: SITE_TYPE;
}

export interface ISitesStore extends IReduxStoreBase<ISite> {
  dashboard: IReduxStoreBase<IDashboardStore>;
  editModal: IEditSiteModalStore;
}

export interface IEditSiteModalStore {
  isFetching: boolean;
  show: boolean;
  site: object;
  values: { name: string };
}

export interface IDashboardStore {
  my2nId: string;
  services?: SiteServices;
}

export type SiteServices = {
  [key in SERVICE]: ISiteService;
};

export interface ISiteService {}

export interface ISiteMobileVideoService extends ISiteService {
  apartments?: IMVApartments;
  devices?: IMVDevices;
  licenceModel?: IMVLicenceModel;
}

export interface IMVLicenceModel {
  additional: number;
  id: number;
  type: MobileVideoType;
  validityFrom: Date;
  validityTo: Date;
  billingType: BillingType;
  billingTypeMultiplier: number;
  status: MobileVideoStatus;
  licencesLimit: number;
  licencesPool: number;
  unlicensedCount: number;
  licensedCount: number;
  maximalLicencesLimit: number;
  paidCount: number;
  unusedLicencesCount: number;
  freeLicencesWithIntercomCount: number;
  unusedFreeLicencesWithIntercomCount: number;
}

export interface ILicenceModelExtensions {
  id: number;
  period: Date;
  dateCreated: Date;
  validityFrom: Date;
  validityTo: Date;
}

export interface IMVApartments {
  status: ApartmentStatus;
}

export interface IMVDevices {
  type: DeviceType;
  count: number;
  status: DeviceStatus;
}

export interface ISiteIdsInfo {
  companyId: TId;
  siteId: TId;
}

export function getInitialSitesStore(): ISitesStore {
  return {
    ...initialState,
    ...getInitialReduxBaseStore<ISite>(),
    dashboard: getInitialReduxBaseStore<IDashboardStore>(),
    editModal: {
      ...initialState.editModal,
    },
  };
}
