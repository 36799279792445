import { defineMessages } from 'react-intl';

export default defineMessages({
  addAxisDeviceAnsweringUnit: {
    defaultMessage: 'AXIS answering unit',
    id: 'addAxisDevice.answeringUnit',
  },
  addAxisDeviceIntercom: {
    defaultMessage: 'AXIS intercom',
    id: 'addAxisDevice.intercom',
  },
  addAxisDeviceText: {
    defaultMessage: 'Choose the type of device you want to add. You can always add another.',
    id: 'addAxisDevice.text',
  },
});
