import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SITE_TYPE } from '../../../../../config/sites';
import { floorField, getFloorFieldState } from '../apartmentFormFieldsControl';
import messages from './messages';

/**
 * This component is temporary solution, use component ready for react-hookform instead
 */
const DeviceApartmentFloorAutocompleteDeprecated = ({ floors, initFloor, onChange, siteType, typedFloor, values }) => {
  const floorFieldState = getFloorFieldState(values);

  const handleChangeInput = (event, newValue) => {
    let value;
    const item = floors.find((floor) => floor.name === newValue);
    if (!item) {
      value = newValue;
    } else {
      value = item.name;
    }
    return onChange({
      target: {
        name: floorField,
        value,
      },
    });
  };

  const handleChangeAutocompleteValue = (event, newValue) => {
    let value;
    if (!newValue) {
      value = initFloor;
    } else {
      value = newValue;
    }
    return onChange({
      target: {
        name: floorField,
        value,
      },
    });
  };

  return (
    <Autocomplete
      clearIcon={null}
      freeSolo
      isOptionEqualToValue={(option) => option === typedFloor}
      onChange={(event, newValue) => handleChangeAutocompleteValue(event, newValue)}
      onInputChange={(event, newValue) => handleChangeInput(event, newValue)}
      options={floors.map((fl) => fl.name)}
      popupIcon={null}
      renderInput={(params) => (
        <TextField
          {...params}
          error={floorFieldState.hasError}
          helperText={floorFieldState.helperText}
          InputLabelProps={{ shrink: true }}
          inputProps={{ ...params.inputProps, value: typedFloor }}
          label={<FormattedMessage {...messages.ApartmentFloorAutocompleteFloor} />}
          name={floorField}
        />
      )}
    />
  );
};

DeviceApartmentFloorAutocompleteDeprecated.propTypes = {
  floors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ).isRequired,
  initFloor: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  siteType: PropTypes.oneOf(Object.keys(SITE_TYPE)).isRequired,
  typedFloor: PropTypes.string.isRequired,
  values: PropTypes.shape({
    existUser: PropTypes.bool,
    floor: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    formErrors: PropTypes.object,
    newApartmentFloor: PropTypes.string,
    newApartmentName: PropTypes.string,
    newApartmentNumber: PropTypes.string,
  }).isRequired,
};

DeviceApartmentFloorAutocompleteDeprecated.defaultProps = {
  initFloor: '',
};

export default DeviceApartmentFloorAutocompleteDeprecated;
