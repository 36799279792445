import { Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import Checkbox from '../../../../components/HookFormFields/Checkbox';
import { OutlinedTextField } from '../../../../components/HookFormFields/TextField';
import { isFirstNameRequired } from './hooks/getGlobalHooks';
import { useGetUserInfoFormData } from './hooks/getUserInfoFormData';
import messages from './messages';

function PersonalInfo({ hasGSM }: { hasGSM: boolean }) {
  const {
    siteData,
    userData: { initialUserRole },
  } = useGetUserInfoFormData();
  const { setValue, watch } = useFormContext();
  const phoneNumberValue = watch('phoneNumber');

  useEffect(() => {
    if (!phoneNumberValue) {
      setValue('gsmCalls', false);
    }
  }, [phoneNumberValue]);

  return (
    <Grid item xs={12}>
      <OutlinedTextField
        disabled
        fullWidth
        label={<FormattedMessage {...messages.personalInfoEmailLabel} />}
        margin="normal"
        name="email"
        required={false}
      />
      <OutlinedTextField
        fullWidth
        label={<FormattedMessage {...messages.personalInfoFirstNameLabel} />}
        margin="normal"
        name="firstName"
        required={isFirstNameRequired(initialUserRole)}
      />
      <OutlinedTextField
        fullWidth
        label={<FormattedMessage {...messages.personalInfoLastNameLabel} />}
        margin="normal"
        name="lastName"
        required
      />
      {hasGSM && (
        <>
          <OutlinedTextField
            fullWidth
            label={<FormattedMessage {...messages.personalInfoMobilePhoneLabel} />}
            name="phoneNumber"
            // helperText={
            //   formState.dirtyFields.phoneNumber ? ( // TODO: HIPCS-22890 add to condition if GSM is active
            //     <FormattedMessage {...messages.personalInfoMobilePhoneWarning} />
            //   ) : null
            // }
            // FormHelperTextProps={{
            //   sx: { color: 'orange' },
            // }}
          />
          {siteData?.services?.GSM_CALLS?.active && (
            <Tooltip title={<FormattedMessage {...messages.personalInfoAllowCallingTooltip} />}>
              <div>
                <Checkbox
                  name="gsmCalls"
                  disabled={!phoneNumberValue}
                  label={
                    <Typography color={phoneNumberValue ? 'textPrimary' : 'secondary'}>
                      <FormattedMessage {...messages.personalInfoAllowCallingLabel} />
                    </Typography>
                  }
                  sx={{ mb: 3, ml: 2 }}
                />
              </div>
            </Tooltip>
          )}
        </>
      )}
    </Grid>
  );
}

export default PersonalInfo;
