import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useSiteContext } from '../../../context/useSiteContext';
import { getValidationSchema } from '../helpers/yupValidations';

export function useGetGlobalHooks(isEdit, context, isCurrentUser, data, validation, companyId, siteId, userRole) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const validationSchema = getValidationSchema(
    isEdit,
    context,
    isCurrentUser,
    data,
    validation,
    companyId,
    siteId,
    formatMessage,
    userRole
  );

  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    shouldUnregister: isEdit,
  });

  return {
    dispatch,
    formatMessage,
    formMethods,
  };
}
