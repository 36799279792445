import ButtonGroup from '@mui/material/ButtonGroup';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon2N } from '../../../components';
import { DEVICE_TYPE } from '../../../config/devices';
import { TDevicesControlValues } from '../types';
import { Device } from './Device';
import messages from './messages';

export const devicesControlStyles = {
  '& .MuiButtonGroup-grouped:not(:last-of-type)': {
    '&:hover': {
      borderRightColor: 'primary.main',
    },
    borderRightColor: 'secondary.main',
  },
  display: 'flex',
  flexWrap: 'wrap',
  gap: 2,
  justifyContent: 'space-evenly',
  py: 1,
};

const devices = [
  {
    icon: <Icon2N name="device-2nhw-new" />,
    title: <FormattedMessage {...messages.x2NTitle} />,
    value: 'X2N',
  },
  {
    icon: <Icon2N name="mobile" />,
    title: <FormattedMessage {...messages.hipmoTitle} />,
    value: DEVICE_TYPE.HIPMO,
  },
  {
    icon: <Icon2N name="axis-device" />,
    title: <FormattedMessage {...messages.doorStationTitle} />,
    value: 'AXIS_DEVICE',
  },
  {
    icon: <Icon2N name="third-party" />,
    title: <FormattedMessage values={{ sup: (chunks) => <sup>{chunks}</sup> }} {...messages.thirdPartyTitle} />,
    value: DEVICE_TYPE.THIRD_PARTY,
  },
];

interface IDevicesControl {
  onSelectDevice: (value: TDevicesControlValues) => void;
}

export function DevicesControl({ onSelectDevice }: IDevicesControl) {
  return (
    <ButtonGroup sx={devicesControlStyles}>
      {devices.map((item) => (
        <Device
          icon={item.icon}
          key={item.value}
          onSelectDevice={onSelectDevice}
          title={item.title}
          value={item.value}
        />
      ))}
    </ButtonGroup>
  );
}
