import { defineMessages } from 'react-intl';

export default defineMessages({
  changeSiteTypeModalErrorAlertCommercial: {
    defaultMessage: 'TBD',
    id: 'changeSiteType.modal.error.alert.commercial',
  },
  changeSiteTypeModalErrorAlertResidential: {
    defaultMessage:
      'Your site doesn’t meet the requirements for the Residential site type. Update the site to select this type.',
    id: 'changeSiteType.modal.error.alert.residential',
  },
  changeSiteTypeModalErrorRequirement1: {
    defaultMessage: 'Each apartment must have the floor filled in.',
    id: 'changeSiteType.modal.error.requirement1',
  },
  changeSiteTypeModalErrorRequirement2: {
    defaultMessage: 'All devices (except intercoms and access units) must be assigned to the corresponding apartments.',
    id: 'changeSiteType.modal.error.requirement2',
  },
  changeSiteTypeModalErrorRequirement3: {
    defaultMessage:
      'Each apartment can have up to {count} paid devices (phones, tablets, or 3rd-party devices). For assistance, contact <link>customer care</link>.',
    id: 'changeSiteType.modal.error.requirement3',
  },
  changeSiteTypeModalErrorTextCommercial: {
    defaultMessage: 'TBD',
    id: 'changeSiteType.modal.error.text.commercial',
  },
  changeSiteTypeModalErrorTextResidential: {
    defaultMessage: 'Residential buildings must meet the following requirements:',
    id: 'changeSiteType.modal.error.text.residential',
  },
});
