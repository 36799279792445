import LocationOnIcon from '@mui/icons-material/LocationOn';
import Box from '@mui/material/Box';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { SiteBasicInfo } from '../../apiRtk/be4feApi';
import { PATHS } from '../../config/consts';
import { useGetActualCompanyId } from '../../hooks/companyHooks';
import { useGetActualSiteId } from '../../hooks/siteHooks';
import { SiteSwitcher } from '../Navbar/components/siteMenu/SiteSwitcher';
import SideBarMenu from '../SideBarMenu';
import messages from './messages';

const styles = {
  switcher: {
    '& li, & div > div > div': {
      px: 2,
    },
  },
};

export interface ISiteSwitcherMobileProps {
  onDrawerClose: (open: boolean) => void;
  onOpenCreateSiteModal: () => void;
  onSetOpen: (open: boolean) => void;
  open: boolean;
  sitesList?: SiteBasicInfo[];
}

export function SiteSwitcherMobile({
  onDrawerClose,
  onOpenCreateSiteModal,
  onSetOpen,
  open,
  sitesList,
}: ISiteSwitcherMobileProps) {
  const navigate = useNavigate();
  const companyId = useGetActualCompanyId();
  const siteId = useGetActualSiteId();

  function addSite() {
    onDrawerClose(false);
    onSetOpen(false);
    onOpenCreateSiteModal();
  }

  function selectSite(currentSiteId: number) {
    onDrawerClose(false);
    onSetOpen(false);
    navigate(PATHS.SITE_DASHBOARD.replace(':companyId', companyId).replace(':siteId', currentSiteId.toString()));
  }

  return (
    <SideBarMenu
      buttonEmptyLabel={<FormattedMessage {...messages.SiteSwitcherEmptyLabel} />}
      buttonItemLabel={<FormattedMessage {...messages.SiteSwitcherLabel} />}
      buttonStartIcon={<LocationOnIcon />}
      buttonValue={sitesList?.find((item: SiteBasicInfo) => item.id === siteId)?.name || ''}
      onButtonClick={() => onSetOpen(!open)}
      open={open}
    >
      <Box sx={styles.switcher}>
        <SiteSwitcher currentId={siteId} data={sitesList ?? []} isSideBar onAdd={addSite} onSelect={selectSite} />
      </Box>
    </SideBarMenu>
  );
}
