import * as Yup from 'yup';
import { LICENSE } from '../../UnitPropsTS';

export function getMVLicenceValidationSchema() {
  return Yup.object().shape({
    agreeGTC: Yup.bool().required(),
    isRecurringPayment: Yup.bool().required(),
    paymentPrepared: Yup.bool().required(),
    product: Yup.mixed<LICENSE>().required(),
  });
}
