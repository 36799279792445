import { defineMessages } from 'react-intl';

export default defineMessages({
  GSMServiceButtonOpenConfig: {
    defaultMessage: 'Open configuration',
    id: 'GSMService.button.openConfiguration',
  },
  GSMServiceSipProfileLabel: {
    defaultMessage: 'SIP Profile',
    id: 'GSMService.SIPprofile.label',
  },
  GSMServiceSipProfilePlaceholder: {
    defaultMessage: 'Select SIP Profile',
    id: 'GSMService.SIPprofile.placeholder',
  },
  GSMServiceSipProfileValue1: {
    defaultMessage: 'SIP 1',
    id: 'GSMService.SIPprofile.value1',
  },
  GSMServiceSipProfileValue2: {
    defaultMessage: 'SIP 2',
    id: 'GSMService.SIPprofile.value2',
  },
  GSMServiceSipProfileValue3: {
    defaultMessage: 'SIP 3',
    id: 'GSMService.SIPprofile.value3',
  },
  GSMServiceSipProfileValue4: {
    defaultMessage: 'SIP 4',
    id: 'GSMService.SIPprofile.value4',
  },
  GSMServiceStatusActivateText: {
    defaultMessage:
      'Do you want to activate GSM calling service for this device?{br}Remember to set up SIP profile through Remote Configuration.',
    id: 'GSMService.modal.activate.text',
  },
  GSMServiceStatusActivateTitle: {
    defaultMessage: 'Activate GSM calling',
    id: 'GSMService.modal.activate.title',
  },
  GSMServiceStatusActive: {
    defaultMessage: 'Active',
    id: 'GSMService.status.active',
  },
  GSMServiceStatusDeactivateText: {
    defaultMessage:
      'Deactivating GSM calling will disconnect this device from the service.{br}Do you want to deactivate GSM calling service for this device?',
    id: 'GSMService.modal.deactivate.text',
  },
  GSMServiceStatusDeactivateTitle: {
    defaultMessage: 'Deactivate GSM calling',
    id: 'GSMService.modal.deactivate.title',
  },
  GSMServiceStatusNotActive: {
    defaultMessage: 'Inactive',
    id: 'GSMService.status.notActive',
  },
  GSMServiceSubmit: {
    defaultMessage: 'Save changes',
    id: 'GSMService.submit',
  },
  GSMServiceText1: {
    defaultMessage: 'SIP profile needs to be set up through Remote Configuration.',
    id: 'GSMService.text1',
  },
  GSMServiceTitle: {
    defaultMessage: 'GSM calling',
    id: 'GSMService.GSMService',
  },
});
