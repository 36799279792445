import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import React from 'react';
import { UseFieldArrayRemove } from 'react-hook-form';
import { IPrices } from '../../hooks/types';
import { IItem } from '../OneStep';
import { RowOne } from './RowOne';
import { RowTwo } from './RowTwo';

export interface IPromoBatchFormProps {
  currency: string;
  prices: IPrices;
  fields: IItem[];
  removeFromArray: UseFieldArrayRemove;
}

export function PromoBatchForm({ currency, fields, prices, removeFromArray }: IPromoBatchFormProps) {
  return (
    <>
      {fields.map((item, index) => (
        <div key={item.id}>
          <Grid alignItems="center" container direction="row">
            <Grid item md xs={12}>
              <Grid container spacing={2}>
                <RowOne batchIndex={index} removeFromArray={removeFromArray} name={`array[${index}]`} />
                <RowTwo
                  batchIndex={index}
                  currency={currency}
                  name={`array[${index}]`}
                  unitFullPrice={
                    item.subscriptionType === 'DEVICE'
                      ? prices?.MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD?.unitFullPrice
                      : prices?.MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT?.unitFullPrice
                  }
                />
              </Grid>
              <Box>
                <Box py={3}>
                  <Divider />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      ))}
    </>
  );
}
