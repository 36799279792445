import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { LoadingButton } from '../../../components';
import { INVOICE } from '../constants';
import messages from '../messages';
import { GTCAgree } from './GTCAgree';
import { PaymentConfirm } from './PaymentConfirm';

export interface IOrderSubmitProps {
  companyId: number | string;
  isCreditLimitReached: boolean;
  isLoading: boolean;
  onInvoicePayment: () => void;
  onPaymentGateway: () => void;
  paymentLocation: string;
  paymentMethod: string;
  secondaryAction?: React.ReactNode;
}

export interface IOrderSubmitFormData {
  gtcAgree: boolean;
  paymentPrepared: boolean;
}

export function OrderSubmit({
  companyId,
  isCreditLimitReached,
  isLoading,
  onInvoicePayment,
  onPaymentGateway,
  paymentLocation,
  paymentMethod,
  secondaryAction,
}: IOrderSubmitProps) {
  const formMethods = useForm<IOrderSubmitFormData>({
    defaultValues: {
      gtcAgree: false,
      paymentPrepared: false,
    },
    mode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        gtcAgree: Yup.boolean()
          .test((value) => value)
          .required(),
        paymentPrepared: Yup.boolean()
          .test((value) => value)
          .required(),
      })
    ),
  });

  return (
    <FormProvider {...formMethods}>
      <form>
        <Grid item lg={10} xs={12}>
          <PaymentConfirm
            message={<FormattedMessage {...messages.orderSubmitPaymentConfirm} />}
            name="paymentPrepared"
          />
        </Grid>
        <Grid item lg={10} xs={12}>
          <GTCAgree companyId={companyId} name="gtcAgree" />
        </Grid>
        <Grid alignItems="center" container justifyContent={secondaryAction ? 'space-between' : 'flex-end'}>
          {secondaryAction && <Grid item>{secondaryAction}</Grid>}
          <Grid item>
            {paymentMethod === INVOICE ? (
              <LoadingButton
                color="primary"
                disabled={!formMethods.formState.isValid || isCreditLimitReached}
                id="invoice-button"
                isLoading={isLoading}
                onClick={onInvoicePayment}
                variant="contained"
              >
                <FormattedMessage {...messages.orderSubmitInvoiceButton} />
              </LoadingButton>
            ) : (
              <LoadingButton
                color="primary"
                disabled={!formMethods.formState.isValid}
                href={paymentLocation}
                id="webpay-button"
                isLoading={isLoading}
                onClick={onPaymentGateway}
                target="_blank"
                variant="contained"
              >
                <FormattedMessage {...messages.orderSubmitWebPayButton} />
              </LoadingButton>
            )}
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
