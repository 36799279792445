import { defineMessages } from 'react-intl';

export default defineMessages({
  userChangePasswordDialogFormActionsCancel: {
    defaultMessage: 'Cancel',
    description: 'User Change Password Dialog - cancel button',
    id: 'userChangePasswordDialog.formActions.cancel',
  },
  userChangePasswordDialogFormActionsSubmit: {
    defaultMessage: 'Set password',
    description: 'User Change Password Dialog - submit button',
    id: 'userChangePasswordDialog.formActions.submit',
  },
  UserChangePasswordDialogImsWarningAppLogout: {
    defaultMessage: `If you're using the My2N mobile app, changing your password will log you out of it.`,
    id: 'userChangePasswordDialog.ims.warningAppLogout',
  },
  userChangePasswordDialogLabelPassword: {
    defaultMessage: 'New password',
    description: 'User Change Password Dialog - new password label',
    id: 'userChangePasswordDialog.label.newPassword',
  },
  userChangePasswordDialogLabelRepeatPassword: {
    defaultMessage: 'Repeat password',
    description: 'User Change Password Dialog - repeat password label',
    id: 'userChangePasswordDialog.label.repeatPassword',
  },
  userChangePasswordDialogTitle: {
    defaultMessage: 'Set new password',
    description: 'User Change Password Dialog - title',
    id: 'userChangePasswordDialog.title',
  },
  userChangePasswordDialogValidationNoMatch: {
    defaultMessage: 'Values must match',
    description: 'User Change Password Dialog - values must match',
    id: 'userChangePasswordDialog.validation.noMatch',
  },
  userChangePasswordDialogValidationNoValid: {
    defaultMessage: 'Password does not conform to policy',
    description: 'User Change Password Dialog - no valid field',
    id: 'userChangePasswordDialog.validation.noValid',
  },
  userChangePasswordDialogValidationRequired: {
    defaultMessage: 'Value is mandatory',
    description: 'User Change Password Dialog - value is required',
    id: 'userChangePasswordDialog.validation.required',
  },
});
