import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonDanger, Modal } from '../../../../components';
import actions from '../../../../modules/subscription';
import { useGetPromoCodeData } from '../hooks/useGetPromoCodeData';
import messages from './messages';

const RemoveBatchModal = ({ onClose, open, removeFromArray, removeIndex }) => {
  const { dispatch } = useGetPromoCodeData();
  const handleRemoveBatch = () => {
    if (removeFromArray) {
      removeFromArray(removeIndex);
    } else {
      dispatch(actions.deletePromoOrderContent(removeIndex));
    }
    onClose();
  };

  return (
    <Modal
      actions={
        <div>
          <Button onClick={onClose}>
            <FormattedMessage {...messages.removeBatchModalActionClose} />
          </Button>
          <ButtonDanger onClick={handleRemoveBatch} startIcon={<DeleteIcon />}>
            <FormattedMessage {...messages.removeBatchModalActionRemove} />
          </ButtonDanger>
        </div>
      }
      headerVariant="danger"
      name="remove-batch-modal"
      onClose={onClose}
      open={open}
      title={<FormattedMessage {...messages.removeBatchModalTitle} />}
    >
      <Typography>
        <FormattedMessage {...messages.removeBatchModalText} />
      </Typography>
    </Modal>
  );
};

RemoveBatchModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  removeFromArray: PropTypes.func,
  removeIndex: PropTypes.number.isRequired,
};

export default RemoveBatchModal;
