import { all, put, takeEvery } from 'redux-saga/effects';
import { addSnackbarError } from './actions';
import { TYPE_CODE, TYPE_DESCRIPTOR, TYPE_TEXT } from './types';

interface IError {
  status?: number;
  message?: string;
  data?: {
    code: string;
    message?: {
      defaultMessage: string;
    };
  };
}

interface IErrorHandlerProps {
  error?: IError | string | Error;
  type: string;
}

export function* notificationErrorHandler({ error, type }: IErrorHandlerProps) {
  if (!type.endsWith('_FAILURE') || error === undefined) {
    return;
  }

  if (isErrorString(error)) {
    yield put(addSnackbarError(error, TYPE_TEXT));
    return;
  }

  if (isCommonError(error)) {
    yield put(addSnackbarError(error.message, TYPE_TEXT));
    return;
  }

  if (error.status === 404) {
    return;
  }

  if (error.data?.message?.defaultMessage !== undefined) {
    yield put(addSnackbarError(error.data.message, TYPE_DESCRIPTOR));
    return;
  }

  if (error.status !== undefined) {
    yield put(addSnackbarError(getTranslateInfo(error.status, type), TYPE_CODE));
    return;
  }

  if (error.data !== undefined) {
    yield put(addSnackbarError(getTranslateInfo(error.data.code, type), TYPE_CODE));
  }
}

export default function* notificationsSagas() {
  yield all([takeEvery('*', notificationErrorHandler)]);
}

function isErrorString(error: IError | string | undefined | Error): error is string {
  return typeof error === 'string';
}

function isCommonError(error: IError | string | undefined | Error): error is Error {
  return (error as Error)?.stack !== undefined;
}

function getTranslateInfo(code: string | number, type: string) {
  const [context, action] = type.split('/');
  return { action, code, context };
}
