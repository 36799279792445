import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import useBreakpoints from '../../helpers/useBreakpoints';
import DataGrid from '../DataGrid';
import DeviceTypeLabel from '../DeviceType';
import messages from './messages';

const PhonebookMembersDataGrid = ({ loading, members }) => {
  const { formatMessage } = useIntl();
  const { upMd } = useBreakpoints();

  const columns = useMemo(
    () => [
      {
        disableColumnMenu: true,
        field: 'name',
        flex: 1,
        headerName: formatMessage(messages.PhonebooksMembersColumnsName),
        renderCell: (cell) =>
          loading ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <Box fontWeight="bold">{cell.value.name}</Box>
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'deviceId',
        flex: 1,
        headerName: formatMessage(messages.PhonebooksMembersColumnsDeviceId),
        renderCell: (cell) => (loading ? <Skeleton animation="wave" height={25} width="80%" /> : cell.value.sipNumber),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'type',
        flex: 1,
        headerName: formatMessage(messages.PhonebooksMembersColumnsType),
        renderCell: (cell) =>
          loading ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <DeviceTypeLabel type={cell.value.type} deviceType={cell.value.deviceType} />
          ),
        sortable: false,
      },
    ],
    [loading, upMd]
  );

  const rows = useMemo(
    () =>
      members?.map((member) => ({
        deviceId: member,
        deviceType: member,
        id: member.id,
        isClickable: false,
        isDisabled: !member.active,
        name: member,
        type: member,
      })),
    [members]
  );

  return (
    <DataGrid
      columns={columns}
      columnVisibilityModel={{
        type: upMd,
      }}
      disableRowSelectionOnClick
      hideFooterPagination
      loading={loading}
      rows={rows}
    />
  );
};

PhonebookMembersDataGrid.propTypes = {
  loading: PropTypes.bool.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      deviceType: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      sipNumber: PropTypes.string,
      type: PropTypes.string,
    })
  ),
};

PhonebookMembersDataGrid.defaultProps = {
  members: [],
};

export default PhonebookMembersDataGrid;
