import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useMediaQuery, useTheme } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../config/consts';
import { useDataGrid } from '../../helpers/dataGrid';
import { isFetchingMessages } from '../../modules/localization/selectors';
import DataGrid from '../DataGrid';
import NameColumn from '../DataGrid/NameColumn';
import { SitesDataGridRowMenu } from '../SitesDataGrid/SitesDataGridRowMenu';
import SiteStatus from '../SiteStatus';
import SiteType from '../SiteType';
import Footer from './Footer';
import messages from './messages';

const RecentSitesDataGrid = ({ companyId, data, didInvalid, isFetching, onLoadSites, order, resultsTotal }) => {
  const { onOrderBy } = useDataGrid(
    onLoadSites,
    {
      companyId,
      filter: { fulltext: '' },
      order,
    },
    didInvalid
  );

  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const upLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isLocalizationLoaded = useSelector(isFetchingMessages);

  const columns = useMemo(
    () => [
      {
        disableColumnMenu: true,
        field: 'name',
        flex: 1,
        headerName: formatMessage(messages.recentSitesDataGridColumnsSiteName),
        renderCell: (cell) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <NameColumn iconComponent={<LocationOnIcon />} name={cell.value?.name} />
          ),
        sortable: true,
      },
      {
        disableColumnMenu: true,
        field: 'type',
        flex: 1,
        headerName: formatMessage(messages.recentSitesDataGridColumnsSiteType),
        renderCell: (cell) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <SiteType category={cell.value?.siteCategory} type={cell.value?.type} />
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'status',
        flex: 1,
        headerName: formatMessage(messages.recentSitesDataGridColumnsStatus),
        renderCell: (cell) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <SiteStatus licenceModel={cell.value?.mobileVideo.licenceModel} />
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'rowMenu',
        headerName: '',
        renderCell: (cell) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <SitesDataGridRowMenu site={cell.value} />
          ),
        sortable: false,
        width: 65,
      },
    ],
    [upSm, upMd, upLg, isFetching, isLocalizationLoaded]
  );

  const rows = useMemo(
    () =>
      data.map((site) => ({
        id: site.id,
        isClickable: true,
        name: site,
        rowMenu: site,
        status: site,
        type: site,
      })),
    [data]
  );

  return (
    <DataGrid
      columns={columns}
      components={{ footer: Footer }}
      componentsProps={{
        footer: {
          companyId,
        },
      }}
      defaultRowsNumber={5}
      disableRowSelectionOnClick
      hideFooterPagination
      loading={isFetching}
      onCellClick={(params) => {
        if (params.field !== 'rowMenu') {
          navigate(PATHS.SITE_DASHBOARD.replace(':companyId', companyId).replace(':siteId', params.value?.id));
        }
      }}
      onOrderBy={onOrderBy}
      order={order}
      resultsFiltered={data.length}
      resultsTotal={resultsTotal}
      rows={rows}
    />
  );
};

RecentSitesDataGrid.propTypes = {
  companyId: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  didInvalid: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onLoadSites: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  resultsTotal: PropTypes.number.isRequired,
};

export default RecentSitesDataGrid;
