import Box from '@mui/material/Box';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import commonActions, { selectors } from '../../modules/common';
import { tryCheckActiveFeatureFlags, tryCheckAppVersion } from './appHealthCheck';

export function AppHeartBeat() {
  const isVersionWarningDisplayed = useSelector(selectors.isSiteVersionWarningDisplayed);
  const featureFlags = useSelector(selectors.getGlobalFeatures);
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(
      () => {
        if (process.env.NODE_ENV !== 'development' && !isVersionWarningDisplayed) {
          tryCheckAppVersion(() => dispatch(commonActions.siteVersionWarningShow()));

          tryCheckActiveFeatureFlags(featureFlags, () => dispatch(commonActions.siteVersionWarningShow()));
        }
      },
      parseInt(process.env.APP_HEARTBEAT_INTERVAL ?? '180000')
    );
    return () => clearInterval(interval);
  }, [isVersionWarningDisplayed]);

  return <Box />;
}
