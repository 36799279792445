import { Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { InfoModal } from '../../../components/_DesignSystemWrappers/Modal';
import { IModalBase } from '../../../modules/commonTypes';
import messages from './messages';

export function ShowGsmDiagramModal({ onClose, open }: IModalBase) {
  const { formatMessage } = useIntl();

  function handleSubmit() {
    onClose();
  }

  return (
    <InfoModal
      buttonText={'Close'}
      onClose={onClose}
      open={open}
      primaryButtonAction={handleSubmit}
      title={formatMessage({ ...messages.siteSettingsCallingAllowCallingLabel })}
      muiDialogProps={{ maxWidth: 'sm' }}
    >
      {/* Replace Typography with ReactSVG when diagram is ready */}
      <Typography variant="h6" gutterBottom>
        Here will be a diagram
      </Typography>
      {/* <ReactSVG className="wrapperSvg" src={DiagramIcon} wrapper="div" /> */}
    </InfoModal>
  );
}
