import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import OutlinedTextField from '../../../../components/HookFormFields/TextField';
import { DEVICE_TYPE } from '../../../../config/devices';
import { useSiteContext } from '../../../../context/useSiteContext';
import { IFloor } from '../../../../modules/apartments/store';
import { selectors as deviceSelectors } from '../../../../modules/devices';
import { selectors as sitesSelectors } from '../../../../modules/sites';
import { IUser } from '../../../../modules/user/store';
import { ApartmentFormField } from '../../components/ApartmentFormField/ApartmentFormField';
import { NewApartmentForm } from '../../components/NewApartmentForm/NewApartmentForm';
import { INewApartmentForm } from '../../components/NewApartmentForm/validationSchema';
import UserSelectField from '../../components/UserSelectField';
import { isAvailableForApartment } from '../../helpers';
import messages from './messages';

export interface IOneStep {
  floors: IFloor[];
}

export function OneStep({ floors }: IOneStep) {
  const deviceTypes = useSelector(deviceSelectors.getCompanyDeviceTypes);
  const users = useSelector(sitesSelectors.getSiteUsers) as IUser[];
  const availableForApartment = isAvailableForApartment(deviceTypes, DEVICE_TYPE.THIRD_PARTY);
  const { watch } = useFormContext<INewApartmentForm>();
  const { isSiteFeatureEnabled } = useSiteContext();

  const isCreatingApartment = watch('hasForm');

  return (
    <>
      <Typography sx={{ mb: 2 }} variant="body2">
        <FormattedMessage {...messages.oneStepDescription} />
      </Typography>
      <Stack>
        <UserSelectField users={users} />
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs>
          <ApartmentFormField
            disabled={!availableForApartment}
            required={isSiteFeatureEnabled('NON_INTERCOM_DEVICE_FORBIDDEN_OUTSIDE_APARTMENT')}
          />
        </Grid>
        <Grid item xs>
          <OutlinedTextField
            autoFocus
            helperText={<FormattedMessage {...messages.oneStepNameHelperText} />}
            label={<FormattedMessage {...messages.oneStepNameLabel} />}
            name="name"
            required
            fullWidth
            hasTopAndBottomMargin
          />
        </Grid>
      </Grid>
      {isCreatingApartment && <NewApartmentForm floors={floors} />}
    </>
  );
}
