import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Link, LoadingButton } from '../../../../components';
import Checkbox from '../../../../components/HookFormFields/Checkbox';
import { PATHS } from '../../../../config/consts';
import OrderPayment from '../../Common/OrderPayment';
import { TotalPriceBar } from '../../Common/TotalPriceBar';
import { GP_WEBPAY } from '../../constants';
import messages from '../../messages';
import { IMobileVideoOrderContentProps } from '../../UnitPropsTS';
import { IMobileVideoLicenseFormValues } from '../hooks/useGetMVLicenceData';
import OrderList from './OrderList';

export interface ITwoStep {
  company: {
    id: number | string;
  };
  currency: string;
  hasFreeLicencesWithIntercom?: boolean;
  mobileVideoOrderContent: IMobileVideoOrderContentProps;
  mobileVideoPaymentIsLoading: boolean;
  mobileVideoPurchaseId?: string;
  mobileVideoPurchaseLocation?: string;
  monthPercentage?: number;
  onStepChange: (value: number) => void;
  step: number;
}

export function TwoStep({
  company,
  currency,
  hasFreeLicencesWithIntercom,
  mobileVideoOrderContent,
  mobileVideoPaymentIsLoading,
  mobileVideoPurchaseId,
  mobileVideoPurchaseLocation,
  monthPercentage,
  onStepChange,
  step,
}: ITwoStep) {
  const { watch } = useFormContext<IMobileVideoLicenseFormValues>();
  const paymentPrepared = watch('paymentPrepared');
  const agreeGTC = watch('agreeGTC');

  return (
    <Grid container direction="column">
      <OrderPayment company={company} currentMethod={GP_WEBPAY} showInvoiceRadio={false} />
      <OrderList
        currency={currency}
        hasFreeLicencesWithIntercom={hasFreeLicencesWithIntercom}
        mobileVideoOrderContent={mobileVideoOrderContent}
        monthPercentage={monthPercentage}
      />
      <Grid container item sx={{ mb: 1 }}>
        <TotalPriceBar
          currency={currency}
          price={mobileVideoOrderContent ? mobileVideoOrderContent.price : 0}
          priceVat={mobileVideoOrderContent ? mobileVideoOrderContent.priceVat : 0}
          totalPriceNote={false}
        />
      </Grid>
      {mobileVideoOrderContent && (
        <>
          <Grid item sx={{ ml: 1 }}>
            <Checkbox
              color="primary"
              name="paymentPrepared"
              required
              label={
                <Typography component="span" variant="body2">
                  <FormattedMessage {...messages.twoStepPaymentPrepared} />
                </Typography>
              }
            />
          </Grid>
          <Grid item sx={{ ml: 1 }}>
            <Checkbox
              color="primary"
              name="agreeGTC"
              required
              label={
                <Typography component="span" variant="body2">
                  <FormattedMessage
                    {...messages.agreeGTCLabel}
                    values={{
                      cloudLink: (chunks) => (
                        <Link
                          target="_blank"
                          to={PATHS.CLOUD_AGREEMENT_WITH_COMPANY.replace(':companyId', company?.id.toString())}
                        >
                          {chunks}
                        </Link>
                      ),
                      consumerLink: (chunks) => (
                        <Link
                          target="_blank"
                          to={PATHS.INFORMATION_FOR_CONSUMERS_WITH_COMPANY.replace(
                            ':companyId',
                            company?.id.toString()
                          )}
                        >
                          {chunks}
                        </Link>
                      ),
                    }}
                  />
                </Typography>
              }
            />
          </Grid>
        </>
      )}
      <Grid alignItems="center" container justifyContent="flex-end">
        <Grid item>
          <LoadingButton
            color="primary"
            disabled={!paymentPrepared || !agreeGTC || !mobileVideoOrderContent || !mobileVideoPurchaseId}
            href={mobileVideoPurchaseLocation}
            isLoading={mobileVideoPaymentIsLoading}
            onClick={() => onStepChange(step + 1)}
            target="_blank"
            type="button"
            variant="contained"
          >
            <FormattedMessage {...messages.subscriptionButtonSecurePayment} />
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
