import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as companySelectors } from '../../../../modules/company';
import { selectors } from '../../../../modules/subscription';
import { ICompany } from '../../useGetSubscriptionCardsData';

export function useGetPromoCodeData() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const company = useSelector(companySelectors.getCompanyData) as ICompany;
  const promoCodeData = useSelector(selectors.getPromoCodeData);
  const promoCodesDataIsLoading = useSelector(selectors.getPromoCodesDataIsLoading);
  const promoCodesError = useSelector(selectors.getPromoCodesError);
  const promoCodesOrderData = useSelector(selectors.getPromoCodesOrderData);
  const promoCodesOrderIsLoading = useSelector(selectors.getPromoCodesOrderIsLoading);
  const purchasePaymentResult = useSelector(selectors.getPurchasePaymentResult);

  return {
    company,
    dispatch,
    formatMessage,
    promoCodeData,
    promoCodesDataIsLoading,
    promoCodesError,
    promoCodesOrderData,
    promoCodesOrderIsLoading,
    purchasePaymentResult,
  };
}
