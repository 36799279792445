import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import OutlinedTextField from '../../../../components/HookFormFields/TextField';
import { DEVICE_TYPE } from '../../../../config/devices';
import { useSiteContext } from '../../../../context/useSiteContext';
import { IFloor } from '../../../../modules/apartments/store';
import { selectors as deviceSelectors } from '../../../../modules/devices';
import { selectors as sitesSelectors } from '../../../../modules/sites';
import { IUser } from '../../../../modules/user/store';
import { ApartmentFormField } from '../../components/ApartmentFormField/ApartmentFormField';
import { NewApartmentForm } from '../../components/NewApartmentForm/NewApartmentForm';
import UserSelectField from '../../components/UserSelectField';
import { isAvailableForApartment } from '../../helpers';
import { IFormData } from '../validationSchema';
import messages from './messages';

export interface IOneStep {
  floors: IFloor[];
}

export function OneStep({ floors }: IOneStep) {
  const deviceTypes = useSelector(deviceSelectors.getCompanyDeviceTypes);
  const availableForApartment = isAvailableForApartment(deviceTypes, DEVICE_TYPE.THIRD_PARTY);
  const users = useSelector(sitesSelectors.getSiteUsers) as IUser[];
  const { formState, getValues, setValue, watch } = useFormContext<IFormData>();
  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const name = getValues('name');
  const isCreatingApartment = watch('hasForm');
  const userId = getValues('userId');
  const { isSiteFeatureEnabled } = useSiteContext();

  React.useEffect(() => {
    const user = users.find((u) => u.id === userId);
    if (user !== undefined && name === '') {
      setValue('name', `${user.lastName} ${user.firstName}`);
    }
    if (!formState.dirtyFields.name && firstName !== undefined && lastName !== undefined) {
      setValue('name', `${firstName} ${lastName}`);
    }
  }, [userId, firstName, lastName]);

  const handleSurNameBlur = () => {
    if (lastName && firstName) {
      setValue('name', `${lastName} ${firstName}`);
    }
  };

  return (
    <>
      <Typography sx={{ mb: 4 }} variant="body2">
        <FormattedMessage {...messages.oneStepDescription} />
      </Typography>
      <Stack spacing={3}>
        <UserSelectField onSurNameBlur={handleSurNameBlur} users={users} />
        <OutlinedTextField
          autoFocus
          helperText={<FormattedMessage {...messages.oneStepNameHelperText} />}
          label={<FormattedMessage {...messages.oneStepNameLabel} />}
          name="name"
          required
          fullWidth
          margin="normal"
        />
        <ApartmentFormField
          disabled={!availableForApartment}
          required={isSiteFeatureEnabled('NON_INTERCOM_DEVICE_FORBIDDEN_OUTSIDE_APARTMENT')}
        />
      </Stack>
      {isCreatingApartment && <NewApartmentForm floors={floors} />}
    </>
  );
}
