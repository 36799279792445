import { IItem } from '../OneStep/OneStep';
import { IPrices, PROMO_TYPES, SUBSCRIPTION_TYPE } from './types';

export const getVoucherValue = (years: number, subscriptions: number) => years * subscriptions;

export const getFullPrice = (values: IItem[], prices: IPrices) => {
  let unitsCountTypeDevice = 0;
  let unitsCountTypeApartment = 0;
  values.forEach((item) => {
    if (item.subscriptionType === SUBSCRIPTION_TYPE.DEVICE) {
      unitsCountTypeDevice += getVoucherValue(item.years, item.subscriptions);
    } else if (item.subscriptionType === SUBSCRIPTION_TYPE.APARTMENT) {
      unitsCountTypeApartment += getVoucherValue(item.years, item.subscriptions);
    }
  });

  return {
    price:
      unitsCountTypeDevice * prices?.MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD?.unitFullPrice +
      unitsCountTypeApartment * prices?.MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT?.unitFullPrice,
    priceVat:
      unitsCountTypeDevice * prices?.MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD?.unitFullPriceVat +
      unitsCountTypeApartment * prices?.MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT?.unitFullPriceVat,
    subscriptionsCount: unitsCountTypeDevice + unitsCountTypeApartment,
  };
};

export const getPrice = (unitFullPrice: number, years: number, subscriptions: number) =>
  unitFullPrice * getVoucherValue(years, subscriptions);

export const getSubscriptionTypeBasedOnProduct = (type?: PROMO_TYPES | string) => {
  if (type === PROMO_TYPES.MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD) {
    return SUBSCRIPTION_TYPE.DEVICE;
  }
  if (type === PROMO_TYPES.MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT) {
    return SUBSCRIPTION_TYPE.APARTMENT;
  }
  return SUBSCRIPTION_TYPE.DEFAULT;
};
