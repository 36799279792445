import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getVoucherValue } from '../../hooks/helpers';
import { orderItemConfigurationPropTypes } from '../../types';
import messages from '../messages';

const ItemVoucherValue = ({ item }) => {
  const message = getMessage(item.type, item.devices);
  return (
    <Grid item sm="auto" xl={6} xs={6}>
      <Typography whiteSpace="nowrap">
        <Box component="span" fontWeight="bold">
          {getVoucherValue(item.years, item.devices)}
        </Box>{' '}
        <Box color="text.secondary" component="span">
          <FormattedMessage {...message} />
        </Box>
      </Typography>
    </Grid>
  );
};

ItemVoucherValue.propTypes = {
  item: orderItemConfigurationPropTypes.isRequired,
};

export default ItemVoucherValue;

function getMessage(itemType, devicesCount) {
  if (itemType === 'MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT') {
    return devicesCount === 1
      ? messages.orderListMobileVideoPoolCreditYearApartmentTotalValueOne
      : messages.orderListMobileVideoPoolCreditYearApartmentTotalValueMore;
  }

  return devicesCount === 1
    ? messages.orderListMobileVideoPoolCreditYearStandartTotalValueOne
    : messages.orderListMobileVideoPoolCreditYearStandartTotalValueMore;
}
