import { defineMessages } from 'react-intl';

export default defineMessages({
  addAxisDeviceAnsweringUnitTitle: {
    defaultMessage: 'Add AXIS answering unit',
    id: 'addAxisDevice.answeringUnit.title',
  },
  addAxisDeviceTitle: {
    defaultMessage: 'Add AXIS device',
    id: 'addAxisDevice.title',
  },
  doorStationAddTitle: {
    defaultMessage: 'Add an AXIS Intercom',
    description: 'DeviceTitle - door station add title',
    id: 'DOOR_STATION.addTitle',
  },
  doorStationTitle: {
    defaultMessage: 'AXIS device',
    id: 'DOOR_STATION.title',
  },
  generalAddTitle: {
    defaultMessage: 'Add device',
    description: 'DeviceTitle - general add title',
    id: 'GENERAL.addTitle',
  },
  hipmoAddTitle: {
    defaultMessage: 'Add smartphone or tablet',
    description: 'DeviceTitle - hipmo add title',
    id: 'HIPMO.addTitle',
  },
  hipmoTitle: {
    defaultMessage: 'Smartphone or tablet',
    description: 'devices - hipmo title',
    id: 'HIPMO.title',
  },
  thirdPartyAddTitle: {
    defaultMessage: 'Add 3<sup>rd</sup> party device',
    description: 'DeviceTitle - third party add title',
    id: 'THIRD_PARTY.addTitle',
  },
  thirdPartyFinishedTitle: {
    defaultMessage: 'Finished',
    description: 'DeviceTitle - general finished title',
    id: 'THIRD_PARTY.finished',
  },
  thirdPartyTitle: {
    defaultMessage: '3<sup>rd</sup> party device',
    description: 'devices - third party title',
    id: 'THIRD_PARTY.title',
  },
  x2NAddTitle: {
    defaultMessage: 'Add 2N Device',
    description: 'DeviceTitle - Add 2N Device',
    id: 'X2N.addTitle',
  },
  x2NTitle: {
    defaultMessage: '2N Device',
    description: 'devices - 2N device',
    id: 'X2N.title',
  },
});
