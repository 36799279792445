import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { SiteCategory, SiteType, useCreateSiteMutation } from '../../../apiRtk/be4feApi';
import { LANGUAGES, PATHS } from '../../../config/consts';
import { useUrlParams } from '../../../helpers/urlParams';
import useBreakpoints from '../../../helpers/useBreakpoints';
import { useGetGlobalInfo } from '../../../hooks/useGetGlobalInfo';
import { setRedirectData } from '../../../modules/infrastructure/actions';
import { TYPE_DESCRIPTOR } from '../../../modules/notifications';
import { addSnackbarError, addSnackbarSuccess } from '../../../modules/notifications/actions';
import { clearSiteData } from '../../../modules/sites/actions';
import { invalidateCurrentUser } from '../../../modules/user/actionsTS';
import messages from '../messages';
import { getCreateSiteModalValidationSchema } from '../validationSchemes/createSiteModalValidationSchema';

export interface ICreateSiteModalValues {
  lang?: string;
  name: string;
  siteCategory?: SiteCategory;
  siteType?: SiteType;
}

export function useGetCreateSiteModalHooks(onClose: () => void) {
  const { downSm } = useBreakpoints();
  const { companyId } = useUrlParams();
  const { dispatch, formatMessage } = useGetGlobalInfo();
  const [createSite, { isLoading }] = useCreateSiteMutation();

  const formMethods = useForm<ICreateSiteModalValues>({
    defaultValues: {
      lang: LANGUAGES.english.locale,
      name: '',
    },
    mode: 'onChange',
    resolver: yupResolver<ICreateSiteModalValues>(getCreateSiteModalValidationSchema(companyId, formatMessage)),
  });

  const submitButtonText = useMemo(
    () =>
      downSm ? formatMessage(messages.CreateSiteModalSubmitMobile) : formatMessage(messages.CreateSiteModalSubmit),
    [downSm]
  );

  async function handleSubmit(formData: ICreateSiteModalValues): Promise<void> {
    if (companyId !== undefined && formData.siteType !== undefined) {
      try {
        const response = await createSite({
          companyId,
          createSiteRequest: {
            locale: formData.lang,
            name: formData.name,
            type: formData.siteType,
          },
        }).unwrap();

        dispatch(invalidateCurrentUser());
        dispatch(clearSiteData());
        dispatch(
          setRedirectData(
            PATHS.SITE_DASHBOARD.replace(':companyId', companyId.toString()).replace(':siteId', response.id.toString())
          )
        );
        dispatch(addSnackbarSuccess({ ...messages.CreateSiteModalSuccess }));
      } catch (error) {
        dispatch(addSnackbarError({ ...messages.CreateSiteModalError }, TYPE_DESCRIPTOR));
      }
    }
    onClose();
  }

  return {
    formatMessage,
    formMethods,
    handleSubmit,
    isLoading,
    submitButtonText,
  };
}
