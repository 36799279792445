import { IntlShape } from 'react-intl';
import * as yup from 'yup';
import validationMessages from '../messages';
import { EmailMaxLength, NameMaxLength } from '../validationConstants';

export const BEEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

export enum UserFormFieldNames {
  firstName = 'firstName',
  lastName = 'lastName',
}

export function getFirstNameValidation<T extends boolean>(
  isFirstNameRequired: T,
  formatMessage: IntlShape['formatMessage']
): T extends true ? yup.Schema<string> : yup.Schema<string | undefined>;

export function getFirstNameValidation(
  isFirstNameRequired: boolean,
  formatMessage: IntlShape['formatMessage']
): yup.Schema<string> | yup.Schema<string | undefined> {
  return yup.string().when([], (_isBig, schema) => {
    return isFirstNameRequired
      ? schema
          .required(formatMessage(validationMessages.validationRequired))
          .max(NameMaxLength, formatMessage(validationMessages.validationMaxLength))
          .trim()
      : schema.max(NameMaxLength, formatMessage(validationMessages.validationMaxLength)).trim();
  });
}

export function getLastNameValidation(formatMessage: IntlShape['formatMessage']): yup.Schema<string> {
  return yup
    .string()
    .required(formatMessage(validationMessages.validationRequired))
    .max(NameMaxLength, formatMessage(validationMessages.validationMaxLength))
    .trim();
}

export function getVisitorNameValidation(formatMessage: IntlShape['formatMessage']): yup.Schema<string> {
  return yup
    .string()
    .required(formatMessage(validationMessages.aamodalNewGuestPinnameErrRequired))
    .max(50, formatMessage(validationMessages.aamodalNewGuestPinnameErrTooLong))
    .trim();
}

export function isMeetingBaseEmailValidation(possibleEmail?: string): boolean {
  return !!(possibleEmail && possibleEmail.length > 2 && possibleEmail.indexOf('@') > 0);
}

export function getBasicEmailValidation(invalidEmailMessage: string, requiredMessage: string) {
  return yup
    .string()
    .email(invalidEmailMessage)
    .matches(BEEmailRegex, invalidEmailMessage)
    .required(requiredMessage)
    .max(EmailMaxLength, invalidEmailMessage);
}

export function getPhoneNumberValidation(formatMessage: IntlShape['formatMessage']): yup.Schema<string | undefined> {
  return yup
    .string()
    .trim()
    .matches(/(^$)|(^\d{6,15}$)/, formatMessage(validationMessages.usersFormMobilePhoneErrFormat));
}
