import DoneIcon from '@mui/icons-material/Done';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { headSiteData } from '../../api/siteRequest';
import { SITE_TYPE } from '../../config/sites';
import { useSiteContext } from '../../context/useSiteContext';
import { isAnsweringUnit as isDeviceAnsweringUnit } from '../../helpers/devices';
import apartmentActions, { selectors as apartmentsSelectors } from '../../modules/apartments';
import { getCompanyId } from '../../modules/company/selectors';
import actions from '../../modules/devices';
import { getSiteData } from '../../modules/sites/selectors';
import FormikFields from '../FormikFields';
import Modal from '../Modal';
import ApartmentFormField from './ApartmentFormField';
import messages from './messages';
import NewApartmentForm from './NewApartmentForm';

const getValidationSchema = (companyId, site) =>
  Yup.object().shape({
    apartmentId: Yup.mixed().when(['isAnsweringUnit', 'isApartmentRequired'], {
      is: (isAnsweringUnit, isApartmentRequired) => isAnsweringUnit && isApartmentRequired,
      otherwise: (schema) => schema.notRequired(),
      then: (schema) =>
        schema.test(
          'isApartmentId',
          <FormattedMessage {...messages.deviceApproveModalApartmentRequired} />,
          (value) => Number.isInteger(value) || value === 'createNewApartment'
        ),
    }),
    existApartment: Yup.boolean().required(),
    name: Yup.string()
      .required(<FormattedMessage {...messages.deviceApproveModalRequired} />)
      .test({
        message: <FormattedMessage {...messages.deviceApproveModalNameExist} />,
        name: 'isExistName',
        test: async (name) => {
          try {
            let state = true;
            if (!name || name.length === 0) {
              return true;
            }
            await headSiteData({ companyId, siteId: site.id }, 'devices', { name }, false).then((response) => {
              state = response.status === 404;
            });

            return state;
          } catch (error) {
            return true;
          }
        },
      }),
    newApartment: Yup.object().when('apartmentId', {
      is: 'createNewApartment',
      then: (schema) =>
        schema.when('isApartmentRequired', {
          is: true,
          otherwise: (scheme) =>
            scheme.shape({
              floor: Yup.string().nullable(),
              name: Yup.string()
                .nullable()
                .required(<FormattedMessage {...messages.deviceApproveModalRequired} />),
              number: Yup.string()
                .nullable()
                .required(<FormattedMessage {...messages.deviceApproveModalRequired} />)
                .test({
                  message: <FormattedMessage {...messages.deviceApproveModalApartmentNumberExists} />,
                  name: 'isExistNumber',
                  test: async (number) => {
                    try {
                      let state = true;
                      if (!number || number.length === 0) {
                        return true;
                      }
                      await headSiteData({ companyId, siteId: site.id }, 'apartments', { number }, false).then(
                        (response) => {
                          state = response.status === 404;
                        }
                      );

                      return state;
                    } catch (error) {
                      return true;
                    }
                  },
                }),
            }),
          then: (scheme) =>
            scheme.shape({
              floor: Yup.string()
                .nullable()
                .required(<FormattedMessage {...messages.deviceApproveModalRequired} />),
              name: Yup.string()
                .nullable()
                .required(<FormattedMessage {...messages.deviceApproveModalRequired} />),
              number: Yup.string()
                .nullable()
                .required(<FormattedMessage {...messages.deviceApproveModalRequired} />)
                .test({
                  message: <FormattedMessage {...messages.deviceApproveModalApartmentNumberExists} />,
                  name: 'isExistNumber',
                  test: async (number) => {
                    try {
                      let state = true;
                      if (!number || number.length === 0) {
                        return true;
                      }
                      await headSiteData({ companyId, siteId: site.id }, 'apartments', { number }, false).then(
                        (response) => {
                          state = response.status === 404;
                        }
                      );

                      return state;
                    } catch (error) {
                      return true;
                    }
                  },
                }),
            }),
        }),
    }),
  });

const DeviceApproveModal = ({
  apartments,
  companyId,
  device,
  floors,
  onApproveDevice,
  onClose,
  onLoadApartments,
  onLoadFloors,
  open,
  site,
}) => {
  const isApartmentRequired = useSiteContext().isSiteFeatureEnabled('NON_INTERCOM_DEVICE_FORBIDDEN_OUTSIDE_APARTMENT');

  useEffect(() => {
    onLoadApartments(companyId, site.id);
    onLoadFloors(companyId, site.id);
  }, []);

  const handleSubmitForm = (values) => {
    onApproveDevice(device, values, floors);
    onClose();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        apartmentId: null,
        existApartment: true,
        isAnsweringUnit: isDeviceAnsweringUnit(device.type),
        isApartmentRequired: isApartmentRequired,
        name: device.name || device.serialNumber,
        newApartment: {
          floor: '',
          name: '',
          number: '',
        },
      }}
      isInitialValid
      onSubmit={handleSubmitForm}
      render={({ handleChange, isValid, values }) => (
        <Modal
          actions={
            <>
              <Button onClick={onClose}>
                <FormattedMessage {...messages.deviceApproveModalCancel} />
              </Button>
              <Button color="primary" disabled={!isValid} startIcon={<DoneIcon />} type="submit" variant="contained">
                <FormattedMessage {...messages.deviceApproveModalSubmit} />
              </Button>
            </>
          }
          fullWidth
          isForm
          maxWidth="sm"
          name="device-approve-modal"
          onClose={onClose}
          open={open}
          title={<FormattedMessage {...messages.deviceApproveModalBasicTitle} />}
        >
          <Typography variant="body2">
            <FormattedMessage {...messages.deviceApproveModalDescription} />
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs>
              <ApartmentFormField
                apartments={apartments}
                disabled={!isDeviceAnsweringUnit(device.type)}
                onChange={handleChange}
                value={values.apartmentId}
              />
            </Grid>
            <Grid item xs>
              <FormikFields.Input
                helperText={<FormattedMessage {...messages.deviceApproveModalNameHelper} />}
                label={<FormattedMessage {...messages.deviceApproveModalNameLabel} />}
                name="name"
                required
              />
            </Grid>
          </Grid>
          {values?.apartmentId === 'createNewApartment' && (
            <NewApartmentForm floors={floors} onChange={handleChange} siteType={site.type} values={values} />
          )}
        </Modal>
      )}
      validationSchema={getValidationSchema(companyId, site)}
    />
  );
};

DeviceApproveModal.propTypes = {
  apartments: PropTypes.arrayOf(PropTypes.object).isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  device: PropTypes.shape({
    name: PropTypes.string,
    serialNumber: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  floors: PropTypes.arrayOf(PropTypes.object).isRequired,
  onApproveDevice: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onLoadApartments: PropTypes.func.isRequired,
  onLoadFloors: PropTypes.func.isRequired,
  open: PropTypes.bool,
  site: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.string,
  }).isRequired,
};

DeviceApproveModal.defaultProps = {
  open: false,
};

const mapStateToProps = (state) => ({
  apartments: apartmentsSelectors.getApartmentsListGrouped(state),
  companyId: getCompanyId(state),
  floors: apartmentsSelectors.getFloors(state),
  site: getSiteData(state),
});

const mapDispatchToProps = {
  onApproveDevice: actions.approveDeviceRequest,
  onLoadApartments: apartmentActions.getApartmentsList,
  onLoadFloors: apartmentActions.getSiteFloorsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceApproveModal);
