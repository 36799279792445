import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import React from 'react';

const LoadingButton = ({ children, isLoading, ...other }) => (
  <Button {...other} disabled={isLoading || other.disabled} startIcon={isLoading ? null : other.startIcon}>
    {isLoading === true ? <CircularProgress color={other.color} size={24} /> : children}
  </Button>
);

LoadingButton.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
};

export default LoadingButton;
