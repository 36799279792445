import { isAfter } from 'date-fns';
import { parseISO } from 'date-fns/fp';
import { MessageDescriptor } from 'react-intl';
import * as yup from 'yup';
import { CredentialsValidityResponseDto } from '../../../../../apiRtk/accessControlApi';
import { EnrichedIUser } from '../../../../../components/UserAccessForm/SectionPin';
import { MULTIPLE_EMAIL_SEPARATOR } from '../../../../../config/consts';
import { ApartmentUser, EnrichedApartmentUser } from '../../../../../modules/apartmentAdmin/data';
import { BEEmailRegex, getVisitorNameValidation } from '../../../../../validations/user/userCommonValidations';
import { getMessage, VisitorModalMsgContext } from './helpers';

export interface IApartmentAdminPinModalFormData {
  apartmentAccess: boolean;
  startOfValidity: Date | undefined;
  endOfValidity: Date | undefined;
  emailAddresses: string;
  siteNameInEmail: string;
  lastName: string;
  pin: number;
  sendInfoEmail: boolean;
}

export enum FormFieldNames {
  ApartmentAccess = 'apartmentAccess',
  LastName = 'lastName',
  Pin = 'pin',
  StartOfValidity = 'startOfValidity',
  EndOfValidity = 'endOfValidity',
  EmailAddresses = 'emailAddresses',
  SiteNameInEmail = 'siteNameInEmail',
  SendInfoEmail = 'sendInfoEmail',
}

export function getDefaultValues(
  siteName: string | undefined,
  user: ApartmentUser | EnrichedApartmentUser | EnrichedIUser | undefined,
  isRegenerate?: boolean,
  userCredentialsValidity?: CredentialsValidityResponseDto
) {
  return {
    [FormFieldNames.ApartmentAccess]: userCredentialsValidity?.apartmentAccess || false,
    [FormFieldNames.EmailAddresses]: '',
    [FormFieldNames.EndOfValidity]: user?.validTo ? parseISO(user?.validTo) : undefined,
    [FormFieldNames.LastName]: user?.lastName || '',
    [FormFieldNames.Pin]: '',
    [FormFieldNames.SendInfoEmail]: isRegenerate || user === undefined,
    [FormFieldNames.SiteNameInEmail]: siteName || '',
    [FormFieldNames.StartOfValidity]: user?.validFrom ? parseISO(user?.validFrom) : undefined,
  };
}

export function getValidationSchema(
  formatMessage: (message: MessageDescriptor) => string,
  isEdit: boolean,
  msgContext: VisitorModalMsgContext
) {
  return yup.object().shape({
    [FormFieldNames.LastName]: getVisitorNameValidation(formatMessage),
    [FormFieldNames.Pin]: yup.string().required().max(15),
    [FormFieldNames.StartOfValidity]: yup
      .date()
      .test('startBeforeEnd', formatMessage(getMessage(msgContext, 'StartErrLater')), (value, context) =>
        value && !!context.parent[FormFieldNames.EndOfValidity]
          ? isAfter(context.parent[FormFieldNames.EndOfValidity], value)
          : true
      ),
    [FormFieldNames.EndOfValidity]: yup
      .date()
      .test('endBeforeStart', formatMessage(getMessage(msgContext, 'EndErrEarlier')), (value, context) =>
        value && !!context.parent[FormFieldNames.StartOfValidity]
          ? isAfter(value, context.parent[FormFieldNames.StartOfValidity])
          : true
      ),
    [FormFieldNames.EmailAddresses]: yup
      .string()
      .test('emailsInvalid', formatMessage(getMessage(msgContext, 'EmailsErrInvalid')), (value) => {
        const modifiedValue = value?.replace(/\s/g, '');
        return modifiedValue
          ? !modifiedValue.split(MULTIPLE_EMAIL_SEPARATOR).some((modifiedValue) => !BEEmailRegex.test(modifiedValue))
          : true;
      })
      .test('emailsTooMany', formatMessage(getMessage(msgContext, 'EmailsErrTooMany')), (value) => {
        const values = value ? value.split(MULTIPLE_EMAIL_SEPARATOR) : [];
        return values.length <= 30;
      })
      .test('reuqired', '', (value, context) => {
        if (isEdit && context.parent.sendInfoEmail) {
          return !!value;
        } else {
          return true;
        }
      })
      .trim(),
    [FormFieldNames.SiteNameInEmail]: yup
      .string()
      .max(255, formatMessage(getMessage(msgContext, 'SitenameErrTooLong')))
      .trim(),
    [FormFieldNames.ApartmentAccess]: yup.boolean(),
    [FormFieldNames.SendInfoEmail]: yup.boolean(),
  });
}
