import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';

const styles = {
  rightGridPrice: {
    ml: {
      xl: 2,
      xs: 0,
    },
  },
  root: {
    mb: 4,
  },
  totalPriceContainer: {
    backgroundColor: 'subscription.backgroundLight',
    flexFlow: 'row',
    minHeight: (theme: Theme) => theme.spacing(8),
    p: {
      xl: 3,
      xs: 2,
    },
  },
};

export interface ITotalPriceBar {
  currency: string;
  price: number;
  priceVat: number;
  totalPriceNote?: React.ReactNode;
}

export function TotalPriceBar({ currency, price, priceVat, totalPriceNote }: ITotalPriceBar) {
  const { formatNumber } = useIntl();

  return (
    <Grid container direction="column" item sx={styles.root}>
      <Grid alignItems="center" container item sx={styles.totalPriceContainer}>
        <Grid alignItems="baseline" container item sm={7} xs={12}>
          <FormattedMessage
            {...messages.totalPriceBarTotalPriceTitle}
            values={{
              strong: (chunks) => (
                <Typography fontWeight="bold" variant="h6">
                  {chunks}
                </Typography>
              ),
            }}
          />
          <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
            {totalPriceNote && (
              <Box color="text.secondary" pl={1}>
                {totalPriceNote}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid alignItems="baseline" container item justifyContent="flex-end" sm={5} xs={12}>
          <Grid alignItems="baseline" container item justifyContent="flex-end" sm sx={styles.rightGridPrice} xl={7}>
            <Grid item xl={6} />
            <Grid item sm="auto" sx={{ textAlign: 'end' }} xl={6}>
              <Box fontSize="h6.fontSize" fontWeight="medium">
                {formatNumber(price, { currency, style: 'currency' })}
              </Box>
            </Grid>
          </Grid>
          <Grid item sm="auto" xl="auto">
            <Box color="text.secondary" pl={1}>
              <FormattedMessage
                {...messages.totalPriceBarPriceVatInfo}
                values={{
                  priceVat: formatNumber(priceVat, { currency, style: 'currency' }),
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {priceVat === 1 && (
        <Grid container item sx={{ mt: 2 }}>
          <Typography color="secondary" variant="body2">
            <FormattedMessage
              {...messages.totalPriceBarAdditionalNote}
              values={{ priceVat: formatNumber(priceVat, { currency, style: 'currency' }) }}
            />
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
