export const CONTEXT_REMOTE_CONFIGURATION = 'REMOTE_CONFIGURATION';
export const CONTEXT_MOBILE_VIDEO = 'MOBILE_VIDEO';
export const CONTEXT_MOBILE_VIDEO_GUIDE = 'MOBILE_VIDEO_GUIDE';

export enum SERVICE {
  ACCESS_CONTROL = 'ACCESS_CONTROL',
  ACS = 'ACS',
  ELEVATOR_INTEGRATION = 'ELEVATOR_INTEGRATION',
  GSM_CALLS = 'GSM_CALLS',
  LOCAL_CALLS = 'LOCAL_CALLS',
  MOBILE_VIDEO = 'MOBILE_VIDEO',
  NOTIFICATION = 'NOTIFICATION',
  REMOTE_CONFIGURATION = 'REMOTE_CONFIGURATION',
  RINGING_GROUP = 'RINGING_GROUP',
}

export const DEVICE_TYPE = {
  ACCESS_UNIT: 'ACCESS_UNIT',
  ACCESS_UNIT_M: 'ACCESS_UNIT_M',
  AXIS_ANSWERING_UNIT: 'AXIS_ANSWERING_UNIT',
  AXIS_THIRD_PARTY: 'AXIS_THIRD_PARTY',
  CHAMELEON: 'CHAMELEON',
  CLIP: 'CLIP',
  DOOR_STATION: 'DOOR_STATION',
  HIPMO: 'HIPMO',
  ID_COMPACT: 'ID_COMPACT',
  ID_TALK: 'ID_TALK',
  ID_VIEW: 'ID_VIEW',
  IDT: 'IDT',
  IP_INTERCOM: 'IP_INTERCOM',
  IP_INTERCOM_V2: 'IP_INTERCOM_V2',
  IP_ONE_INTERCOM: 'IP_ONE_INTERCOM',
  IP_STYLE: 'IP_STYLE',
  KONE_IP_INTERCOM: 'KONE_IP_INTERCOM',
  THIRD_PARTY: 'THIRD_PARTY',
};

export const CONFIGURATION_MODE = {
  AUTOMATIC: 'AUTOMATIC',
  MANUAL: 'MANUAL',
};

export const ANSWERING_MODE = {
  AUTO: 'AUTO',
  DTMF: 'DTMF',
  MANUAL: 'MANUAL',
  OFF: 'OFF',
};

export const FACE_TRACKING_MODE = {
  DISABLED: 'DISABLED',
  ZOOM_AND_PTZ: 'ZOOM_AND_PTZ',
};

export const CONNECTION_MODE = {
  CLOUD: 'CLOUD',
  LOCAL: 'LOCAL',
};

export const DEVICE_STATUS = {
  ERROR: 'ERROR',
  INACTIVE: 'INACTIVE',
  OK: 'OK',
  WARN: 'WARN',
};

export const TEXT_FIELD = {
  CHARACTER_LIMIT: 25,
};

export enum FEATURES {
  BUTTON_CONFIGURATION = 'BUTTON_CONFIGURATION',
  CONTACT_LIST = 'CONTACT_LIST',
  DISPLAY = 'DISPLAY',
  DTMF_CODE = 'DTMF_CODE',
  DTMF_SWITCH_LIST = 'DTMF_SWITCH_LIST',
  FACE_TRACKING = 'FACE_TRACKING',
}

export enum RC_STATUS {
  CONNECTED = 'CONNECTED',
  CONNECTION_REQUESTED = 'CONNECTION_REQUESTED',
  DISABLED = 'DISABLED',
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
  UNSUPPORTED_FIRMWARE = 'UNSUPPORTED_FIRMWARE',
}

export enum PASSWORD_STATUS {
  CHANGED_PASSWORD = 'CHANGED_PASSWORD',
  DEFAULT_PASSWORD = 'DEFAULT_PASSWORD',
}
export enum DTMFSwitchAction {
  CREATE_DTMF_SWITCH = 'CREATE_DTMF_SWITCH',
  UPDATE_DTMF_SWITCH = 'UPDATE_DTMF_SWITCH',
}

export const VERIFICATION_STATUS = {
  NOT_VERIFIED: 'NOT_VERIFIED',
  PENDING: 'PENDING',
  VERIFIED: 'VERIFIED',
  ZOMBIE: 'ZOMBIE',
};

export const DEVICE_CATEGORY = {
  accessUnit: 'accessUnit',
  answeringUnit: 'answeringUnit',
  intercom: 'intercom',
  smartphoneOrTablet: 'smartphoneOrTablet',
  thirdParty: 'thirdParty',
};

export enum CALLS_STATUS {
  CONFIGURATION_NEEDED = 'CONFIGURATION_NEEDED',
  CREDENTIALS_NEEDED = 'CREDENTIALS_NEEDED',
  LOCAL = 'LOCAL',
  MISSING_CALL_SETTINGS = 'MISSING_CALL_SETTINGS',
  NOT_REGISTERED = 'NOT_REGISTERED',
  READY = 'READY',
  REGISTERED = 'REGISTERED',
  REGISTERING = 'REGISTERING',
  SYNCHRONIZATION_FAILED = 'SYNCHRONIZATION_FAILED',
  SYNCHRONIZING = 'SYNCHRONIZING',
  UNLICENSED = 'UNLICENSED',
  UNREACHABLE = 'UNREACHABLE',
  WAITING = 'WAITING',
}

export const FIRMWARE_STATUS = {
  DEPRECATED: 'DEPRECATED',
  RECOMMENDED: 'RECOMMENDED',
  SUPPORTED_NEWER: 'SUPPORTED_NEWER',
  SUPPORTED_OLDER: 'SUPPORTED_OLDER',
  UNKNOWN: 'UNKNOWN',
  UNSUPPORTED: 'UNSUPPORTED',
};

export const QR_CODE_DEVICE_MASK = /^(SN: )([\d-]{12})(\r?\n|\r)(SC: )([\d\w-]{19})$/;
export const DEVICE_SERIAL_CODE_MASK = /^(\d{2}-\d{4}-\d{4})$/i;

export enum SIP_PROFILE_NUMBER {
  SIP_1 = 'SIP_1',
  SIP_2 = 'SIP_2',
  SIP_3 = 'SIP_3',
  SIP_4 = 'SIP_4',
}
