import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { APARTMENT_STATUS, CURRENCY, MOBILE_VIDEO_STATUS, MOBILE_VIDEO_TYPE, PATHS } from '../../config/consts';
import { SITE_TYPE } from '../../config/sites';
import { useSiteContext } from '../../context/useSiteContext';
import { isStandardMVType } from '../../helpers/mobileVideo';
import { useUrlParams } from '../../helpers/urlParams';
import { useAppContext } from '../../pages/App/AppContextProvider';
import ButtonLink from '../ButtonLink';
import DashboardCardHeader from '../DashboardCardHeader';
import CreditsRequirement from './CreditsRequirement';
import ExpirationDate from './ExpirationDate';
import { FreeDevices } from './FreeDevices';
import messages from './messages';
import NextConsumption from './NextConsumption';
import NextPayment from './NextPayment';

const styles = {
  count: {
    color: 'text.primary',
    fontSize: '68px',
    fontWeight: 300,
    lineHeight: '50px',
    marginBottom: 1,
  },
  errorColor: {
    color: 'error.main',
  },
};

const CreditPoolCard = ({
  apartments,
  currency,
  isRecurringPayment,
  licenceModel,
  mobileVideoDevices,
  siteType,
}) => {
  const { companyId, siteId } = useUrlParams();
  const { feSiteRoles } = useAppContext();
  const isColorError = () => !isRecurringPayment && licenceModel?.licencesPool < licenceModel?.requirePaymentCount;
  const isPerDeviceBillingType = useSiteContext().hasSiteBillingType('PER_DEVICE');
  const isFreeLicenceAlertDisplayed =
    siteType !== SITE_TYPE.OTHER &&
    licenceModel?.freeLicencesWithIntercomCount > 0 &&
    isStandardMVType(licenceModel?.type, licenceModel?.status);

  return (
    <Card sx={{ height: '100%' }}>
      <DashboardCardHeader
        action={
          <ButtonLink
            color="primary"
            to={PATHS.SUBSCRIPTION_ACTIVATION_CODE.replace(':companyId', companyId).replace(':siteId', siteId)}
            variant="text"
          >
            <FormattedMessage {...messages.creditPoolCardVoucher} />
          </ButtonLink>
        }
        title={<FormattedMessage {...messages.creditPoolCardTitle} />}
      />
      <Grid container direction="column" sx={{ pb: 3, pt: { sm: 4, xs: 2 }, px: 3 }}>
        <div>
          <Typography sx={{ ...styles.count, ...(isColorError() && styles.errorColor) }}>
            {licenceModel?.licencesPool?.toFixed(1)}
          </Typography>
          <Typography variant="body2">
            <FormattedMessage
              {...(isPerDeviceBillingType
                ? messages.CreditPoolCardCreditsPerDevice
                : messages.CreditPoolCardCreditsPerApartment)}
              values={{
                count: licenceModel?.licencesPool?.toFixed(0) ?? 0,
              }}
            />
          </Typography>
        </div>
        <div>
          <CreditsRequirement
            count={licenceModel?.requirePaymentCount}
            licenceModelType={licenceModel?.type}
            multiplier={licenceModel?.combinedMultiplier}
          />
          <FreeDevices apartments={apartments} devices={mobileVideoDevices} status={licenceModel?.status || ''} />
          <NextConsumption
            licencesPool={licenceModel?.licencesPool}
            multiplier={licenceModel?.combinedMultiplier}
            requirePaymentCount={licenceModel?.requirePaymentCount}
            validityTo={licenceModel?.validityTo}
          />
          <ExpirationDate
            expirationDate={licenceModel?.poolExpiration || licenceModel?.validityTo}
            isRecurringPayment={isRecurringPayment}
            licencesPool={licenceModel?.licencesPool}
            multiplier={licenceModel?.combinedMultiplier}
            requirePaymentCount={licenceModel?.requirePaymentCount}
          />
          {licenceModel?.nextPayment !== null && (feSiteRoles.isCompanyAdmin || feSiteRoles.isSiteAdminPayment) && (
            <NextPayment
              chargeVat={licenceModel?.nextPayment?.calculation?.chargeVat}
              creditsCount={licenceModel?.nextPayment?.calculation?.licencesPool}
              currency={currency}
              isRecurringPayment={isRecurringPayment}
              paymentDate={licenceModel?.nextPayment?.date}
              price={licenceModel?.nextPayment?.calculation?.price}
              priceVat={licenceModel?.nextPayment?.calculation?.priceVat}
            />
          )}
          {isFreeLicenceAlertDisplayed && (
            <Typography color="success.main" sx={{ mt: 2 }} variant="body2">
              <FormattedMessage
                {...messages.CreditPoolCardApartmentModelFreeWithIntercom}
                values={{ count: licenceModel?.freeLicencesWithIntercomCount }}
              />
            </Typography>
          )}
        </div>
      </Grid>
    </Card>
  );
};

CreditPoolCard.propTypes = {
  apartments: PropTypes.shape({
    [APARTMENT_STATUS.ACTIVE_FOR_FREE]: PropTypes.number,
    [APARTMENT_STATUS.ACTIVE_SUBSCRIPTIONS]: PropTypes.number,
    [APARTMENT_STATUS.NO_SERVICE]: PropTypes.number,
    [APARTMENT_STATUS.UNLICENSED]: PropTypes.number,
    [APARTMENT_STATUS.OTHER]: PropTypes.number,
  }).isRequired,
  currency: PropTypes.oneOf(Object.keys(CURRENCY)),
  isRecurringPayment: PropTypes.bool,
  licenceModel: PropTypes.shape({
    billingType: PropTypes.string,
    combinedMultiplier: PropTypes.number,
    freeLicencesWithIntercomCount: PropTypes.number,
    licencesPool: PropTypes.number,
    licensedCount: PropTypes.number,
    nextPayment: PropTypes.shape({
      calculation: PropTypes.shape({
        chargeVat: PropTypes.bool,
        licencesPool: PropTypes.number,
        multiplier: PropTypes.number,
        price: PropTypes.number,
        priceVat: PropTypes.number,
      }),
      date: PropTypes.string,
    }),
    poolExpiration: PropTypes.string,
    requirePaymentCount: PropTypes.number,
    status: PropTypes.oneOf(Object.keys(MOBILE_VIDEO_STATUS)),
    type: PropTypes.oneOf(Object.keys(MOBILE_VIDEO_TYPE)),
    validityTo: PropTypes.string,
  }),
  mobileVideoDevices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  siteType: PropTypes.string.isRequired,
};

CreditPoolCard.defaultProps = {
  currency: CURRENCY.EUR,
  isRecurringPayment: false,
  licenceModel: {},
};

export default CreditPoolCard;
