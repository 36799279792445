import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import React, { useLayoutEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { PricingModal, Stepper, Title } from '../../../components';
import AvailableCreditDialog from '../../../components/AvailableCreditModal';
import { SERVICE } from '../../../config/devices';
import { PROCESS } from '../../../config/subscription';
import { useModal } from '../../../hooks/useModal';
import { BillingType } from '../../../modules/commonTypes';
import siteActions from '../../../modules/sites';
import actions from '../../../modules/subscription';
import ThreeStep from '../Common/ThreeStep';
import { GP_WEBPAY } from '../constants';
import { getSubscriptionStepsIntl } from '../helpers';
import messages from '../messages';
import subscriptionStyles from '../styles';
import { IMobileVideoLicenseFormValues, useGetMVLicenceData } from './hooks/useGetMVLicenceData';
import OneStep from './OneStep';
import TwoStep from './TwoStep';
import WarningBox from './WarningBox';

const styles = {
  addButton: {
    mt: {
      md: 1,
      xs: 0,
    },
  },
  loader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    px: 0,
    py: 4,
  },
  root: {
    '& sub': {
      verticalAlign: 'text-bottom',
    },
  },
};

export function MobileVideoLicense() {
  const {
    calculation,
    company,
    currency,
    dispatch,
    formatMessage,
    formMethods,
    isAvailableCredit,
    loading,
    mobileVideoLicenceModel,
    mobileVideoOrderContent,
    mobileVideoPaymentIsLoading,
    mobileVideoPurchaseId,
    mobileVideoPurchaseLocation,
    mobileVideoStartPurchase,
    monthPercentage,
    nextPayment,
    purchasePaymentResult,
    regularPayment,
    siteId,
    unlicensedMobileVideoCount,
  } = useGetMVLicenceData();
  const [isOpenPricingModal, setIsOpenPricingModal] = useState(false);
  const [step, setStep] = useState(1);
  const hasFreeLicencesWithIntercom = mobileVideoLicenceModel.freeLicencesWithIntercomCount > 0;

  const { Modal: AvailableCreditModal, onOpen: onOpenAvailableCreditModal } = useModal({
    Modal: AvailableCreditDialog,
  });

  useLayoutEffect(() => {
    dispatch(siteActions.getCurrentSiteLicenseModel());

    if (isAvailableCredit && mobileVideoLicenceModel.billingType === BillingType.PER_DEVICE) {
      onOpenAvailableCreditModal();
    } else {
      dispatch(actions.getMobileVideoLicenseRequest());
    }
  }, [isAvailableCredit, mobileVideoLicenceModel.billingType]);

  function submitForm(data: IMobileVideoLicenseFormValues) {
    const productData = {
      content: [
        {
          licences: unlicensedMobileVideoCount,
          orderType: 'RECURRING',
          product: data.product,
          siteId,
          type: SERVICE.MOBILE_VIDEO,
        },
      ],
      paymentMethod: GP_WEBPAY,
    };
    setStep(step + 1);
    dispatch(actions.setMobileVideoProduct(productData));
  }

  if (loading) {
    return (
      <Box sx={styles.loader}>
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <>
      <Title heading={<FormattedMessage {...messages.subscriptionMOBILE_VIDEO_LICENSETitle} />} />
      <Stepper activeStep={step} steps={getSubscriptionStepsIntl(formatMessage)} />
      <Paper sx={subscriptionStyles.root}>
        <>
          {mobileVideoStartPurchase === PROCESS.STOP && (
            <WarningBox billingType={mobileVideoLicenceModel.billingType} />
          )}
          {mobileVideoStartPurchase === PROCESS.START && !isAvailableCredit && (
            <>
              {isOpenPricingModal && (
                <PricingModal
                  currency={currency}
                  isOpenPricingModal={isOpenPricingModal}
                  onClosePricingModal={() => setIsOpenPricingModal(false)}
                />
              )}
              <Box sx={styles.root}>
                <FormProvider {...formMethods}>
                  <form autoComplete="off" noValidate onSubmit={formMethods.handleSubmit(submitForm)}>
                    {step === 1 && (
                      <OneStep
                        calculation={calculation}
                        currency={currency}
                        hasFreeLicencesWithIntercom={hasFreeLicencesWithIntercom}
                        nextPayment={nextPayment}
                        regularPayment={regularPayment}
                        setIsOpenPricingModal={setIsOpenPricingModal}
                      />
                    )}
                    {step === 2 && (
                      <TwoStep
                        company={company}
                        currency={currency}
                        hasFreeLicencesWithIntercom={hasFreeLicencesWithIntercom}
                        mobileVideoOrderContent={mobileVideoOrderContent}
                        mobileVideoPaymentIsLoading={mobileVideoPaymentIsLoading}
                        mobileVideoPurchaseId={mobileVideoPurchaseId}
                        mobileVideoPurchaseLocation={mobileVideoPurchaseLocation}
                        monthPercentage={monthPercentage}
                        onStepChange={setStep}
                        step={step}
                      />
                    )}
                    {step === 3 && <ThreeStep purchasePaymentResult={purchasePaymentResult} />}
                  </form>
                </FormProvider>
              </Box>
            </>
          )}
        </>
      </Paper>
      {isAvailableCredit && <AvailableCreditModal />}
    </>
  );
}
