import React from 'react';
import { FormattedMessage } from 'react-intl';
import { OutlinedTextField } from '../../../../../../components/HookFormFields/TextField';
import { INameProps } from '../../../hooks/types';
import messages from '../messages';

export function VoucherName({ name }: INameProps) {
  return (
    <OutlinedTextField
      fullWidth
      id="PromoBatchForm-name"
      InputLabelProps={{
        required: false,
        shrink: true,
      }}
      label={<FormattedMessage {...messages.promoBatchFormInputNameLabel} />}
      margin="dense"
      name={`${name}.name`}
      required
      size="small"
      type="text"
    />
  );
}
