import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SectionHeader } from '../../../components';
import actions from '../../../modules/subscription';
import { GP_WEBPAY, INVOICE } from '../constants';
import messages from '../messages';
import { useGetPromoCodeData } from '../PromoCode/hooks/useGetPromoCodeData';

function PaymentMethod({ currentMethod, invoiceDisabled, showInvoiceRadio }) {
  const { dispatch } = useGetPromoCodeData();
  return (
    <>
      <SectionHeader headerText={<FormattedMessage {...messages.paymentMethodHeader} />} variant="h6" />
      <RadioGroup
        name="paymentMethod"
        onChange={({ target }) => dispatch(actions.setPromoCodesPayments({ paymentMethod: target.value }))}
        value={currentMethod}
      >
        <FormControlLabel
          control={<Radio color="primary" />}
          label={<FormattedMessage {...messages.paymentMethodWebPay} />}
          value={GP_WEBPAY}
        />
        {showInvoiceRadio && (
          <FormControlLabel
            control={<Radio color="primary" />}
            disabled={invoiceDisabled}
            label={<FormattedMessage {...messages.paymentMethodInvoice} />}
            value={INVOICE}
          />
        )}
      </RadioGroup>
    </>
  );
}

PaymentMethod.propTypes = {
  currentMethod: PropTypes.string.isRequired,
  invoiceDisabled: PropTypes.bool,
  showInvoiceRadio: PropTypes.bool,
};

PaymentMethod.defaultProps = {
  invoiceDisabled: false,
  showInvoiceRadio: true,
};

export default PaymentMethod;
