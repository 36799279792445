import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import { UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import RemoveBatchButton from '../../Common/RemoveBatchButton';
import RemoveBatchModal from '../../Common/RemoveBatchModal';
import { IFormValues } from '../OneStep';
import { SubscriptionTypeSelect } from './Fields/SubscriptionTypeSelect';

export interface IRowOneProps {
  batchIndex: number;
  removeFromArray: UseFieldArrayRemove;
  name: string;
}
export function RowOne({ batchIndex, name, removeFromArray }: IRowOneProps) {
  const { getValues } = useFormContext<IFormValues>();
  const batchValues = getValues('array');
  const [removeDialog, setRemoveDialog] = useState({ index: 0, open: false });

  return (
    <Grid alignItems="center" container item justifyContent="space-between" xs={12}>
      <Grid item lg={5} sm={9} xl={5} xs={12}>
        <SubscriptionTypeSelect name={name} />
      </Grid>
      <Grid item sx={{ mr: -1 }}>
        <RemoveBatchButton
          disabled={!(batchValues.length > 1)}
          onClick={() => setRemoveDialog({ index: batchIndex, open: true })}
        />
      </Grid>
      <RemoveBatchModal
        onClose={() => setRemoveDialog({ index: 0, open: false })}
        removeFromArray={removeFromArray}
        removeIndex={removeDialog.index}
        open={removeDialog.open}
      />
    </Grid>
  );
}
