import { defineMessages } from 'react-intl';

export default defineMessages({
  SharedLabelCurrentPassword: {
    defaultMessage: 'Current password',
    id: 'shared.label.currentPassword',
  },
  SharedNotificationErrTryAgain: {
    defaultMessage: 'There was an error, please try again',
    id: 'shared.notification.ErrTryAgain',
  },
  TwoFactorAuthenticationModalLinkBack: {
    defaultMessage: 'Back',
    id: 'twoFactorAuthentication.modal.link.back',
  },
  TwoFactorAuthenticationModalLinkCancel: {
    defaultMessage: 'Cancel',
    id: 'twoFactorAuthentication.modal.link.cancel',
  },
  TwoFactorAuthenticationModalLinkConfirm: {
    defaultMessage: 'SAVE',
    id: 'twoFactorAuthentication.modal.link.confirm',
  },
  TwoFactorAuthenticationModalLinkNext: {
    defaultMessage: 'Next',
    id: 'twoFactorAuthentication.modal.link.next',
  },
  TwoFactorAuthenticationModalLinkStep1Text1: {
    defaultMessage: 'To enhance the security of your account, you can enable two-factor authentication.',
    id: 'twoFactorAuthentication.modal.link.step1.text1',
  },
  TwoFactorAuthenticationModalLinkStep1Text2: {
    defaultMessage: '<b>Step 1</b>: Download an authenticator app on your mobile device.',
    id: 'twoFactorAuthentication.modal.link.step1.text2',
  },
  TwoFactorAuthenticationModalLinkStep1Text3: {
    defaultMessage: `<ul><li>We recommend using authenticator applications from reputable vendors such as Google or Microsoft.</li>      
<li>If you already have an authenticator app installed, you can proceed to the next step.</li></ul>`,
    id: 'twoFactorAuthentication.modal.link.step1.text3',
  },
  TwoFactorAuthenticationModalLinkStep1Text4: {
    defaultMessage: '<b>Step 2</b>: Click the button below to link your authenticator app to your account.',
    id: 'twoFactorAuthentication.modal.link.step1.text4',
  },
  TwoFactorAuthenticationModalLinkStep1WarningAppLogout: {
    defaultMessage: `If you're using the My2N mobile app, setting up two-factor authentication will log you out of it.`,
    id: 'twoFactorAuthentication.modal.link.step1.warningAppLogout',
  },
  TwoFactorAuthenticationModalLinkStep2AuthCodeHelper: {
    defaultMessage: 'Enter the code from your authenticator app',
    id: 'twoFactorAuthentication.modal.link.step2.authCode.helper',
  },
  TwoFactorAuthenticationModalLinkStep2AuthCodeTitle: {
    defaultMessage: 'Authentication code',
    id: 'twoFactorAuthentication.modal.link.step2.authCode.title',
  },
  TwoFactorAuthenticationModalLinkStep2Text1: {
    defaultMessage: 'Scan the QR code below with your authenticator app.',
    id: 'twoFactorAuthentication.modal.link.step2.text1',
  },
  TwoFactorAuthenticationModalLinkStep2Text2: {
    defaultMessage: `If you can't scan the QR code, use this code instead:`,
    id: 'twoFactorAuthentication.modal.link.step2.text2',
  },
  TwoFactorAuthenticationModalLinkSuccess: {
    defaultMessage: 'Authenticator app linked successfully',
    id: 'twoFactorAuthentication.modal.link.success',
  },
  TwoFactorAuthenticationModalLinkTitle: {
    defaultMessage: 'Two-factor authentication',
    id: 'twoFactorAuthentication.modal.link.title',
  },
});
