import InfoIcon from '@mui/icons-material/Info';
import { Stack } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { UseBreakpoints } from '@my2n/use-breakpoints';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { SITE_CATEGORY } from '../../../../config/sites';
import { LearnAboutSiteTypesText } from '../../components/LearnAboutSiteTypesText';
import { ICreateSiteModalValues } from '../../hooks/useGetCreateSiteModalHooks';
import { SiteTypeDescription } from './components/SiteTypeDescription';
import { SiteTypesList } from './components/SiteTypesList';
import { getDescriptionItemValues } from './helpers';
import messages from './messages';

export function SelectSiteTypeStep() {
  const { upMd } = UseBreakpoints();
  const { getValues } = useFormContext<ICreateSiteModalValues>();
  const isResidential = getValues('siteCategory') === SITE_CATEGORY.RESIDENTIAL;
  const { description, icon, title } = getDescriptionItemValues(isResidential, getValues('name') !== undefined);

  return (
    <Stack spacing={3} sx={{ mb: 4 }}>
      <LearnAboutSiteTypesText />
      <SiteTypeDescription
        icon={icon}
        title={<FormattedMessage {...title} />}
        description={<FormattedMessage {...description} />}
      />
      <Stack spacing={1}>
        <Stack direction="row" spacing={1}>
          <Typography sx={{ fontWeight: 'bold' }}>
            <FormattedMessage {...messages.CreateSiteModalSiteTypeResidentialSubtypeSelect} />
          </Typography>
          <Tooltip
            title={<FormattedMessage {...messages.CreateSiteModalSiteTypeResidentialSubtypeInfoTooltip} />}
            placement={upMd ? 'right-start' : 'bottom-end'}
          >
            <InfoIcon sx={{ color: 'info.dark' }} />
          </Tooltip>
        </Stack>
        <SiteTypesList isResidential={isResidential} />
      </Stack>
    </Stack>
  );
}
