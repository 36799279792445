import { FormControl, FormHelperText, InputLabel, InputLabelProps } from '@mui/material';
import MuiSelect, { SelectProps } from '@mui/material/Select';
import React from 'react';
import { Control, Controller, FieldPath, FieldValues, useFormContext } from 'react-hook-form';

interface ISelectWithControl<T extends FieldValues> extends SelectProps, Pick<InputLabelProps, 'shrink'> {
  control: Control<T>;
  name: FieldPath<T>;
  helperText?: string;
}

export interface ISelect<T extends FieldValues> extends SelectProps, Pick<InputLabelProps, 'shrink'> {
  name: FieldPath<T>;
  helperText?: string;
}

export function Select<T extends FieldValues>({ name, ...other }: ISelect<T>): React.JSX.Element;
/** @deprecated primary use component without control (control is acquired from context) */
export function Select<T extends FieldValues>({ control, name, ...other }: ISelectWithControl<T>): React.JSX.Element;

export function Select<T extends FieldValues>({
  control,
  helperText,
  id,
  label,
  name,
  required,
  shrink,
  ...other
}: ISelectWithControl<T>) {
  const { control: contextControl, watch } = useFormContext<T>();
  return (
    <Controller
      control={control ?? contextControl}
      name={name}
      render={({ field, fieldState }) => (
        <FormControl sx={{ mb: 1, mt: 2, ...other.sx }}>
          <InputLabel
            error={!!fieldState?.error}
            disableAnimation
            required={required}
            htmlFor={id}
            variant="outlined"
            defaultChecked
            shrink
          >
            {label}
          </InputLabel>
          <MuiSelect {...other} {...field} notched label={label} value={watch(name)} required={required} fullWidth />
          {helperText && (
            <FormHelperText error={!!fieldState?.error?.message}>
              {' '}
              {fieldState?.error?.message || helperText || ' '}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}

export default Select;
