import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';

interface IValidationIssuesItem {
  errorCondition: boolean;
  description: React.ReactElement;
}

export function ValidationIssuesItem({ description, errorCondition }: IValidationIssuesItem) {
  return (
    <Stack direction="row" spacing={1}>
      {errorCondition ? <ClearIcon color="error" /> : <CheckIcon color="success" />}
      <Typography variant="body2">{description}</Typography>
    </Stack>
  );
}
