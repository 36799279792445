import { defineMessages } from 'react-intl';

export default defineMessages({
  accessAndSecurityAssignRfidCard: {
    defaultMessage: 'Assign RFID card',
    description: 'Access & Security - Assign RFID card',
    id: 'accessAndSecurity.assignRfidCard',
  },
  accessAndSecurityChangePassword: {
    defaultMessage: 'Change password',
    description: 'Access & Security - Change password button',
    id: 'accessAndSecurity.changePassword',
  },
  accessAndSecurityGenerateNewPin: {
    defaultMessage: 'Generate new pin',
    description: 'Access & Security - Generate new pin button',
    id: 'accessAndSecurity.generateNewPin',
  },
  accessAndSecurityTitle: {
    defaultMessage: 'Access & Security',
    description: 'Access & Security - Title',
    id: 'accessAndSecurity.title',
  },
  basicInfoTitle: {
    defaultMessage: 'My2N details',
    description: 'Basic info - Title',
    id: 'basicInfo.title',
  },
  buttonsCancel: {
    defaultMessage: 'Cancel',
    description: 'Buttons - Cancel',
    id: 'buttons.cancel',
  },

  enableMy2NAppAccessAddButtonLabel: {
    defaultMessage: 'Enable my2n app access',
    description: 'Access & Security - Enable My2N app access button label',
    id: 'enableMy2NAppAccessAddButtonLabel',
  },
  personalInfoAllowCallingLabel: {
    defaultMessage: 'Allow to call this number via the intercom display when calling this user',
    description: 'Personal info - allow calling label',
    id: 'personalInfo.allowCalling.label',
  },
  personalInfoAllowCallingTooltip: {
    defaultMessage: 'For proper funcionality SIP profiles need to be set in Remote configuration for each device.',
    description: 'Personal info - allow calling tooltip',
    id: 'personalInfo.allowCalling.tooltip',
  },
  personalInfoAllowCallingWarning: {
    defaultMessage: `To be able to call this user, make sure to enter their apartment. Without it, the number can't be added to the intercom button.`,
    description: 'Personal info - allow calling warning',
    id: 'personalInfo.allowCalling.warning',
  },
  personalInfoEmailLabel: {
    defaultMessage: 'User email',
    description: 'Personal info - user email - label',
    id: 'personalInfo.email.label',
  },
  personalInfoFirstNameLabel: {
    defaultMessage: 'First name',
    description: 'Personal info - first name label',
    id: 'personalInfo.firstName.label',
  },
  personalInfoLastNameLabel: {
    defaultMessage: 'Surname',
    description: 'Personal info - last name label',
    id: 'personalInfo.lastName.label',
  },
  personalInfoMobilePhoneLabel: {
    defaultMessage: 'Mobile phone',
    description: 'Personal info - Mobile phone label',
    id: 'personalInfo.mobilePhone.label',
  },
  personalInfoMobilePhoneWarning: {
    defaultMessage: 'Changing the number changes the GSM call service settings',
    description: 'Personal info - Mobile phone warning',
    id: 'personalInfo.mobilePhone.warning',
  },
  personalInfoTitle: {
    defaultMessage: 'Personal info',
    description: 'Personal info - Title',
    id: 'personalInfo.title',
  },
  UserInfoFormLangSelectLabel: {
    defaultMessage: 'User language',
    description: 'User language select - label',
    id: 'userInfoForm.langSelect.label',
  },
  validationApartment: {
    defaultMessage: 'Value is mandatory.',
    description: 'Apartment data form - number is exist',
    id: 'useApartmentFormValidationSchema.validationApartment',
  },
  validationExistNumber: {
    defaultMessage: 'An apartment with this number already exists.',
    description: 'Apartment data form - number is exist',
    id: 'useApartmentFormValidationSchema.validationExistNumber',
  },
  validationMaxFloorLength: {
    defaultMessage: 'Floor name is too long.',
    description: 'User info form - max floor name length',
    id: 'userInfoForm.validationMaxFloorLength',
  },
  validationMaxLength: {
    defaultMessage: 'Name is too long.',
    description: 'Personal info - maxLength value',
    id: 'personalInfo.maxLength',
  },
  validationMaxNameLength: {
    defaultMessage: 'Name is too long.',
    description: 'User info form - max name length',
    id: 'userInfoForm.validationMaxNameLength',
  },
  validationMaxNumberLength: {
    defaultMessage: 'Number is too long.',
    description: 'User info form - max number length',
    id: 'userInfoForm.validationMaxNumberLength',
  },
  validationRequired: {
    defaultMessage: 'Value is mandatory',
    description: 'Personal info - required value',
    id: 'personalInfo.required',
  },
  validationRequiredFloor: {
    defaultMessage: 'Enter the floor number',
    description: 'Apartment data form - floor is required',
    id: 'useApartmentFormValidationSchema.validationRequiredFloor',
  },
  validationRequiredName: {
    defaultMessage: 'Enter the apartment name',
    description: 'Apartment data form - name is required',
    id: 'useApartmentFormValidationSchema.validationRequiredName',
  },
  validationRequiredNumber: {
    defaultMessage: 'Enter the apartment number',
    description: 'Apartment data form - number is required',
    id: 'useApartmentFormValidationSchema.validationRequiredNumber',
  },
});
