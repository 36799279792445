import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AppVersionWarning from '../../components/AppVersionWarning';
import { PATHS } from '../../config/consts';
import { getAccessTokenSelector, getResendEmail } from '../../modules/auth/selectors';
import { isSiteVersionWarningDisplayed } from '../../modules/common/selectors';
import { getCurrentUserSelector } from '../../modules/user/selectors';
import { useOryUserLogged } from '../../ory/hooks/oryUser/useOryUserLogged';
import { PublicFormWrapper } from '../_components/PublicFormWrapper';
import { LoginFormWrapper } from './components/LoginForm/LoginFormWrapper';
import { MFAForm } from './components/MFAForm/MFAForm';
import { MFARecoveryForm } from './components/MFARecoveryForm/MFARecoveryForm';
import { ICurrentUserLogin, LoginPageStep, useLoginPage } from './hook/useLoginPage';
import messages from './messages';
import { styles } from './style';
import { useSetAccessToken } from './useSetAccessToken';

export function LoginPage() {
  const navigate = useNavigate();
  const [loginPageStep, setLoginPageStep] = useState<LoginPageStep>(LoginPageStep.Login);
  const accessToken = useSelector(getAccessTokenSelector);
  const isAlertShown = useSelector(isSiteVersionWarningDisplayed);

  useSetAccessToken();

  const currentUser = useSelector(getCurrentUserSelector) as ICurrentUserLogin;
  const resendEmail = useSelector(getResendEmail);
  const { isOryUserLoggedIn, oryLoginStatus } = useOryUserLogged({ accessToken, currentUser });
  const { getTitle, redirectToAppWhenUserIsLoggedIn } = useLoginPage({ currentUser, isOryUserLoggedIn });

  useEffect(() => {
    redirectToAppWhenUserIsLoggedIn();
  }, [currentUser.id, oryLoginStatus]);

  useEffect(() => {
    if ((resendEmail?.length ?? 0) > 0) {
      navigate(PATHS.RESEND);
    }
  }, [resendEmail]);

  const registerLink = (
    <Box sx={styles.sub}>
      <Typography variant="body1">
        <FormattedMessage {...messages.authLoginUnregisteredText} />
        <Link to={PATHS.REGISTER}>
          <FormattedMessage {...messages.authLoginUnregisteredAction} />
        </Link>
      </Typography>
    </Box>
  );

  return (
    <>
      <AppVersionWarning />
      <Box sx={{ ...(isAlertShown && styles.alert) }}>
        <PublicFormWrapper title={getTitle(loginPageStep)} subContent={registerLink}>
          {loginPageStep === LoginPageStep.Login && (
            <LoginFormWrapper
              onOrySecondFactor={() => {
                setLoginPageStep(LoginPageStep.MFA);
              }}
            />
          )}
          {loginPageStep === LoginPageStep.MFA && (
            <MFAForm
              onMFARecovery={() => {
                setLoginPageStep(LoginPageStep.MFARecovery);
              }}
            />
          )}
          {loginPageStep === LoginPageStep.MFARecovery && (
            <MFARecoveryForm
              onBack={() => {
                setLoginPageStep(LoginPageStep.MFA);
              }}
            />
          )}
        </PublicFormWrapper>
      </Box>
    </>
  );
}
