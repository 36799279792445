import * as Yup from 'yup';
import http from '../../../api';
import { FE_ROLES, ROLES } from '../../../config/consts';
import { CONTEXT } from '../../../modules/user';
import { getApartmentValidationSchema } from '../../../validations/apartmentFormValidationSchema';
import {
  getBasicEmailValidation,
  getFirstNameValidation,
  getLastNameValidation,
  getPhoneNumberValidation,
} from '../../../validations/user/userCommonValidations';
import messages from '../messages';

export function getValidationSchema(
  isEdit,
  context,
  isCurrentUser,
  data,
  validation,
  companyId,
  siteId,
  formatMessage,
  userRole
) {
  const firstNameValidation = {
    firstName: getFirstNameValidation(true, formatMessage),
  };

  const optionalFirstNameValidation = {
    firstName: getFirstNameValidation(false, formatMessage),
  };

  const lastNameValidation = {
    lastName: getLastNameValidation(formatMessage),
  };

  if (isEdit) {
    return Yup.object().shape({
      ...firstNameValidation,
      ...lastNameValidation,
      ...getApartmentValidationSchema(companyId, siteId, formatMessage),
    });
  }
  return userRole === FE_ROLES.LOGIN_LESS
    ? Yup.object().shape({
        ...optionalFirstNameValidation,
        ...lastNameValidation,
        ...getApartmentValidationSchema(companyId, siteId, formatMessage),
      })
    : Yup.object().shape({
        ...firstNameValidation,
        ...lastNameValidation,
        ...getEmailValidation(context, isCurrentUser, data, validation, formatMessage),
        ...getApartmentValidationSchema(companyId, siteId, formatMessage),
        ...getGsmValidationSchema(formatMessage),
      });
}

function getEmailValidation(context, isCurrentUser, data, validation, formatMessage) {
  return {
    email: getBasicEmailValidation(
      formatMessage(messages.userModalEmailValid),
      formatMessage(messages.userModalRequired)
    ).test({
      message:
        context === CONTEXT.SITE_USERS
          ? formatMessage(messages.userModalEmailExistUser)
          : formatMessage(messages.userModalEmailExistAdmin),
      name: 'isExistEmail',
      // eslint-disable-next-line max-statements
      test: async (email) => {
        try {
          if (!email || email.length === 0) {
            return true;
          }
          if (isCurrentUser && data.email === email) {
            return true;
          }

          let url;
          let roles;
          if (context === CONTEXT.SITE_USERS) {
            url = `/companies/${validation.companyId}/sites/${validation.siteId}/users`;
            roles = ROLES.USER;
          } else if (context === CONTEXT.COMPANY_ADMINS) {
            url = `/companies/${validation.companyId}/users`;
            roles = ROLES.COMPANY_ADMIN;
          }
          const response = await http.head(url, {
            params: { email, roles },
          });
          return response.status === 404;
        } catch (error) {
          return true;
        }
      },
    }),
  };
}

function getGsmValidationSchema(formatMessage) {
  return {
    gsmCalls: Yup.boolean(),
    phoneNumber: getPhoneNumberValidation(formatMessage),
  };
}
