import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { SERVICE } from '../../config/devices';
import { isActivable, isExpirable } from '../../helpers/mobileVideo';
import { useModal } from '../../hooks/useModal';
import { ISite } from '../../modules/sites/store';
import { ChangeSiteTypeModal as ChangeSiteTypeDialog } from '../SiteModal/ChangeSiteTypeModal';
import sitesActions from './../../modules/sites';
import RowMenu from './../DataGrid/RowMenu';
import RowMenuItem from './../DataGrid/RowMenuItem';
import messages from './messages';

export function SitesDataGridRowMenu({ site }: { site: ISite }) {
  const dispatch = useDispatch();
  const isNotActivableOrExpirable =
    !isActivable(site?.services?.[SERVICE.MOBILE_VIDEO]?.licenceModel?.status) &&
    !isExpirable(site?.services?.[SERVICE.MOBILE_VIDEO]?.licenceModel?.status);

  const { Modal: ChangeSiteTypeModal, onOpen: openChangeSiteTypeModal } = useModal({
    Modal: ChangeSiteTypeDialog,
  });

  return (
    <>
      <RowMenu
        menuItems={
          <Box>
            <RowMenuItem
              icon={<EditIcon />}
              label={<FormattedMessage {...messages.sitesDataGridActionsRename} />}
              onClick={() => dispatch(sitesActions.editSiteShowModal(site))}
            />
            {isNotActivableOrExpirable && (
              <RowMenuItem
                icon={<LocationOnIcon />}
                label={<FormattedMessage {...messages.sitesDataGridActionsChangeSiteType} />}
                onClick={openChangeSiteTypeModal}
              />
            )}
            <Divider />
            <RowMenuItem
              colorIcon="error.dark"
              colorText="error.dark"
              icon={<DeleteIcon />}
              key="deleteSite"
              label={<FormattedMessage {...messages.sitesDataGridActionsDelete} />}
              onClick={() => dispatch(sitesActions.deleteSiteShowModal(site))}
            />
          </Box>
        }
      />
      <ChangeSiteTypeModal siteId={site.id} />
    </>
  );
}
