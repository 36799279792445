import LocationOnIcon from '@mui/icons-material/LocationOn';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../config/consts';
import { useDataGrid } from '../../helpers/dataGrid';
import useBreakpoints from '../../helpers/useBreakpoints';
import { isFetchingMessages } from '../../modules/localization/selectors';
import BillingType from '../BillingType';
import DataGrid from '../DataGrid';
import NameColumn from '../DataGrid/NameColumn';
import EllipsisText from '../EllipsisText';
import SiteStatus from '../SiteStatus';
import SiteType from '../SiteType';
import messages from './messages';
import { NoRowsOverlay } from './NoRowsOverlay';
import { NoRowsSearchOverlay } from './NoRowsSearchOverlay';
import { SitesDataGridRowMenu } from './SitesDataGridRowMenu';

const SitesDataGrid = ({
  companyId,
  context,
  data,
  didInvalid,
  filter,
  isFetching,
  onAddSite,
  onChangeFilter,
  onLoadSites,
  order,
  page,
  resultsFiltered,
  rowsPerPage,
}) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { downSm, upLg, upMd, upSm } = useBreakpoints();
  const isLocalizationLoaded = useSelector(isFetchingMessages);

  const { onOrderBy, onRowsPerPageChange, onSelectPage } = useDataGrid(
    onLoadSites,
    {
      companyId,
      context,
      filter,
      order,
      page,
      rowsPerPage,
    },
    didInvalid,
    isFetching
  );

  const columns = useMemo(
    () => [
      {
        disableColumnMenu: true,
        field: 'name',
        flex: 1,
        headerName: formatMessage(messages.sitesDataGridColumnsName),
        renderCell: (site) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <NameColumn iconComponent={<LocationOnIcon />} name={site.value?.name} />
          ),
        sortable: true,
        width: 200,
      },
      {
        disableColumnMenu: true,
        field: 'my2nId',
        flex: 1,
        headerName: formatMessage(messages.sitesDataGridColumnsMy2nId),
        renderCell: (site) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <EllipsisText>{site.value?.my2nId}</EllipsisText>
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'type',
        flex: 1,
        headerName: formatMessage(messages.sitesDataGridColumnsType),
        renderCell: (site) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <SiteType category={site.value?.siteCategory} type={site.value?.type} />
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'model',
        flex: 1,
        headerName: formatMessage(messages.sitesDataGridColumnsPaymentModel),
        renderCell: (site) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <BillingType billingType={site.value?.mobileVideo.licenceModel.billingType} />
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'status',
        flex: 1,
        headerName: formatMessage(
          downSm
            ? messages.sitesDataGridColumnsMobileVideoStatusMobile
            : messages.sitesDataGridColumnsMobileVideoStatusDesktop
        ),
        renderCell: (site) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <SiteStatus licenceModel={site.value?.mobileVideo.licenceModel} />
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'rowMenu',
        headerName: '',
        renderCell: (site) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <SitesDataGridRowMenu site={site.value} />
          ),
        sortable: false,
        width: 65,
      },
    ],
    [downSm, isFetching, upLg, upMd, upSm, isLocalizationLoaded]
  );

  const rows = useMemo(
    () =>
      data.map((site) => ({
        id: site.id,
        isClickable: true,
        model: site,
        my2nId: site,
        name: site,
        rowMenu: site,
        status: site,
        type: site,
      })),
    [data]
  );

  const onClearSearch = () => {
    onChangeFilter({
      target: {
        name: 'fulltext',
        value: '',
      },
    });
  };

  return (
    <DataGrid
      columns={columns}
      columnVisibilityModel={{
        model: upSm,
        my2nId: upMd,
        type: upLg,
      }}
      disableRowSelectionOnClick
      hideFooterPagination={isFetching}
      loading={isFetching}
      noRowsOverlay={
        filter.fulltext !== '' ? (
          <NoRowsSearchOverlay onClearSearch={onClearSearch} searchText={filter.fulltext} />
        ) : (
          <NoRowsOverlay onCreateSite={onAddSite} />
        )
      }
      onCellClick={(site) => {
        if (site.field !== 'rowMenu') {
          navigate(PATHS.SITE_DASHBOARD.replace(':companyId', companyId).replace(':siteId', site.value?.id));
        }
      }}
      onOrderBy={onOrderBy}
      onRowsPerPageChange={onRowsPerPageChange}
      onSelectPage={onSelectPage}
      order={order}
      page={page}
      resultsFiltered={resultsFiltered}
      resultsTotal={resultsFiltered}
      rows={rows}
      rowsPerPage={rowsPerPage}
    />
  );
};

SitesDataGrid.propTypes = {
  companyId: PropTypes.number.isRequired,
  context: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  didInvalid: PropTypes.bool,
  filter: PropTypes.shape({ fulltext: PropTypes.string }).isRequired,
  isFetching: PropTypes.bool.isRequired,
  onAddSite: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onLoadSites: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  resultsFiltered: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

SitesDataGrid.defaultProps = {
  context: '',
  didInvalid: false,
};

export default SitesDataGrid;
