import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TotalPriceBar } from '../../Common/TotalPriceBar';
import messages from '../../messages';

export interface IPromoTotalPriceBarProps {
  currency: string;
  price: number;
  priceVat: number;
  subscriptionsCount: number;
}

export function PromoTotalPriceBar({ currency, price, priceVat, subscriptionsCount }: IPromoTotalPriceBarProps) {
  return (
    <TotalPriceBar
      currency={currency}
      price={price}
      priceVat={priceVat}
      totalPriceNote={
        <FormattedMessage
          {...messages.promoCodesTotalPriceNote}
          values={{
            subscriptionsCount,
          }}
        />
      }
    />
  );
}
