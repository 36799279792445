import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import OutlinedTextField from '../../components/HookFormFields/TextField';
import messages from './messages';

const SupportForm = () => {
  const { formState, reset, watch } = useFormContext();

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <OutlinedTextField
        multiline
        name="message"
        sx={{
          '& textarea': {
            minHeight: 200,
          },
        }}
      />
      <Box display="flex" gap={2}>
        <Button
          color="primary"
          startIcon={<SendIcon />}
          type="submit"
          variant="contained"
          disabled={!watch('message') || !formState.isValid}
        >
          <FormattedMessage {...messages.supportSend} />
        </Button>
        <Button color="secondary" onClick={() => reset()}>
          <FormattedMessage {...messages.supportClear} />
        </Button>
      </Box>
    </Box>
  );
};

export default SupportForm;
