import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  SiteCategory,
  SiteType,
  useLazyGetSiteTypeChangePrecheckQuery,
  useUpdateSiteMutation,
} from '../../../apiRtk/be4feApi';
import useBreakpoints from '../../../helpers/useBreakpoints';
import { useGetActualCompanyId } from '../../../hooks/companyHooks';
import { useGetGlobalInfo } from '../../../hooks/useGetGlobalInfo';
import { setInvalidateSiteSwitcher } from '../../../modules/common/actionsTS';
import { TId } from '../../../modules/commonTypes';
import { TYPE_DESCRIPTOR } from '../../../modules/notifications';
import { addSnackbarError, addSnackbarSuccess } from '../../../modules/notifications/actions';
import actions from '../../../modules/sites';
import messages from '../messages';
import { getChangeSiteTypeModalValidationSchema } from '../validationSchemes/changeSiteTypeModalValidationSchema';

export interface IManageSiteTypeModalValues {
  siteCategory: SiteCategory;
  siteType: SiteType;
}

export function useGetChangeSiteTypeModalHooks(siteId: TId, onClose: () => void) {
  const { downSm } = useBreakpoints();
  const companyId = useGetActualCompanyId();
  const { dispatch, formatMessage } = useGetGlobalInfo();
  const [updateSite] = useUpdateSiteMutation();
  const [getSiteTypeChangePrecheckQuery, { data, isFetching }] = useLazyGetSiteTypeChangePrecheckQuery();

  const formMethods = useForm<IManageSiteTypeModalValues>({
    mode: 'onChange',
    resolver: yupResolver<IManageSiteTypeModalValues>(getChangeSiteTypeModalValidationSchema()),
  });

  const buttonsText: { cancel: string; submit: string } = useMemo(() => {
    if (data && !data.canChangeSiteType) {
      return {
        cancel: formatMessage(messages.changeSiteTypeModalErrorCancel),
        submit: formatMessage(messages.changeSiteTypeModalErrorSubmit),
      };
    }

    return {
      cancel: formatMessage(messages.CreateSiteModalCancel),
      submit: downSm
        ? formatMessage(messages.changeSiteTypeModalSubmitMobile)
        : formatMessage(messages.changeSiteTypeModalSubmit),
    };
  }, [downSm, data]);

  async function handleSubmit(formData: IManageSiteTypeModalValues): Promise<void> {
    try {
      const precheckResponse = await getSiteTypeChangePrecheckQuery({
        companyId,
        siteId,
        siteType: formData.siteType,
      }).unwrap();

      if (precheckResponse.canChangeSiteType) {
        onClose();

        await updateSite({
          companyId,
          siteId,
          updateSiteRequest: { type: formData.siteType },
        });

        dispatch(addSnackbarSuccess({ ...messages.changeSiteTypeModalSuccess }));
        dispatch(actions.finishSetSiteType());
        dispatch(setInvalidateSiteSwitcher());
      }
    } catch (error) {
      dispatch(addSnackbarError({ ...messages.CreateSiteModalError }, TYPE_DESCRIPTOR));
    }
  }

  return {
    buttonsText,
    formatMessage,
    formMethods,
    handleSubmit,
    isFetching,
    manageSiteTypeValidationResult: data,
  };
}
