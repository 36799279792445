import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { Paper, Title } from '../../components';
import { FAQ } from '../../config/address';
import { useUrlParams } from '../../helpers/urlParams';
import { useGetGlobalInfo } from '../../hooks/useGetGlobalInfo';
import actions from '../../modules/common';
import messages from './messages';
import SupportForm from './SupportForm';

const Support = () => {
  const { dispatch, formatMessage } = useGetGlobalInfo();
  const { companyId, siteId } = useUrlParams();

  const formMethods = useForm({
    defaultValues: { message: '' },
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        message: yup.string().required(formatMessage(messages.supportTextValidate)),
      })
    ),
  });

  const onSubmit = () => {
    const values = formMethods.getValues();

    dispatch(
      actions.sendSupport({
        ...values,
        companyId,
        siteId,
      })
    );

    formMethods.reset();
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Title heading={<FormattedMessage {...messages.supportTitle} />} />
        <Paper>
          <Box maxWidth={600}>
            <Typography>
              <FormattedMessage {...messages.supportInfo} />
            </Typography>
            <Typography>
              <FormattedMessage
                {...messages.supportFaq}
                values={{
                  link: (
                    <Link href={FAQ} rel="noopener noreferrer" target="_blank">
                      <FormattedMessage {...messages.supportFaqCheck} />
                    </Link>
                  ),
                }}
              />
            </Typography>
            <Box pb={2} pt={4}>
              <Box pb={1}>
                <Typography variant="h5">
                  <FormattedMessage {...messages.supportReportTitle} />
                </Typography>
              </Box>
              <Typography color="textSecondary" variant="body2" mb={2}>
                <FormattedMessage {...messages.supportPlaceholder} />
              </Typography>
              <SupportForm />
            </Box>
          </Box>
        </Paper>
      </form>
    </FormProvider>
  );
};

export default Support;
