import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Stepper, Title } from '../../../components';
import { TYPE } from '../../../config/subscription';
import billingActions from '../../../modules/billing';
import { selectors as companySelectors } from '../../../modules/company';
import actions, { selectors } from '../../../modules/subscription';
import ThreeStep from '../Common/ThreeStep';
import { getSubscriptionStepsIntl } from '../helpers';
import messages from '../messages';
import styles from '../styles';
import { purchasePaymentResultPropTypes } from '../unitProps';
import { OneStep } from './OneStep/OneStep';
import TwoStep from './TwoStep/TwoStep';

const DeviceLicense = ({
  company,
  deviceLicenseOrders,
  deviceLicensePurchaseOrder,
  onAddSerialNumber,
  onCreateLicenseInvoicePayment,
  onDeleteOrderLicense,
  onEditBilling,
  onSetLicenses,
  onSetPayments,
  purchasePaymentResult,
  snLicenseData,
  snLicenseIsLoading,
}) => {
  const [step, setStep] = useState(1);
  const { formatMessage } = useIntl();
  return (
    <>
      <Title heading={<FormattedMessage {...messages.subscriptionIntercomLicensesTitle} />} />
      <Stepper activeStep={step} steps={getSubscriptionStepsIntl(formatMessage)} />
      <Paper sx={styles.root}>
        <>
          {(step === 1 || deviceLicenseOrders.length < 1) && (
            <OneStep
              onAddSerialNumber={onAddSerialNumber}
              onSetLicenses={onSetLicenses}
              onStepChange={setStep}
              snLicenseData={snLicenseData}
              snLicenseIsLoading={snLicenseIsLoading}
            />
          )}
          {step === 2 && deviceLicenseOrders.length > 0 && (
            <TwoStep
              company={company}
              deviceLicenseOrders={deviceLicenseOrders}
              deviceLicensePurchaseOrder={deviceLicensePurchaseOrder}
              onCreateLicenseInvoicePayment={onCreateLicenseInvoicePayment}
              onDeleteOrderLicense={onDeleteOrderLicense}
              onEditBilling={onEditBilling}
              onSetPayments={onSetPayments}
              onStepChange={setStep}
              purchasePaymentResult={purchasePaymentResult}
              snLicenseData={snLicenseData}
              snLicenseIsLoading={snLicenseIsLoading}
            />
          )}
          {step === 3 && (
            <ThreeStep
              paymentMethod={deviceLicensePurchaseOrder?.paymentMethod}
              purchasePaymentResult={purchasePaymentResult}
              subscription={TYPE.INTERCOM_LICENSES}
            />
          )}
        </>
      </Paper>
    </>
  );
};

DeviceLicense.propTypes = {
  company: PropTypes.shape({}).isRequired,
  deviceLicenseOrders: PropTypes.arrayOf(PropTypes.object).isRequired,
  deviceLicensePurchaseOrder: PropTypes.shape({
    chargeVat: PropTypes.bool,
    currency: PropTypes.string,
    customerOrderNumber: PropTypes.string,
    paymentMethod: PropTypes.string,
    price: PropTypes.number,
    priceVat: PropTypes.number,
  }).isRequired,
  onAddSerialNumber: PropTypes.func.isRequired,
  onCreateLicenseInvoicePayment: PropTypes.func.isRequired,
  onDeleteOrderLicense: PropTypes.func.isRequired,
  onEditBilling: PropTypes.func.isRequired,
  onSetLicenses: PropTypes.func.isRequired,
  onSetPayments: PropTypes.func.isRequired,
  purchasePaymentResult: purchasePaymentResultPropTypes,
  snLicenseData: PropTypes.shape({
    canBeInvoiced: PropTypes.bool,
    creditLimit: PropTypes.number,
  }).isRequired,
  snLicenseIsLoading: PropTypes.bool.isRequired,
};

DeviceLicense.defaultProps = {
  purchasePaymentResult: null,
};

const mapStateToProps = (state) => ({
  company: companySelectors.getCompanyData(state),
  deviceLicenseOrders: selectors.getLicenseOrders(state),
  deviceLicensePurchaseOrder: selectors.getDeviceLicensePurchaseOrder(state),
  purchasePaymentResult: selectors.getPurchasePaymentResult(state),
  snLicenseData: selectors.getSerialNumberLicenseData(state),
  snLicenseIsLoading: selectors.getSerialNumberLicenseIsLoading(state),
});

const mapDispatchToProps = {
  onAddSerialNumber: actions.getSerialNumberLicenses,
  onCreateLicenseInvoicePayment: actions.createLicenseInvoicePayment,
  onDeleteOrderLicense: actions.deleteOrederLicense,
  onEditBilling: billingActions.editBillingShowModal,
  onSetLicenses: actions.setSerialNumberLicenses,
  onSetPayments: actions.setPayments,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceLicense);
