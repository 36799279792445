import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DEVICE_TYPE } from '../../../config/devices';
import { TDevicesControlValues } from '../types';
import messages from './messages';

interface IDeviceTitle {
  device: TDevicesControlValues | 'GENERAL';
}

const DeviceTitle = ({ device }: IDeviceTitle) => {
  const type = deviceTypeAddTitleMap[device] ? device : 'GENERAL';
  return <FormattedMessage values={{ sup: (chunks) => <sup>{chunks}</sup> }} {...deviceTypeAddTitleMap[type]} />;
};

export default DeviceTitle;

const deviceTypeAddTitleMap = {
  [DEVICE_TYPE.DOOR_STATION]: messages.doorStationAddTitle,
  [DEVICE_TYPE.HIPMO]: messages.hipmoAddTitle,
  [DEVICE_TYPE.THIRD_PARTY]: messages.thirdPartyAddTitle,
  [DEVICE_TYPE.AXIS_ANSWERING_UNIT]: messages.addAxisDeviceAnsweringUnitTitle,
  AXIS_DEVICE: messages.addAxisDeviceTitle,
  GENERAL: messages.generalAddTitle,
  X2N: messages.x2NAddTitle,
};
