import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import actions from '../../modules/accessSettings';
import ApartmentAutocomplete from '../ApartmentAutocomplete';
import PrimaryModal from '../PrimaryModal';
import { useGetApartmentFormData } from './hooks/getApartmentFormData';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import messages from './messages';

function AccessGroupAddApartmentModal({ groupId, onClose, open }) {
  const hasFormItemKey = 'hasForm';
  const newItemId = 'newItemId';
  const { isLoading, optionsData } = useGetApartmentFormData();
  const { dispatch, formatMessage, formMethods } = useGetGlobalHooks(hasFormItemKey);
  const { isDirty, isValid } = formMethods.formState;

  useEffect(() => {
    if (open) {
      dispatch(actions.getAccessControlApartments(groupId));
    }
  }, [open]);

  function handleSubmit(data) {
    const { floor, name, number } = data;
    dispatch(
      actions.addAccessGroupApartments(
        groupId,
        data.apartment.filter((item) => item.id !== newItemId).map((item) => item.id),
        data.hasForm ? { floor, name, number } : null
      )
    );
    onClose();
  }

  const getIsOpen = open || isLoading;
  return (
    getIsOpen && (
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <PrimaryModal
            actionButtonLabel={formatMessage(messages.accessGroupAddApartmentModalAdd)}
            isActionButtonDisabled={!isDirty || !isValid}
            isLoading={isLoading}
            name="access-group-addApartment-modal"
            onClose={onClose}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onCreate={() => {}}
            open={getIsOpen}
            title={formatMessage(messages.accessGroupAddApartmentModalTitle)}
          >
            <ModalText />
            <ApartmentAutocomplete hasFormItemKey={hasFormItemKey} newItemId={newItemId} optionsData={optionsData} />
          </PrimaryModal>
        </form>
      </FormProvider>
    )
  );
}

function ModalText() {
  return (
    <Typography component="span" variant="body1">
      <FormattedMessage {...messages.accessGroupAddApartmentModalText} values={{ p: (chunks) => <p>{chunks}</p> }} />
    </Typography>
  );
}

AccessGroupAddApartmentModal.propTypes = {
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

AccessGroupAddApartmentModal.defaultProps = {
  groupId: '',
};

export default AccessGroupAddApartmentModal;
