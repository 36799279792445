export const minYears = 1;
export const maxYears = 10;
export const minSubscriptions = 1;
export const maxSubscriptions = 3000;

export enum SUBSCRIPTION_TYPE {
  APARTMENT = 'APARTMENT',
  DEFAULT = 'DEFAULT',
  DEVICE = 'DEVICE',
}

export enum PROMO_TYPES {
  MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT = 'MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT',
  MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD = 'MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD',
}

export interface IBatch {
  id: number;
  name?: string;
  subscriptions?: number;
  subscriptionType?: SUBSCRIPTION_TYPE;
  years?: number;
}

export interface IOrderItemConfiguration {
  devices?: number;
  type?: keyof typeof PROMO_TYPES;
  years?: number;
}

export interface IOrderItem {
  configuration?: IOrderItemConfiguration;
  id: number;
  name?: string;
  price?: number;
}

export interface IPromoContent {
  configuration: IOrderItemConfiguration;
  name: string;
  size: number;
  type: string;
}

export interface IPromo {
  content: IPromoContent[];
  paymentMethod: string;
}

export interface IPrices {
  MOBILE_VIDEO_POOL_CREDIT: {
    unitFullPrice: number;
    unitFullPriceVat: number;
  };
  MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT: {
    unitFullPrice: number;
    unitFullPriceVat: number;
  };
  MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD: {
    unitFullPrice: number;
    unitFullPriceVat: number;
  };
}

export interface INameProps {
  name: string;
}
