import Typography from '@mui/material/Typography';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { OutlinedTextField } from '../../../../../../components/HookFormFields/TextField';
import { useSiteContext } from '../../../../../../context/useSiteContext';
import { maxSubscriptions, minSubscriptions, SUBSCRIPTION_TYPE } from '../../../hooks/types';
import messages from '../messages';

export interface INumberOfSubscription {
  name: string;
  batchIndex: number;
}

export function NumberOfSubscriptions({ batchIndex, name }: INumberOfSubscription) {
  const isPerApartmentBillingType = useSiteContext().hasSiteBillingType('PER_APARTMENT');
  const { watch } = useFormContext();
  const subscriptionType = watch('array.' + batchIndex + '.subscriptionType');
  const getSubscriptionAdornment = () => {
    if (subscriptionType === SUBSCRIPTION_TYPE.DEVICE) {
      return <FormattedMessage {...messages.promoBatchFormInputSubscriptionsAdornmentDevices} />;
    }
    if (subscriptionType === SUBSCRIPTION_TYPE.APARTMENT) {
      return <FormattedMessage {...messages.promoBatchFormInputSubscriptionsAdornmentApartments} />;
    }
    if (isPerApartmentBillingType) {
      return <FormattedMessage {...messages.promoBatchFormInputSubscriptionsAdornmentApartments} />;
    }
    return <FormattedMessage {...messages.promoBatchFormInputSubscriptionsAdornmentDevices} />;
  };

  return (
    <OutlinedTextField
      fullWidth
      id="PromoBatchForm-subscriptions"
      InputLabelProps={{
        required: false,
        shrink: true,
        sx: {
          width: 'max-content',
        },
      }}
      inputProps={{
        max: maxSubscriptions,
        min: minSubscriptions,
      }}
      InputProps={{
        endAdornment: <Typography color="text.secondary">{getSubscriptionAdornment()}</Typography>,
      }}
      label={<FormattedMessage {...messages.promoBatchFormInputSubscriptionsLabel} />}
      margin="dense"
      name={`${name}.subscriptions`}
      required
      size="small"
      type="number"
    />
  );
}
