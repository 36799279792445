import { Stack } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { UseBreakpoints } from '@my2n/use-breakpoints';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SITE_TYPE } from '../../../../../config/sites';
import RadioGroup from '../../../../HookFormFields/RadioGroup';
import messages from '../../../messages';

export interface ISiteTypesListProps {
  isResidential: boolean;
}

export function SiteTypesList({ isResidential }: ISiteTypesListProps) {
  const { upMd } = UseBreakpoints();

  return (
    <Stack flexWrap="wrap" sx={{ width: '100%' }}>
      {isResidential ? (
        <RadioGroup name="siteType" row={upMd ?? false}>
          <FormControlLabel
            value={SITE_TYPE.MDU}
            control={<Radio />}
            label={<FormattedMessage {...messages.CreateSiteModalSiteTypeResidentialSubtypeApartmentBuilding} />}
          />
          <FormControlLabel
            value={SITE_TYPE.FAMILY_HOME}
            control={<Radio />}
            label={<FormattedMessage {...messages.CreateSiteModalSiteTypeResidentialSubtypeFamilyHome} />}
          />
        </RadioGroup>
      ) : (
        <RadioGroup name="siteType" row={upMd ?? false}>
          <FormControlLabel
            value={SITE_TYPE.OFFICE}
            control={<Radio />}
            label={<FormattedMessage {...messages.CreateSiteModalSiteTypeCommercialSubtypeOffice} />}
          />
          <FormControlLabel
            value={SITE_TYPE.MIXED_USE}
            control={<Radio />}
            label={<FormattedMessage {...messages.CreateSiteModalSiteTypeCommercialSubtypeMixedUse} />}
          />
          <FormControlLabel
            value={SITE_TYPE.OTHER}
            control={<Radio />}
            label={<FormattedMessage {...messages.CreateSiteModalSiteTypeCommercialSubtypeOther} />}
          />
        </RadioGroup>
      )}
    </Stack>
  );
}
