import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { getPrice } from '../../../hooks/helpers';
import { SUBSCRIPTION_TYPE } from '../../../hooks/types';
import { InputLabel } from '../InputLabel';
import messages from '../messages';

export interface ITotalPrice {
  currency: string;
  unitFullPrice: number;
  batchIndex: number;
}

export function TotalPrice({ batchIndex, currency, unitFullPrice }: ITotalPrice) {
  const { formatNumber } = useIntl();
  const { watch } = useFormContext();
  const item = watch().array[batchIndex];

  return (
    <Grid alignContent={{ lg: 'flex-end', xs: 'stretch' }} alignItems="flex-end" container direction="column">
      <Grid item sx={{ transformOrigin: 'top right' }}>
        <InputLabel>
          <FormattedMessage {...messages.promoBatchFormInputPriceLabel} />
        </InputLabel>
      </Grid>
      <Grid item>
        <Typography>
          <strong>
            {item.subscriptionType === SUBSCRIPTION_TYPE.DEFAULT
              ? '—'
              : formatNumber(getPrice(unitFullPrice, item.years, item.subscriptions), {
                  currency,
                  style: 'currency',
                })}
          </strong>
        </Typography>
      </Grid>
    </Grid>
  );
}
