import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import actions from '../../../../modules/subscription';
import ErrorAlertCreditLimit from '../../Common/ErrorAlertCreditLimit';
import OrderPayment from '../../Common/OrderPayment';
import { OrderSubmit } from '../../Common/OrderSubmit';
import { INVOICE } from '../../constants';
import { isCreditLimitReached } from '../../helpers';
import { purchasePaymentResultPropTypes } from '../../unitProps';
import { PromoTotalPriceBar } from '../Common/PromoTotalPriceBar';
import { getVoucherValue } from '../hooks/helpers';
import { useGetPromoCodeData } from '../hooks/useGetPromoCodeData';
import { orderItemPropTypes } from '../types';
import BackButton from './BackButton';
import OrderList from './OrderList/OrderList';

const TwoStep = ({
  canBeInvoiced,
  company,
  creditLimit,
  currency,
  isLoading,
  onStepChange,
  orderContent,
  paymentLocation,
  promoCodesOrder,
  purchasePaymentResult,
}) => {
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
  const { dispatch } = useGetPromoCodeData();
  const onPaymentGateway = () => {
    onStepChange((prevStep) => prevStep + 1);
  };

  useEffect(() => {
    if (purchasePaymentResult !== null && promoCodesOrder.paymentMethod === INVOICE) {
      setIsSubmitButtonLoading(false);
      onPaymentGateway();
    }
  }, [purchasePaymentResult, promoCodesOrder.paymentMethod]);

  const handleOrderNumberChange = (orderNumber) =>
    dispatch(
      actions.setPromoCodesPayments({
        customerOrderNumber: orderNumber,
        paymentMethod: promoCodesOrder.paymentMethod,
      })
    );

  const onInvoicePayment = () => {
    dispatch(actions.createPromoInvoicePayment());
    setIsSubmitButtonLoading(true);
  };

  const getSubscriptionsCount = () => {
    let count = 0;

    orderContent.forEach((item) => {
      count += getVoucherValue(item.configuration.years, item.configuration.devices);
    });

    return count;
  };

  const limitReached = useMemo(
    () => isCreditLimitReached(promoCodesOrder.paymentMethod, promoCodesOrder.priceVat, creditLimit),
    [promoCodesOrder.paymentMethod, promoCodesOrder.priceVat, creditLimit]
  );

  return (
    <>
      {limitReached && <ErrorAlertCreditLimit />}
      <OrderPayment
        company={company}
        currentMethod={promoCodesOrder.paymentMethod}
        invoiceDisabled={!canBeInvoiced}
        onOrderNumberChange={handleOrderNumberChange}
      />
      <OrderList currency={currency} orderData={orderContent} />
      <PromoTotalPriceBar
        currency={currency}
        price={promoCodesOrder.price}
        priceVat={promoCodesOrder.priceVat}
        subscriptionsCount={getSubscriptionsCount()}
      />
      <OrderSubmit
        companyId={company.id}
        isCreditLimitReached={limitReached}
        isLoading={isLoading || isSubmitButtonLoading}
        onInvoicePayment={onInvoicePayment}
        onPaymentGateway={onPaymentGateway}
        paymentLocation={paymentLocation}
        paymentMethod={promoCodesOrder.paymentMethod}
        secondaryAction={<BackButton onClick={() => onStepChange(1)} />}
      />
    </>
  );
};

TwoStep.propTypes = {
  canBeInvoiced: PropTypes.bool.isRequired,
  company: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  creditLimit: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onStepChange: PropTypes.func.isRequired,
  orderContent: PropTypes.arrayOf(orderItemPropTypes).isRequired,
  paymentLocation: PropTypes.string.isRequired,
  promoCodesOrder: PropTypes.shape({
    paymentMethod: PropTypes.string,
    price: PropTypes.number,
    priceVat: PropTypes.number,
  }).isRequired,
  purchasePaymentResult: purchasePaymentResultPropTypes,
};

TwoStep.defaultProps = {
  purchasePaymentResult: null,
};

export default TwoStep;
