import { IntlFormatters, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectors as userSelectors } from '../../../modules/user';

export function useGetGlobalHooks(): {
  apartmentOptions?: {
    floor: string;
    id: number;
    label: string;
    name: string;
    number: string;
  }[];
  formatMessage: IntlFormatters['formatMessage'];
  isCompanyOrSiteAdmin: boolean;
} {
  const { formatMessage } = useIntl();
  const isCompanyOrSiteAdmin = useSelector(userSelectors.isCompanyOrSiteAdmin);

  return {
    formatMessage,
    isCompanyOrSiteAdmin,
  };
}
