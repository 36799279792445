import { MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Select } from '../../../../../../components/HookFormFields/Select';
import { SUBSCRIPTION_TYPE } from '../../../hooks/types';
import commonMessages from '../../../messages';
import messages from '../messages';

export interface ISubscriptionTypeSelect {
  name: string;
}

export function SubscriptionTypeSelect({ name }: ISubscriptionTypeSelect) {
  return (
    <Select
      fullWidth
      name={`${name}.subscriptionType`}
      label={
        <>
          <Box component="span" fontWeight="bold">
            <FormattedMessage {...messages.promoBatchFormSelectLabelSubscriptionType} />
          </Box>{' '}
          <Box component="span" fontWeight="normal">
            <FormattedMessage {...messages.promoBatchFormSelectLabelDeviceApartment} />
          </Box>
        </>
      }
      size="small"
      shrink
      sx={{ maxWidth: '600px', width: '100%' }}
    >
      {subscriptionTypeValues.map((value, index) => (
        <MenuItem key={index} value={value.value} disabled={value.disabled}>
          {value.label}
        </MenuItem>
      ))}
    </Select>
  );
}

export const subscriptionTypeValues = [
  {
    disabled: true,
    label: <FormattedMessage {...messages.promoBatchFormSelectPlaceholder} />,
    value: SUBSCRIPTION_TYPE.DEFAULT,
  },
  {
    label: <FormattedMessage {...commonMessages.promoCodeProductMobileVideoPoolCreditYearStandard} />,
    value: SUBSCRIPTION_TYPE.DEVICE,
  },
  {
    label: <FormattedMessage {...commonMessages.promoCodeProductMobileVideoPoolCreditYearApartment} />,
    value: SUBSCRIPTION_TYPE.APARTMENT,
  },
];
