import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomDate, FormAction } from '../../../../components';
import RadioGroup from '../../../../components/HookFormFields/RadioGroup';
import { useSiteContext } from '../../../../context/useSiteContext';
import { PaymentConfirm } from '../../Common/PaymentConfirm';
import { TotalPriceBar } from '../../Common/TotalPriceBar';
import { getOptions } from '../../helpers';
import messages from '../../messages';
import { INextPaymentProps, IPaymentProps, IUnitPurchaseProps, LICENSE } from '../../UnitPropsTS';
import { IMobileVideoLicenseFormValues } from '../hooks/useGetMVLicenceData';
import { PriceMessage } from './PriceMessage';

export interface IOneStepProps {
  calculation: IPaymentProps;
  currency: string;
  hasFreeLicencesWithIntercom: boolean;
  isValid?: boolean;
  nextPayment: INextPaymentProps;
  regularPayment: IPaymentProps;
  setIsOpenPricingModal: (value: boolean) => void;
}

export function OneStep({
  calculation,
  currency,
  hasFreeLicencesWithIntercom,
  nextPayment,
  regularPayment,
  setIsOpenPricingModal,
}: IOneStepProps) {
  const { watch } = useFormContext<IMobileVideoLicenseFormValues>();
  const product = watch('product');
  const isRecurringPayment = watch('isRecurringPayment');
  const intl = useIntl();
  const regularPaymentFrequency = regularPayment[product];
  const nextPaymentFrequency = nextPayment[product];
  const calculationFrequency = calculation[product];
  const typographyColor = { color: isRecurringPayment ? 'inherit' : 'secondary' };
  const isBillingTypeApartment = useSiteContext().hasSiteBillingType('PER_APARTMENT');

  const paymentValues = (payment: IUnitPurchaseProps) => ({
    values: {
      price: intl.formatNumber(payment?.price, { currency, style: 'currency' }),
      priceVat: intl.formatNumber(payment?.priceVat, { currency, style: 'currency' }),
    },
  });

  return (
    <Grid container>
      <Grid item sx={{ pb: 1 }}>
        <Typography fontWeight="bold">
          <FormattedMessage {...messages.mobileVideoLicenseTitle} />
          <Link onClick={() => setIsOpenPricingModal(true)} sx={{ '&:hover': { cursor: 'pointer' } }} underline="hover">
            <FormattedMessage {...messages.mobileVideoLicenseTitleLink} />
          </Link>
        </Typography>
      </Grid>
      <Grid container sx={{ pb: 2 }}>
        <Grid item xs={8}>
          <RadioGroup name="product" data-testid="subscription-fee-radio-list">
            {Object.values(getOptions(regularPayment)).map((value) => (
              <FormControlLabel
                control={<Radio />}
                key={value.value}
                label={value.label}
                value={value.value}
                sx={{ color: 'secondary.main', pl: 2.5 }}
              />
            ))}
          </RadioGroup>
        </Grid>
        <Grid container direction="column" item justifyContent="space-around" sx={{ pl: 1.5 }} xs={4}>
          {(regularPayment[LICENSE.MOBILE_VIDEO_MONTH] || regularPayment[LICENSE.APARTMENT_MOBILE_VIDEO_MONTH]) && (
            <Grid alignItems="center" container item>
              <PriceMessage
                currency={currency}
                payment={
                  regularPayment[LICENSE.MOBILE_VIDEO_MONTH] || regularPayment[LICENSE.APARTMENT_MOBILE_VIDEO_MONTH]
                }
              />
            </Grid>
          )}
          {(regularPayment[LICENSE.MOBILE_VIDEO_YEAR] || regularPayment[LICENSE.APARTMENT_MOBILE_VIDEO_YEAR]) && (
            <Grid alignItems="center" container item>
              <PriceMessage
                currency={currency}
                payment={
                  regularPayment[LICENSE.MOBILE_VIDEO_YEAR] || regularPayment[LICENSE.APARTMENT_MOBILE_VIDEO_YEAR]
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container direction="column" sx={{ pb: 2 }}>
        <Grid item sx={{ pb: 1 }}>
          <Typography fontWeight="bold">
            <FormattedMessage {...messages.mobileVideoLicenseNumberOfLicencesTitle} />
          </Typography>
        </Grid>
        <Grid item>
          <Typography gutterBottom sx={{ pl: 2.5 }}>
            {isBillingTypeApartment ? (
              <FormattedMessage
                {...(hasFreeLicencesWithIntercom
                  ? messages.mobileVideoLicenseNumberOfLicencesApartmentFreeApartment
                  : messages.mobileVideoLicenseNumberOfLicencesApartment)}
                values={{
                  licences: calculationFrequency?.licences,
                }}
              />
            ) : (
              <FormattedMessage
                {...messages.mobileVideoLicenseNumberOfLicencesDevices}
                values={{
                  licences: calculationFrequency?.licences,
                }}
              />
            )}
          </Typography>
        </Grid>
      </Grid>
      <TotalPriceBar
        currency={currency}
        price={calculationFrequency?.price}
        priceVat={calculationFrequency?.priceVat}
        totalPriceNote={
          [LICENSE.MOBILE_VIDEO_YEAR, LICENSE.APARTMENT_MOBILE_VIDEO_YEAR].includes(product) ? (
            <FormattedMessage
              {...messages.totalPriceBarTotalPriceTitleNote}
              values={{
                monthsNote: <FormattedMessage {...messages.totalPriceBarMonthsNote} />,
              }}
            />
          ) : null
        }
      />
      <Grid item sx={{ pb: 2 }}>
        <PaymentConfirm
          description={<FormattedMessage {...messages.mobileVideoLicenseRecurringPaymentDescription} />}
          message={<FormattedMessage {...messages.mobileVideoLicenseRecurringPaymentTitle} />}
          name="isRecurringPayment"
        />
      </Grid>
      <Grid container direction="column" item sx={{ ml: 2.5 }}>
        <Box pb={1}>
          <Typography {...typographyColor} variant="body2">
            <FormattedMessage
              {...messages.mobileVideoLicenseNextScheduledPayment}
              values={{
                date: <CustomDate dateValue={nextPaymentFrequency?.date} />,
              }}
            />
          </Typography>
          <Typography {...typographyColor} variant="body2">
            <FormattedMessage
              {...messages.mobileVideoLicenseNextPayment}
              values={{
                price: nextPaymentFrequency?.calculation.price.toFixed(2),
                priceVat: nextPaymentFrequency?.calculation.priceVat.toFixed(2),
              }}
            />
            {nextPaymentFrequency?.calculation.licencesPool > 0 && (
              <FormattedMessage
                {...messages.mobileVideoLicenseNextPaymentLicensePool}
                values={{
                  licencesPool: nextPaymentFrequency?.calculation.licencesPool.toFixed(2),
                }}
              />
            )}
          </Typography>
        </Box>
        <Typography {...typographyColor} variant="body2">
          {isBillingTypeApartment ? (
            <FormattedMessage
              {...messages.mobileVideoLicenseNumberOfLicencedDevicesApartment}
              values={{
                number: nextPaymentFrequency?.calculation.licences,
              }}
            />
          ) : (
            <FormattedMessage
              {...messages.mobileVideoLicenseNumberOfLicencedDevicesDevices}
              values={{
                number: nextPaymentFrequency?.calculation.licences,
              }}
            />
          )}
        </Typography>
        <Typography {...typographyColor} variant="body2">
          {[LICENSE.MOBILE_VIDEO_YEAR, LICENSE.APARTMENT_MOBILE_VIDEO_YEAR].includes(product) ? (
            <FormattedMessage
              {...messages.mobileVideoLicenseRegularAnnualPayment}
              {...paymentValues(regularPaymentFrequency)}
            />
          ) : (
            <FormattedMessage
              {...messages.mobileVideoLicenseRegularMonthlyPayment}
              {...paymentValues(regularPaymentFrequency)}
            />
          )}
        </Typography>
      </Grid>
      <Grid alignItems="center" container justifyContent="flex-end">
        <FormAction
          disabled={!isRecurringPayment}
          submitText={<FormattedMessage {...messages.subscriptionButtonNext} />}
          type="submit"
        />
      </Grid>
    </Grid>
  );
}
