export const moduleName = 'sites';

export default {
  CLEAR_SITE_DATA: `${moduleName}/CLEAR_SITE_DATA`,
  DEACTIVATE_MV_RECURRING_PAYMENT: `${moduleName}/DEACTIVATE_MV_RECURRING_PAYMENT`,
  DELETE_SITE_FAILURE: `${moduleName}/DELETE_SITE_FAILURE`,
  DELETE_SITE_HIDE_MODAL: `${moduleName}/DELETE_SITE_HIDE_MODAL`,
  DELETE_SITE_REQUEST: `${moduleName}/DELETE_SITE_REQUEST`,
  DELETE_SITE_SHOW_MODAL: `${moduleName}/DELETE_SITE_SHOW_MODAL`,
  DELETE_SITE_SUCCESS: `${moduleName}/DELETE_SITE_SUCCESS`,
  EDIT_SITE_FORM_FAILURE: `${moduleName}/EDIT_SITE_FORM_FAILURE`,
  EDIT_SITE_FORM_REQUEST: `${moduleName}/EDIT_SITE_FORM_REQUEST`,
  EDIT_SITE_FORM_SUCCESS: `${moduleName}/EDIT_SITE_FORM_SUCCESS`,
  EDIT_SITE_HIDE_MODAL: `${moduleName}/EDIT_SITE_HIDE_MODAL`,
  EDIT_SITE_SHOW_MODAL: `${moduleName}/EDIT_SITE_SHOW_MODAL`,
  GET_CURRENT_SITE_LICENSE_MODEL_FAILURE: `${moduleName}/GET_CURRENT_SITE_LICENSE_MODEL_FAILURE`,
  GET_CURRENT_SITE_LICENSE_MODEL_REQUEST: `${moduleName}/GET_CURRENT_SITE_LICENSE_MODEL_REQUEST`,
  GET_CURRENT_SITE_LICENSE_MODEL_SUCCESS: `${moduleName}/GET_CURRENT_SITE_LICENSE_MODEL_SUCCESS`,
  GET_SITE_DASHBOARD_FAILURE: `${moduleName}/GET_SITE_DASHBOARD_FAILURE`,
  GET_SITE_DASHBOARD_REQUEST: `${moduleName}/GET_SITE_DASHBOARD_REQUEST`,
  GET_SITE_DASHBOARD_SUCCESS: `${moduleName}/GET_SITE_DASHBOARD_SUCCESS`,
  GET_SITES_FAILURE: `${moduleName}/GET_SITES_FAILURE`,
  GET_SITES_RECENT_REQUEST: `${moduleName}/GET_SITES_RECENT_REQUEST`,
  GET_SITES_REQUEST: `${moduleName}/GET_SITES_REQUEST`,
  GET_SITES_SUCCESS: `${moduleName}/GET_SITES_SUCCESS`,
  GET_SITES_USERS_FAILURE: `${moduleName}/GET_SITES_USERS_FAILURE`,
  GET_SITES_USERS_REQUEST: `${moduleName}/GET_SITES_USERS_REQUEST`,
  GET_SITES_USERS_SUCCESS: `${moduleName}/GET_SITES_USERS_SUCCESS`,
  INVALIDATE_SITE: `${moduleName}/INVALIDATE_SITE`,
  INVALIDATE_SITE_DASHBOARD: `${moduleName}/INVALIDATE_SITE_DASHBOARD`,
  LOAD_SITE_FAILURE: `${moduleName}/LOAD_SITE_FAILURE`,
  LOAD_SITE_REQUEST: `${moduleName}/LOAD_SITE_REQUEST`,
  LOAD_SITE_SUCCESS: `${moduleName}/LOAD_SITE_SUCCESS`,
  SET_LAST_VISITED_SITE_FAILURE: `${moduleName}/SET_LAST_VISITED_SITE_FAILURE`,
  SET_LAST_VISITED_SITE_REQUEST: `${moduleName}/SET_LAST_VISITED_SITE_REQUEST`,
  SET_LAST_VISITED_SITE_SUCCESS: `${moduleName}/SET_LAST_VISITED_SITE_SUCCESS`,
  SET_SITE_LANGUAGE_SUCCESS: `${moduleName}/SET_SITE_LANGUAGE_SUCCESS`,
  SET_SITE_TYPE_SUCCESS: `${moduleName}/SET_SITE_TYPE_SUCCESS`,
  VERIFY_NAME_SITE_EXISTS: `${moduleName}/VERIFY_NAME_SITE_EXISTS`,
  VERIFY_NAME_SITE_FREE: `${moduleName}/VERIFY_NAME_SITE_FREE`,
  VERIFY_NAME_SITE_REQUEST: `${moduleName}/VERIFY_NAME_SITE_REQUEST`,
};
