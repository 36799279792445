/* eslint-disable sort/object-properties */
import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import { headSiteData } from '../../../../api/siteRequest';
import { useValidateNewHipmoDeviceInApartmentMutation } from '../../../../apiRtk/be4feApi';
import { TId } from '../../../../modules/commonTypes';
import messages from '../../../../validations/messages';

export interface INewApartmentForm {
  apartmentId: TId | 'createNewApartment';
  floor?: string;
  apartmentName: string;
  number: string;
  hasForm: boolean;
  hasSelectedApartment: boolean;
  isApartmentRequired: boolean;
}

export function getNewApartmentValidationSchema(
  formatMessage: IntlShape['formatMessage'],
  companyId: TId,
  siteId: TId,
  isHipmoOrThirdParty?: boolean
) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [validateNewHipmoDeviceInApartment] = useValidateNewHipmoDeviceInApartmentMutation();

  return {
    apartmentName: Yup.string().when('hasForm', {
      is: true,
      then: (schema) =>
        schema
          .max(255, formatMessage(messages.useApartmentFormValidationSchemaMaxLengthName))
          .matches(/^(?!\s+$)/, formatMessage(messages.useApartmentFormValidationSchemaInvalidValue)),
    }),
    apartmentId: Yup.number()
      .when('isApartmentRequired', {
        is: true,
        then: (schema) => schema.required(formatMessage(messages.AAmodalChangeLabelErrorMandatory)),
      })
      .test({
        name: 'isApartmentDeviceLimitReached',
        test: async (apartmentId: number) => {
          let state = true;
          if (isHipmoOrThirdParty && apartmentId > 0) {
            await validateNewHipmoDeviceInApartment({
              companyId: companyId,
              siteId: siteId,
              apartmentId: apartmentId,
            })
              .unwrap()
              .catch((response) => {
                state = response?.error?.status === 400;
              });
          }

          return state;
        },
      }),
    hasSelectedApartment: Yup.boolean().when('isApartmentRequired', {
      is: true,
      then: (schema: Yup.BooleanSchema) => schema.isTrue(),
    }),
    floor: Yup.string()
      .optional()
      .max(255, formatMessage(messages.useApartmentFormValidationSchemaMaxLengthFloor))
      .matches(/^(?!\s+$)/, formatMessage(messages.useApartmentFormValidationSchemaInvalidValue)),
    hasForm: Yup.boolean(),
    name: Yup.string().when('hasForm', {
      is: true,
      then: (schema) =>
        schema
          .max(255, formatMessage(messages.useApartmentFormValidationSchemaMaxLengthName))
          .matches(/^(?!\s+$)/, formatMessage(messages.useApartmentFormValidationSchemaInvalidValue)),
    }),
    number: Yup.string().when('hasForm', {
      is: true,
      then: (schema) =>
        schema
          .required(formatMessage(messages.useApartmentFormValidationSchemaRequiredNumber))
          .max(255, formatMessage(messages.useApartmentFormValidationSchemaMaxLengthNumber))
          .matches(/^\s*\S.*$/, formatMessage(messages.useApartmentFormValidationSchemaInvalidValue))
          .test({
            message: formatMessage(messages.useApartmentFormValidationSchemaExistNumber),
            name: 'isExistNumber',
            test: async (number) => {
              try {
                let state = true;
                if (!number || number.length === 0) {
                  return true;
                }
                await headSiteData({ companyId, siteId }, 'apartments', { number }, false).then((response) => {
                  state = response.status === 404;
                });

                return state;
              } catch (error) {
                return true;
              }
            },
          }),
    }),
  };
}
