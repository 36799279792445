import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DEVICE_TYPE } from '../../config/devices';
import { DeviceType } from '../../modules/commonTypes';
import messages from './messages';

export const getDeviceTypeMessage = (deviceType: string, type: DeviceType) => {
  const reducedType = deviceType?.split('.').slice(0, 2).join('.');
  const deviceWithDeviceTypeMessages = {
    '2.0': messages.deviceTypeIpIntercom,
    '2.3': messages.deviceTypeIpIntercom,
    '2.5': messages.deviceTypeIpIntercom,
    '2.7': messages.deviceTypeIpIntercom,
    '2.9': messages.deviceTypeIpIntercom,
    '2.10': messages.deviceTypeIpIntercom,
    '2.12': messages.deviceTypeIpIntercom,
    '2.14': messages.deviceTypeIpIntercom,
    '2.16': messages.deviceTypeAccessUnit,
    '2.18': messages.deviceTypeIpIntercom,
    '2.19': messages.deviceTypeIpIntercom,
    '2.21': messages.deviceTypeIpIntercom,
    '2.25': messages.deviceTypeIdTalk,
    '2.26': messages.deviceTypeIdCompact,
    '2.27': messages.deviceTypeAccessUnitM,
    '2.28': messages.deviceTypeAccessUnit,
    '2.30': messages.deviceTypeIdView,
    '2.31': messages.deviceTypeIpStyle,
    '2.32': messages.deviceTypeIpIntercomV2,
    '2.33': messages.deviceTypeIpOneIntercom,
    '2.34': messages.deviceTypeClip,
    '2.35': messages.deviceTypeChameleon,
    '2.38': messages.deviceTypeIdView,
    '2.39': messages.deviceTypeIpIntercom,
    '3.1': messages.deviceTypeIdt,
    '3.2': messages.deviceTypeIdt,
    '4.1': messages.deviceTypeDoorStation,
    '4.4': messages.DeviceTypeAXISI5304,
  };

  const deviceWithoutDeviceTypeMessages = {
    [DEVICE_TYPE.HIPMO]: messages.deviceTypeHipmo,
    [DEVICE_TYPE.THIRD_PARTY]: messages.deviceTypeThirdParty,
  };

  if (deviceType === null) {
    return deviceWithoutDeviceTypeMessages[type];
  }

  if (deviceType !== undefined) {
    return deviceWithDeviceTypeMessages[reducedType as keyof typeof deviceWithDeviceTypeMessages];
  }

  return messages.deviceTypeIpIntercom;
};

interface IDeviceTypeLabelParams {
  deviceType: string;
  type: DeviceType;
}

function DeviceTypeLabel({ deviceType, type }: IDeviceTypeLabelParams) {
  return (
    <FormattedMessage
      tagName="span"
      values={{
        sub: (chunks) => <sub>{chunks}</sub>,
        sup: (chunks) => <sup>{chunks}</sup>,
      }}
      {...getDeviceTypeMessage(deviceType, type)}
    />
  );
}

export default DeviceTypeLabel;
