import * as yup from 'yup';
import { headSiteData } from '../api/siteRequest';
import messages from './messages';
import { getPhoneNumberValidation } from './user/userCommonValidations';

export function getApartmentValidationSchema(companyId, siteId, formatMessage, initValues = {}) {
  return {
    floor: yup
      .string()
      .optional()
      .max(255, formatMessage(messages.useApartmentFormValidationSchemaMaxLengthFloor))
      .matches(/^(?!\s+$)/, formatMessage(messages.useApartmentFormValidationSchemaInvalidValue)),
    gsmCalls: yup.boolean(),
    hasForm: yup.boolean(),
    name: yup.string().when('hasForm', {
      is: true,
      then: (schema) =>
        schema
          .max(255, formatMessage(messages.useApartmentFormValidationSchemaMaxLengthName))
          .matches(/^(?!\s+$)/, formatMessage(messages.useApartmentFormValidationSchemaInvalidValue)),
    }),
    number: yup.string().when('hasForm', {
      is: true,
      then: (schema) =>
        schema
          .required(formatMessage(messages.useApartmentFormValidationSchemaRequiredNumber))
          .max(255, formatMessage(messages.useApartmentFormValidationSchemaMaxLengthNumber))
          .matches(/^\s*\S.*$/, formatMessage(messages.useApartmentFormValidationSchemaInvalidValue))
          .test({
            message: formatMessage(messages.useApartmentFormValidationSchemaExistNumber),
            name: 'isExistNumber',
            test: async (number) => {
              try {
                let state = true;
                if (!number || number.length === 0 || number === initValues?.number) {
                  return true;
                }
                await headSiteData({ companyId, siteId }, 'apartments', { number }, false).then((response) => {
                  state = response.status === 404;
                });

                return state;
              } catch (error) {
                return true;
              }
            },
          }),
    }),
    phoneNumber: getPhoneNumberValidation(formatMessage),
  };
}
