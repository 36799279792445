import { FormControl } from '@mui/material';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { DeviceModelName } from '../../../components';
import OutlinedTextField from '../../../components/HookFormFields/TextField';
import { DEVICE_TYPE } from '../../../config/devices';
import { useSiteContext } from '../../../context/useSiteContext';
import { getTypeFromDeviceType, isIntercomOrAccessUnit } from '../../../helpers/devices';
import { TId } from '../../../modules/commonTypes';
import messages from './messages';
import SerialNumberField from './SerialNumberField';

export interface ISerialNumberControl {
  companyId: TId;
}

export function SerialNumberControl() {
  const { getFieldState, setValue, watch } = useFormContext();
  const deviceType = watch('deviceType');
  const isIDT = watch('isIDT');
  const serialNumberState = getFieldState('serialNumber');
  const isApartmentRequired = useSiteContext().isSiteFeatureEnabled('NON_INTERCOM_DEVICE_FORBIDDEN_OUTSIDE_APARTMENT');

  useEffect(() => {
    const isDeviceIntercomOrAccessUnit = isIntercomOrAccessUnit(getTypeFromDeviceType(deviceType));

    setValue('isDeviceIntercomOrAccessUnit', isDeviceIntercomOrAccessUnit);
    setValue('isApartmentRequired', isApartmentRequired && !isDeviceIntercomOrAccessUnit);
    setValue('isIDT', getTypeFromDeviceType(deviceType) === DEVICE_TYPE.IDT);
  }, [deviceType]);

  return (
    <FormControl fullWidth>
      <OutlinedTextField
        autoFocus
        disabled
        error={!serialNumberState.error}
        InputProps={{ inputComponent: SerialNumberField }}
        label={<FormattedMessage {...messages.serialNumberControlInputLabel} />}
        name="serialNumber"
        required
        fullWidth
        helperText={
          !serialNumberState.error && (
            <DeviceModelName deviceType={deviceType} unknownType={false} type={isIDT ? DEVICE_TYPE.IDT : ''} />
          )
        }
      />
    </FormControl>
  );
}
