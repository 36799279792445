import { DASH_LIMIT, DASH_WIDTH } from './DevicesColumn';

const BaseColumnWidth = 40;
const DeviceCountTextDescriptionWidth = 170;

export function getDevicesCountColumnWidth({
  isBillingTypeApartment,
  limit,
}: {
  isBillingTypeApartment: boolean;
  limit: number | undefined;
}): number | undefined {
  if (!isBillingTypeApartment || !limit) {
    return undefined;
  }

  if (limit <= DASH_LIMIT) {
    return BaseColumnWidth + DASH_WIDTH * limit;
  }

  return DeviceCountTextDescriptionWidth;
}
