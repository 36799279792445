import CheckIcon from '@mui/icons-material/Check';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IStep, MultistepDialog } from '@my2n/multistep-dialog';
import React, { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { IModalBase, TId } from '../../modules/commonTypes';
import { useGetChangeSiteTypeModalHooks } from './hooks/useGetChangeSiteTypeModalHooks';
import messages from './messages';
import { SelectSiteCategoryStep } from './steps/SelectSiteCategoryStep/SelectSiteCategoryStep';
import { SelectSiteTypeStep } from './steps/SelectSiteTypeStep/SelectSiteTypeStep';
import { ValidationIssuesStep } from './steps/ValidationIssuesStep/ValidationIssuesStep';

interface IChangeSiteTypeModal extends IModalBase {
  siteId: TId;
}

export function ChangeSiteTypeModal({ onClose, open, siteId }: IChangeSiteTypeModal) {
  const { buttonsText, formatMessage, formMethods, handleSubmit, isFetching, manageSiteTypeValidationResult } =
    useGetChangeSiteTypeModalHooks(siteId, onClose);

  const isCategoryInvalid = formMethods.getValues('siteCategory') === undefined;
  const isTypeInvalid = formMethods.getValues('siteType') === undefined;

  const shouldRenderValidationIssuesStep =
    manageSiteTypeValidationResult && !manageSiteTypeValidationResult.canChangeSiteType;

  const steps: IStep[] = useMemo(() => {
    const initialSteps: IStep[] = [
      {
        children: <SelectSiteCategoryStep />,
        isNextDisabled: isCategoryInvalid,
      },
      {
        children: <SelectSiteTypeStep />,
        isNextDisabled: isTypeInvalid,
      },
    ];

    if (shouldRenderValidationIssuesStep) {
      initialSteps.push({
        children: <ValidationIssuesStep manageSiteTypeValidationResult={manageSiteTypeValidationResult} />,
        isBackDisabled: true,
      });
    }

    return initialSteps;
  }, [isCategoryInvalid, isTypeInvalid, shouldRenderValidationIssuesStep]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <MultistepDialog
          testId="change-site-type-modal"
          title={formatMessage(messages.changeSiteTypeModalTitle)}
          backButtonLabel={formatMessage(messages.CreateSiteModalBack)}
          cancelButtonLabel={buttonsText.cancel}
          nextButtonLabel={formatMessage(messages.CreateSiteModalNext)}
          orderedSteps={steps}
          stepControl={shouldRenderValidationIssuesStep ? 2 : undefined}
          submitButton={{
            icon: shouldRenderValidationIssuesStep ? <RefreshIcon /> : <CheckIcon />,
            isDisabled: !formMethods.formState.isValid || isFetching,
            label: buttonsText.submit,
          }}
          onClose={onClose}
          open={open}
          onSubmit={formMethods.handleSubmit(handleSubmit)}
        />
      </form>
    </FormProvider>
  );
}
