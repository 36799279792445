import { defineMessages } from 'react-intl';

export default defineMessages({
  deviceModelNameAccessUnits: {
    defaultMessage: '2N {device}',
    description: 'Device model name - Access Units',
    id: 'deviceModelName.accessUnits',
  },
  DeviceModelNameDeviceNon2NTypeAXISI5304: {
    defaultMessage: 'AXIS I5304',
    id: 'deviceModelNameDeviceNon2NTypeAXISI5304',
  },
  deviceModelNameDeviceNon2NTypeDoorStation: {
    defaultMessage: 'AXIS Intercom',
    description: 'Device type - AXIS Intercom',
    id: 'deviceModelName.deviceNon2NType.DOOR_STATION',
  },
  deviceModelNameDeviceNon2NTypeHipmo: {
    defaultMessage: 'Smartphone or tablet',
    description: 'Device type - Smartphone or tablet',
    id: 'deviceModelName.deviceNon2NType.HIPMO',
  },
  deviceModelNameDeviceNon2NTypeIdt: {
    defaultMessage: '2N<sup>®</sup> Indoor Touch',
    description: 'Device type - Indoor Touch for 1.0 or 2.0.',
    id: 'deviceModelName.deviceNon2NType.IDT',
  },
  deviceModelNameDeviceNon2NTypeThirdParty: {
    defaultMessage: 'Unknown - 3rd Party Device',
    description: 'Device type - 3rd party device',
    id: 'deviceModelName.deviceNon2NType.THIRD_PARTY',
  },
  deviceNon2NTypeAxisThirdParty: {
    defaultMessage: 'Axis 3rd Party Device',
    id: 'deviceModelName.deviceNon2NType.THIRD_PARTY_AXIS',
  },
  deviceNon2NTypeName: {
    defaultMessage: '2N<sup>®</sup> {device}',
    description: 'Device model name',
    id: 'deviceModelName.name',
  },
  deviceNon2NTypeUnknown: {
    defaultMessage: 'Unknown',
    description: 'Device model unknown',
    id: 'deviceModelName.unknown',
  },
});
