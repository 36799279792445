import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from '../../../../components';
import messages from './messages';

export function ErrorAlertBox() {
  return (
    <Alert severity="error">
      <FormattedMessage {...messages.errorAlertBoxError} />
    </Alert>
  );
}
