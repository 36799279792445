import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DEVICE_TYPE } from '../../config/devices';
import { getNameFromDeviceType } from '../../helpers/devices';
import messages from './messages';

export interface IDeviceModelNameProps {
  deviceType: string;
  type: string;
  unknownType: boolean;
}

export function DeviceModelName({ deviceType, type, unknownType }: IDeviceModelNameProps) {
  const sup = (chunks: string) => <sup>{chunks}</sup>;
  const name = getNameFromDeviceType(deviceType);
  if (name) {
    if (['Access Unit', 'Access Unit 2.0', 'Access Unit M', 'Access Unit QR'].includes(name)) {
      return (
        <FormattedMessage
          {...messages.deviceModelNameAccessUnits}
          values={{
            device: name.replace('2N', '').trim(),
          }}
        />
      );
    }
    if (name === 'Axis Indoor View') {
      return <FormattedMessage {...messages.DeviceModelNameDeviceNon2NTypeAXISI5304} />;
    }
    return (
      <FormattedMessage
        {...messages.deviceNon2NTypeName}
        values={{
          device: name.replace('2N<sup>®</sup>', '').trim(),
          sup,
        }}
      />
    );
  }

  if (deviceTypeMap[type]) {
    return <FormattedMessage {...deviceTypeMap[type]} values={{ sup }} />;
  }

  return unknownType ? <FormattedMessage {...messages.deviceNon2NTypeUnknown} /> : <>{''}</>;
}

const deviceTypeMap = {
  [DEVICE_TYPE.HIPMO]: messages.deviceModelNameDeviceNon2NTypeHipmo,
  [DEVICE_TYPE.DOOR_STATION]: messages.deviceModelNameDeviceNon2NTypeDoorStation,
  [DEVICE_TYPE.THIRD_PARTY]: messages.deviceModelNameDeviceNon2NTypeThirdParty,
  [DEVICE_TYPE.IDT]: messages.deviceModelNameDeviceNon2NTypeIdt,
  [DEVICE_TYPE.AXIS_THIRD_PARTY]: messages.deviceNon2NTypeAxisThirdParty,
};
