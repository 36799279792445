import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import Checkbox from '../../../components/HookFormFields/Checkbox';

export interface IPaymentConfirm {
  description?: React.JSX.Element;
  message: React.JSX.Element;
  name: string;
}

export function PaymentConfirm({ description, message, name }: IPaymentConfirm) {
  return (
    <Grid item sx={{ mb: 3 }}>
      <Checkbox
        name={name}
        label={
          <Typography component="span" variant="body2">
            {message}
          </Typography>
        }
        required
        sx={{
          '& .MuiStack-root': {
            display: 'block !important',
          },
          '& span': {
            pt: 0,
          },
          alignItems: 'flex-start',
          display: 'flex',
        }}
      />
      {description && <Typography variant="body2">{description}</Typography>}
    </Grid>
  );
}
