import { defineMessages } from 'react-intl';

export default defineMessages({
  createNewApartment: {
    defaultMessage: 'Create new apartment',
    description: 'Create new apartment',
    id: 'oneStep.apartment.createNewApartment',
  },
  newApartmentEmptySelect: {
    defaultMessage: 'No apartment',
    description: 'No apartment selected',
    id: 'oneStep.apartment.emptySelect',
  },
  newApartmentFloorHelperText: {
    defaultMessage: 'Usually a group of apartments',
    description: 'Apartment form helper text - floor',
    id: 'apartmentFormHelperTextFloor',
  },
  newApartmentHelperText: {
    defaultMessage: 'Add the device to an apartment for easier administration.',
    description: 'Apartment helper text',
    id: 'oneStep.apartment.helperText',
  },
  newApartmentLabel: {
    defaultMessage: 'Apartment',
    description: 'Apartment label',
    id: 'oneStep.apartment.label',
  },
  newApartmentNameHelperText: {
    defaultMessage: 'You can name the apartment after the family name.',
    description: 'Create new apartment - name helper text',
    id: 'oneStep.newApartment.name.helperText',
  },
  newApartmentNameLabel: {
    defaultMessage: 'Apartment name',
    description: 'Create new apartment - name label',
    id: 'oneStep.newApartment.name.label',
  },
  newApartmentNumberHelperText: {
    defaultMessage: 'Use a unique number, like 27, 4A or 2.01',
    description: 'Create new apartment - number helper text',
    id: 'oneStep.newApartment.number.helperText',
  },
  newApartmentNumberLabel: {
    defaultMessage: 'Number',
    description: 'Create new apartment - number label',
    id: 'oneStep.newApartment.number.label',
  },
  newApartmentNumberNotUniqueError: {
    defaultMessage: 'An apartment with this number already exist',
    description: 'Helper text when entered apartment number is same as already existing apartments number',
    id: 'newApartmentNumberNotUniqueError',
  },
  newApartmentNumberRequiredError: {
    defaultMessage: 'Enter the apartment number',
    description: 'Helper text when field is empty',
    id: 'newApartmentNumberRequiredError',
  },
});
