import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useHasFeature } from '../components/Feature/Feature';
import { PATHS } from '../config/consts';
import featureFlags from '../config/featureFlags';
import SiteSettings from '../containers/SiteSettings';
import Access from '../containers/SiteSettings/Access/Access';
import Calling from '../containers/SiteSettings/Calling/Callling';
import ElevatorIntegration from '../containers/SiteSettings/ElevatorIntegration';
import SiteLanguage from '../containers/SiteSettings/SiteLanguage';
import { useGetLoadedParams } from '../hooks/paramsHooks';
import { ApplicationContext, Roles } from '../modules/commonTypes';
import { PrivateRoute } from './components/PrivateRoute';

export function SiteSettingsRoutes() {
  const { companyId, siteId } = useGetLoadedParams(ApplicationContext.Site);
  const isElevatorIntegrationFFEnabled = useHasFeature(featureFlags.ELEVATOR_INTEGRATION);
  const isQRCodeFFEnable = useHasFeature(featureFlags.QR_CODE);
  const isSiteGSMFFEnabled = useHasFeature(featureFlags.SITE_GSM);

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.ADMIN, Roles.STANDARD] }}>
            <SiteSettings />
          </PrivateRoute>
        }
      >
        <Route element={<SiteLanguage />} path={'site-language'} />
        {isQRCodeFFEnable && (
          <Route
            element={
              <PrivateRoute allowedRoles={{ contextRoles: [Roles.ADMIN, Roles.STANDARD] }}>
                <Access />
              </PrivateRoute>
            }
            path={'access'}
          />
        )}
        {isSiteGSMFFEnabled && (
          <Route
            element={
              <PrivateRoute allowedRoles={{ contextRoles: [Roles.ADMIN, Roles.STANDARD] }}>
                <Calling />
              </PrivateRoute>
            }
            path={'calling'}
          />
        )}
        {isElevatorIntegrationFFEnabled && <Route element={<ElevatorIntegration />} path={'elevator-integration'} />}
      </Route>
      <Route
        path={'/*'}
        element={<Navigate replace to={getRedirectPath(companyId, siteId, isElevatorIntegrationFFEnabled)} />}
      />
    </Routes>
  );
}

function getRedirectPath(companyId: number, siteId: number, isElevatorIntegrationFFEnabled: boolean) {
  let path = PATHS.SITE_SETTINGS_SITE_LANGUAGE;
  if (isElevatorIntegrationFFEnabled) {
    path = PATHS.SITE_SETTINGS_ELEVATOR_INTEGRATION;
  }
  return path.replace(':companyId', companyId.toString()).replace(':siteId', siteId.toString());
}
