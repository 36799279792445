import { Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FilledWarningBar } from '../../../../../components/_DesignSystemWrappers/WarningBar/FilledWarningBar';
import messages from '../../messages';

export function MFASetupStep1() {
  return (
    <>
      <Stack spacing={2}>
        <Typography>
          <FormattedMessage {...messages.TwoFactorAuthenticationModalLinkStep1Text1}></FormattedMessage>
        </Typography>
        <Typography>
          <FormattedMessage {...messages.TwoFactorAuthenticationModalLinkStep1Text2}></FormattedMessage>
        </Typography>
      </Stack>

      <Typography variant="body2" component="div">
        <FormattedMessage {...messages.TwoFactorAuthenticationModalLinkStep1Text3}></FormattedMessage>
      </Typography>
      <Stack spacing={2}>
        <Typography>
          <FormattedMessage {...messages.TwoFactorAuthenticationModalLinkStep1Text4}></FormattedMessage>
        </Typography>

        <FilledWarningBar>
          <FormattedMessage {...messages.TwoFactorAuthenticationModalLinkStep1WarningAppLogout}></FormattedMessage>
        </FilledWarningBar>
      </Stack>
    </>
  );
}
