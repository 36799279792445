import { AxiosResponse } from 'axios';
import { IPin, TId, TPaginationParams, TUuid } from '../modules/commonTypes';
import { IUser, UserRoles } from '../modules/user/store';
import { encode } from './index';
import { deleteSiteData, getSiteData, ISiteGenericRequestData, postSiteData, putSiteData } from './siteRequest';

export type TMultipleUsers = { lastName: string }[];

export interface ICreateMultipleUsersData {
  users: TMultipleUsers;
  generatePin: boolean;
}

interface TUserActionContextStatus {
  enabled: boolean;
  authIdHash: string;
}

export interface IRfid {
  id: TUuid;
  rfidCode: string;
}

// replace with API type definition
export interface IUserInput {
  apartments: number[];
  email: string | undefined;
  firstName?: string;
  gsmCalls?: boolean;
  lastName: string;
  role: UserRoles;
  phoneNumber?: string;
  locale: string;
}

export interface IUWLUserAssignedPin {
  pin: string;
  lastName: string;
}

export const enableUserMy2NAppAccess = async (siteData: ISiteGenericRequestData, userId: TId): Promise<AxiosResponse> =>
  postSiteData(siteData, `users/${encode(userId.toString())}/credentials/bluetooth/enable`, {}, false);

export const disableUserMy2NAppAccess = async (
  siteData: ISiteGenericRequestData,
  userId: TId
): Promise<AxiosResponse> =>
  postSiteData(siteData, `users/${encode(userId.toString())}/credentials/bluetooth/disable`, {}, false);

export const getUserMy2NAppAccessStatus = async (
  siteData: ISiteGenericRequestData,
  userId: TId
): Promise<AxiosResponse<Array<TUserActionContextStatus>>> =>
  getSiteData(siteData, `users/${encode(userId.toString())}/credentials/bluetooth/status`, false);

export const getSiteUserDetail = async (
  siteData: ISiteGenericRequestData,
  userId: TId
): Promise<AxiosResponse<IUser>> => getSiteData(siteData, `users/${encode(userId.toString())}`, true);

export const getSiteUsersList = async (
  siteData: ISiteGenericRequestData,
  params: TPaginationParams
): Promise<AxiosResponse<{ users: Array<IUser>; resultsFiltered: number; resultsTotal: number }>> =>
  getSiteData(siteData, 'users', true, params);

export const createUser = async (siteData: ISiteGenericRequestData, data: any): Promise<AxiosResponse> =>
  postSiteData(siteData, `users`, data, true);

export const getUserPin = async (siteData: ISiteGenericRequestData, userId: TId): Promise<AxiosResponse<IPin>> =>
  getSiteData(siteData, `users/${encode(userId.toString())}/credentials/pin`, false);

export const generateUserPin = async (siteData: ISiteGenericRequestData, userId: TId): Promise<AxiosResponse> =>
  postSiteData(siteData, `users/${encode(userId.toString())}/credentials/pin`, null, false);

export const saveUserPin = async (
  siteData: ISiteGenericRequestData,
  userId: TId,
  pinId: TUuid
): Promise<AxiosResponse> =>
  putSiteData(siteData, `users/${encode(userId.toString())}/credentials/pin/${pinId}/activate`, null, false);

export const createUserRfid = async (
  siteData: ISiteGenericRequestData,
  userId: TId,
  rfidCode: string
): Promise<AxiosResponse> =>
  postSiteData(
    siteData,
    `users/${encode(userId.toString())}/credentials/rfid`,
    {
      rfidCode,
    },
    false
  );

export const getUserRfid = async (siteData: ISiteGenericRequestData, userId: TId): Promise<AxiosResponse<[IRfid]>> =>
  getSiteData(siteData, `users/${encode(userId.toString())}/credentials/rfid`, false);

export const deleteUser = async (siteData: ISiteGenericRequestData, user: IUser): Promise<AxiosResponse> =>
  deleteSiteData(siteData, `users/${encode(user.id.toString())}`, true);

export const updateSiteUser = async (
  siteData: ISiteGenericRequestData,
  userId: TId,
  values: IUserInput
): Promise<AxiosResponse<IUserInput>> => putSiteData(siteData, `users/${encode(userId.toString())}`, values, true);
