import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Icon2N } from '../../../components';
import { DEVICE_TYPE } from '../../../config/devices';
import actions from '../../../modules/devices';
import AddDeviceModal from '../AddDeviceModal';
import axisDoorStationFormMessages from '../AxisDoorStationForm/messages';
import { Device } from '../DeviceControl/Device';
import { devicesControlStyles } from '../DeviceControl/DevicesControl';
import DeviceTitle from '../DeviceControl/DeviceTitle';
import { TDevicesControlValues } from '../types';
import messages from './messages';

interface IAxisDeviceForm {
  onClearModal: () => void;
  onClose: () => void;
  onSelectDevice: (value?: TDevicesControlValues) => void;
  open: boolean;
}

export function AxisDeviceForm({ onClearModal, onClose, onSelectDevice, open }: IAxisDeviceForm) {
  const dispatch = useDispatch();

  function backAction() {
    onSelectDevice(undefined);
    onClearModal();
    dispatch(actions.addDeviceClearModalData);
  }

  return (
    <AddDeviceModal
      title={<DeviceTitle device={'AXIS_DEVICE'} />}
      actions={
        <>
          <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
            <Button onClick={backAction} startIcon={<ArrowBackIcon />} sx={{ marginRight: 'auto' }}>
              <FormattedMessage {...axisDoorStationFormMessages.formActionBackBtn} />
            </Button>
          </Box>
          <Button onClick={onClose}>
            <FormattedMessage {...axisDoorStationFormMessages.formActionCancelBtn} />
          </Button>
        </>
      }
      onClose={onClose}
      open={open}
    >
      <Typography mb={2}>
        <FormattedMessage {...messages.addAxisDeviceText} />
      </Typography>
      <ButtonGroup sx={devicesControlStyles}>
        <Device
          icon={<Icon2N name="axis-ds" />}
          onSelectDevice={onSelectDevice}
          title={<FormattedMessage {...messages.addAxisDeviceIntercom} />}
          value={DEVICE_TYPE.DOOR_STATION}
        />
        <Device
          icon={<Icon2N name="axis-au" />}
          onSelectDevice={onSelectDevice}
          title={<FormattedMessage {...messages.addAxisDeviceAnsweringUnit} />}
          value={DEVICE_TYPE.AXIS_ANSWERING_UNIT}
        />
      </ButtonGroup>
    </AddDeviceModal>
  );
}
