import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useUrlParams } from '../../../../helpers/urlParams';
import { selectors as companySelectors } from '../../../../modules/company';
import { selectors as siteSelectors } from '../../../../modules/sites';
import { selectors } from '../../../../modules/subscription';
import { getUseAvailableCredit } from '../../helpers';
import { LICENSE } from '../../UnitPropsTS';
import { ICompany } from '../../useGetSubscriptionCardsData';
import { getMVLicenceValidationSchema } from './validationSchema';

export interface IMobileVideoLicenseFormValues {
  isRecurringPayment: boolean;
  product: LICENSE;
  paymentPrepared: boolean;
  agreeGTC: boolean;
}

export function useGetMVLicenceData() {
  const dispatch = useDispatch();
  const { siteId } = useUrlParams();
  const { formatMessage } = useIntl();
  const calculation = useSelector(selectors.getCalculation);
  const company = useSelector(companySelectors.getCompanyData) as ICompany;
  const currency = useSelector(companySelectors.getCurrentCompanyCurrencySelector);
  const isAvailableCredit = getUseAvailableCredit();
  const loading = useSelector(selectors.getVideoMobileLicenseIsLoading);
  const mobileVideoLicenceModel = useSelector(siteSelectors.getSitesServiceMobileVideoLicenceModel);
  const mobileVideoOrderContent = useSelector(selectors.getVMOrderContent);
  const mobileVideoPaymentIsLoading = useSelector(selectors.getMobileVideoPaymentIsLoading);
  const mobileVideoPurchaseId = useSelector(selectors.getMobileVideoPurchaseId);
  const mobileVideoPurchaseLocation = useSelector(selectors.getMobileVideoPurchaseLocation);
  const mobileVideoStartPurchase = useSelector(selectors.getMobileVideoStartPurchase);
  const monthPercentage = useSelector(selectors.getMonthPercentage);
  const nextPayment = useSelector(selectors.getNextPayment);
  const product = useSelector(siteSelectors.getSitesServiceMobileVideoProduct);
  const purchasePaymentResult = useSelector(selectors.getPurchasePaymentResult);
  const regularPayment = useSelector(selectors.getRegularPayment);
  const unlicensedMobileVideoCount = useSelector(siteSelectors.getUnlicensedMobileVideoCount);

  const formMethods = useForm<IMobileVideoLicenseFormValues>({
    defaultValues: {
      agreeGTC: false,
      isRecurringPayment: false,
      paymentPrepared: false,
      product: product || LICENSE.MOBILE_VIDEO_MONTH,
    },
    mode: 'onChange',
    resolver: yupResolver(getMVLicenceValidationSchema()),
  });

  return {
    calculation,
    company,
    currency,
    dispatch,
    formatMessage,
    formMethods,
    isAvailableCredit,
    loading,
    mobileVideoLicenceModel,
    mobileVideoOrderContent,
    mobileVideoPaymentIsLoading,
    mobileVideoPurchaseId,
    mobileVideoPurchaseLocation,
    mobileVideoStartPurchase,
    monthPercentage,
    nextPayment,
    product,
    purchasePaymentResult,
    regularPayment,
    siteId,
    unlicensedMobileVideoCount,
  };
}
