import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSiteContext } from '../../../../../../context/useSiteContext';
import { getVoucherValue } from '../../../hooks/helpers';
import { SUBSCRIPTION_TYPE } from '../../../hooks/types';
import { InputLabel } from '../InputLabel';
import messages from '../messages';

export interface ITotalValue {
  batchIndex: number;
}

export function TotalValue({ batchIndex }: ITotalValue) {
  const isPerApartmentBillingType = useSiteContext().hasSiteBillingType('PER_APARTMENT');
  const { watch } = useFormContext();
  const batch = watch('array.' + batchIndex);

  const getAdornmentPluralOrSingular = (context: string) => {
    const totalValue = getVoucherValue(batch.years, batch.subscriptions);
    if (totalValue === 1) {
      return (
        <FormattedMessage
          {...(context === 'apartment'
            ? messages.promoBatchFormInputValueAdornmentApartmentYear
            : messages.promoBatchFormInputValueAdornmentDeviceYear)}
        />
      );
    }
    return (
      <FormattedMessage
        {...(context === 'apartment'
          ? messages.promoBatchFormInputValueAdornmentApartmentYears
          : messages.promoBatchFormInputValueAdornmentDeviceYears)}
      />
    );
  };

  const getSubscriptionInputAdornment = () => {
    if (batch.subscriptionType === SUBSCRIPTION_TYPE.DEVICE) {
      return getAdornmentPluralOrSingular('device');
    }
    if (batch.subscriptionType === SUBSCRIPTION_TYPE.APARTMENT || isPerApartmentBillingType) {
      return getAdornmentPluralOrSingular('apartment');
    }
    return getAdornmentPluralOrSingular('device');
  };

  return (
    <>
      <InputLabel>
        <FormattedMessage {...messages.promoBatchFormInputValueLabel} />
      </InputLabel>
      <Typography>
        <strong>{getVoucherValue(batch.years, batch.subscriptions)}</strong>{' '}
        <Box
          component="span"
          sx={{
            color: 'text.secondary',
            display: 'inline-flex',
          }}
        >
          {getSubscriptionInputAdornment()}
        </Box>
      </Typography>
    </>
  );
}
