import { yupResolver } from '@hookform/resolvers/yup';
import DoneIcon from '@mui/icons-material/Done';
import SettingsIcon from '@mui/icons-material/Settings';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import serviceGsm from '../../../assets/service-gsmcalling.svg';
import { RCModal, ServicePanel } from '../../../components';
import { SERVICE, SIP_PROFILE_NUMBER } from '../../../config/devices';
import { IDevice } from '../../../modules/devices/store/deviceStore';
import messages from './messages';
import { getDefaultValues, getValidationSchema } from './validationSchema';
interface IGSMCallingServiceProps {
  device: IDevice;
  isGsmActivable: boolean;
  isRCConfigurationDisabled: boolean;
  onUpdateServiceConfiguration: (data: { gsmValue: { active: boolean; type: string } }) => void;
  onCreateServiceConfiguration: (deviceId: number, type: SERVICE) => void;
  onOpenRCModal: (device: IDevice) => void;
}

const GSMCallingService = ({
  device,
  isGsmActivable,
  isRCConfigurationDisabled,
  onCreateServiceConfiguration,
  onOpenRCModal,
  onUpdateServiceConfiguration,
}: IGSMCallingServiceProps) => {
  const { formatMessage } = useIntl();
  const active = device?.services?.[SERVICE.GSM_CALLS]?.active || false;
  const sipProfiles = Object.values(SIP_PROFILE_NUMBER);

  const formMethods = useForm({
    defaultValues: getDefaultValues(device),
    mode: 'onChange',
    resolver: yupResolver(getValidationSchema()),
  });

  const handleUpdate = () => {
    if (!device?.services?.[SERVICE.GSM_CALLS] && isGsmActivable) {
      onCreateServiceConfiguration(device.id, SERVICE.GSM_CALLS);
    } else {
      onUpdateServiceConfiguration({
        gsmValue: {
          active: !active,
          type: SERVICE.GSM_CALLS,
        },
      });
    }
  };

  const handleButtonSave = () => {
    const formValues = formMethods.getValues();
    onUpdateServiceConfiguration({
      gsmValue: {
        active: active,
        type: SERVICE.GSM_CALLS,
        ...(formValues.sipProfile && { sipProfileNumber: formValues.sipProfile }),
      },
    });
  };

  const getConfirmTitle = () =>
    active ? (
      <FormattedMessage {...messages.GSMServiceStatusDeactivateTitle} />
    ) : (
      <FormattedMessage {...messages.GSMServiceStatusActivateTitle} />
    );

  const getStateInfo = () => {
    if (active) {
      return <FormattedMessage tagName="strong" {...messages.GSMServiceStatusActive} />;
    } else if (!active) {
      return <FormattedMessage tagName="strong" {...messages.GSMServiceStatusNotActive} />;
    }
  };

  const getConfirmInfo = () =>
    active ? (
      <Typography>
        <FormattedMessage {...messages.GSMServiceStatusDeactivateText} values={{ br: <br /> }} />
      </Typography>
    ) : (
      <Typography>
        <FormattedMessage {...messages.GSMServiceStatusActivateText} values={{ br: <br /> }} />
      </Typography>
    );

  const getSipProfileMessage = (profile: SIP_PROFILE_NUMBER) => {
    const messageKeys = {
      [SIP_PROFILE_NUMBER.SIP_1]: messages.GSMServiceSipProfileValue1,
      [SIP_PROFILE_NUMBER.SIP_2]: messages.GSMServiceSipProfileValue2,
      [SIP_PROFILE_NUMBER.SIP_3]: messages.GSMServiceSipProfileValue3,
      [SIP_PROFILE_NUMBER.SIP_4]: messages.GSMServiceSipProfileValue4,
    };
    return messageKeys[profile];
  };

  const renderValue = (selected: string) => {
    if (selected === '') {
      return (
        <Typography>
          <FormattedMessage {...messages.GSMServiceSipProfilePlaceholder} />
        </Typography>
      );
    } else {
      return (
        <Typography>
          <FormattedMessage {...getSipProfileMessage(selected as SIP_PROFILE_NUMBER)} />
        </Typography>
      );
    }
  };

  return (
    <>
      <ServicePanel
        active={active}
        icon={<img alt="GSM calling service" src={serviceGsm} />}
        disabled={false}
        isServiceAvailable={isGsmActivable}
        name={<FormattedMessage tagName="strong" {...messages.GSMServiceTitle} />}
        state={getStateInfo()}
        toggleConfirmTitle={getConfirmTitle()}
        toggleConfirmInfo={getConfirmInfo()}
        onToggleConfirm={handleUpdate}
      >
        <Grid container spacing={4}>
          <Grid item lg={4} md={6} xs={12}>
            <Box pt={2}>
              <Typography color="textSecondary" variant="body2" mb={2}>
                <FormattedMessage {...messages.GSMServiceText1} />
              </Typography>
              <Button
                color="primary"
                data-gaid="deviceDetailOpenConfigurationBtn"
                disabled={isRCConfigurationDisabled}
                onClick={() => onOpenRCModal(device)}
                startIcon={<SettingsIcon />}
                variant="contained"
              >
                <FormattedMessage {...messages.GSMServiceButtonOpenConfig} />
              </Button>
            </Box>
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <Box pt={2}>
              <FormControl fullWidth>
                <InputLabel shrink disabled={!active}>
                  <FormattedMessage {...messages.GSMServiceSipProfileLabel} />
                </InputLabel>
                <Select
                  displayEmpty
                  notched
                  disabled={!active}
                  {...formMethods.register('sipProfile')}
                  defaultValue={device?.services?.[SERVICE.GSM_CALLS]?.sipProfileNumber ?? ''}
                  renderValue={renderValue}
                  label={formatMessage(messages.GSMServiceSipProfileLabel)}
                >
                  {sipProfiles.map((sipProfile) => (
                    <MenuItem key={sipProfile} value={sipProfile}>
                      <Typography>
                        <FormattedMessage {...getSipProfileMessage(sipProfile)} />
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid container item justifyContent="flex-end">
            <Grid item>
              <Button
                color="primary"
                startIcon={<DoneIcon />}
                variant="contained"
                disabled={!formMethods.formState.isDirty || !active}
                onClick={handleButtonSave}
              >
                <FormattedMessage {...messages.GSMServiceSubmit} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </ServicePanel>
      <RCModal />
    </>
  );
};

export default GSMCallingService;
