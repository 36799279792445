import { FE_ROLES, GRANTED_ROLES } from '../../../config/consts';
import { onSetRole } from '../../../helpers/user';
import actions, { CONTEXT } from '../../../modules/user';
import messages from '../messages';

export function getSubmitButtonText(isEdit, context) {
  if (isEdit) {
    return messages.userModalFormActionsSave;
  }
  if (context === CONTEXT.SITE_USERS) {
    return messages.userModalFormActionsCreateSiteUser;
  }
  if (context === CONTEXT.COMPANY_ADMINS) {
    return messages.userModalFormActionsCreateCompanyAdmin;
  }
  return messages.userModalFormActionsAdd;
}

export function getTitle(isEdit, isCurrentUser, context) {
  if (isEdit) {
    if (!isCurrentUser && context === CONTEXT.COMPANY_ADMINS) {
      return messages.userModalEditAdmin;
    }
    if (!isCurrentUser && context === CONTEXT.SITE_USERS) {
      return messages.userModalEditSiteUser;
    }
    return messages.userModalEditProfile;
  }

  return context === CONTEXT.COMPANY_ADMINS ? messages.userModalAddAdmin : messages.userModalAddSiteUser;
}

export function getInitialFormData(initialApartmentData, hasFormItemKey, data) {
  return {
    apartment: initialApartmentData,
    email: data?.email ?? '',
    firstName: data?.firstName ?? '',
    floor: '',
    gsmCalls: false,
    [hasFormItemKey]: false,
    id: data?.id,
    lastName: data?.lastName ?? '',
    name: '',
    number: '',
    phoneNumber: '',
  };
}

export function getSubmitFormCallback({
  context,
  data,
  dispatch,
  formMethods,
  hasFormItemKey,
  initialData,
  isCurrentUser,
  isEdit,
  newItemId,
  onClose,
  setSubmitting,
  userRole,
  validation,
}) {
  return (formData) => {
    const formActions = {
      resetForm: () => formMethods.reset(getInitialFormData(initialData, hasFormItemKey)),
      setSubmitting: () => {
        onClose();
      },
    };

    const enrichedFormData = {
      ...formData,
      newApartmentField: newItemId,
      newsletters: false,
    };

    setSubmitting(true);

    if (isEdit) {
      dispatch(actions.editUser(enrichedFormData, context, isCurrentUser, formActions, data.id));
      onSetRole(() => dispatch(actions.setSiteUserRole()), userRole, data, validation, context);
    } else {
      if (userRole !== FE_ROLES.COMPANY_ADMIN) {
        dispatch(actions.createUser(enrichedFormData, context, userRole, formActions));
      } else {
        dispatch(actions.createUser(enrichedFormData, context, GRANTED_ROLES[userRole], formActions));
      }
    }
  };
}
