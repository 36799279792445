import Button from '@mui/material/Button';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Alert from '../../components/Alert';
import messages from './messages';

export function SelectSiteTypeAlert({ onOpenModal }: { onOpenModal: () => void }) {
  const { formatMessage } = useIntl();

  return (
    <Alert
      action={
        <Button onClick={onOpenModal} size="small">
          <FormattedMessage {...messages.selectSiteTypeAlertAction} />
        </Button>
      }
      severity="warning"
      title={formatMessage(messages.selectSiteTypeAlertTitle)}
    >
      <FormattedMessage {...messages.selectSiteTypeAlertText} />
    </Alert>
  );
}
