import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, ListItemIcon, MenuItem, Stack } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { SiteBasicInfo } from '../../../../apiRtk/be4feApi';
import { SITE_CATEGORY, SITE_TYPE } from '../../../../config/sites';
import { getSiteCategoryMessage, getSiteTypeMessage } from '../../../../helpers/sites/siteTypeHelper';
import { styles as switcherStyles } from '../../styles/commonSwitcher';

export interface ISiteOptionProps {
  siteData: SiteBasicInfo;
  selected: boolean;
  onSelect: (id: number) => void;
}

export function SiteOption({ onSelect, selected, siteData }: ISiteOptionProps) {
  const formatMessage = useIntl().formatMessage;
  const categoryLabelText = getSiteCategoryMessage(siteData.siteCategory as SITE_CATEGORY);
  const siteTypeLabelText = getSiteTypeMessage(siteData.siteType as SITE_TYPE);

  return (
    <MenuItem key={siteData.id} onClick={() => onSelect(siteData.id)} selected={selected} sx={switcherStyles.listItem}>
      <ListItemIcon sx={switcherStyles.listItemIcon}>
        <LocationOnIcon color="secondary" />
      </ListItemIcon>
      <Stack>
        <Box component="span" sx={switcherStyles.listItemName}>
          {siteData.name}
        </Box>
        {categoryLabelText !== undefined && siteTypeLabelText !== undefined && (
          <Box component="span" sx={styles.siteType}>
            {`${formatMessage(categoryLabelText)} / ${formatMessage(siteTypeLabelText)}`}
          </Box>
        )}
      </Stack>
    </MenuItem>
  );
}

const styles = {
  siteType: {
    color: 'text.secondary',
    fontSize: '0.75rem',
    maxWidth: 196,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};
