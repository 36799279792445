import Box from '@mui/material/Box';
import MuiInputLabel from '@mui/material/InputLabel';
import React from 'react';

export interface IInputLabel {
  children: React.JSX.Element;
}

export function InputLabel({ children, ...other }: IInputLabel) {
  return (
    <MuiInputLabel shrink {...other}>
      <Box component="span" whiteSpace="nowrap">
        {children}
      </Box>
    </MuiInputLabel>
  );
}
