import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { SerialNumberMaskedInput } from '../../../../components';
import { OutlinedTextField } from '../../../../components/HookFormFields/TextField';
import { addSnackbarError, addSnackbarSuccess } from '../../../../modules/notifications/actions';
import messages from '../../messages';
import SerialNumberList from './SerialNumberList';

const styles = {
  addButton: {
    mt: {
      md: 1,
      xs: 0,
    },
  },
  title: {
    mb: 2,
  },
};

export interface IOneStepProps {
  onAddSerialNumber: (data: string[]) => void;
  onSetLicenses: () => void;
  onStepChange: () => void;
  snLicenseData: object;
  snLicenseIsLoading: boolean;
}

export interface IOneStepFormValues {
  serialNumber: string;
}

export function OneStep({
  onAddSerialNumber,
  onSetLicenses,
  onStepChange,
  snLicenseData,
  snLicenseIsLoading,
}: IOneStepProps) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [serialNumbers, addSerialNumberToList] = useState<string[]>([]);

  const formMethods = useForm<IOneStepFormValues>({
    defaultValues: {
      serialNumber: '',
    },
    mode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        serialNumber: Yup.string()
          .required(formatMessage(messages.oneStepSerialNumberInputRequired))
          .matches(/^([0-9]{2})-([0-9]{4})-([0-9]{4})$/, formatMessage(messages.oneStepSerialNumberInputInvalid)),
      })
    ),
  });

  const handleAddSerialNumber = (data: IOneStepFormValues) => {
    if (!serialNumbers.includes(data.serialNumber)) {
      addSerialNumberToList([...serialNumbers, data.serialNumber]);
      onAddSerialNumber([...serialNumbers, data.serialNumber]);
      formMethods.reset();
    } else {
      dispatch(addSnackbarError(formatMessage(messages.serialNumberListAddAlreadyExist)));
    }
  };

  const handleRemoveSerialNumber = (serialNumber: string) => {
    const newObj = serialNumbers.filter((item) => item !== serialNumber);
    addSerialNumberToList(newObj);
    dispatch(addSnackbarSuccess(messages.serialNumberListRemoveSuccess));
  };

  return (
    <>
      <Typography sx={styles.title}>
        <FormattedMessage
          values={{
            sup: (chunks) => <sup>{chunks}</sup>,
          }}
          {...messages.oneStepTitle}
        />
      </Typography>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleAddSerialNumber)}>
          <Grid container spacing={4} pt={2}>
            <Grid item lg={4} md={6} xl={3} xs={12}>
              <OutlinedTextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputComponent: SerialNumberMaskedInput }}
                label={
                  <FormattedMessage
                    values={{
                      sup: (chunks) => <sup>{chunks}</sup>,
                    }}
                    {...messages.oneStepSerialNumberInputLabel}
                  />
                }
                name="serialNumber"
                placeholder="__-____-____"
                required
              />
            </Grid>
            <Grid item sx={styles.addButton} pt={1}>
              <Button
                color="primary"
                disabled={!formMethods.formState.isValid}
                startIcon={<AddIcon />}
                type="submit"
                variant="contained"
              >
                <FormattedMessage {...messages.oneStepAddButton} />
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <SerialNumberList
        onRemoveSerialNumber={handleRemoveSerialNumber}
        onSetLicenses={onSetLicenses}
        onStepChange={onStepChange}
        serialNumbers={serialNumbers}
        snLicenseData={snLicenseData}
        snLicenseIsLoading={snLicenseIsLoading}
      />
    </>
  );
}
