import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useSiteContext } from '../../../context/useSiteContext';
import { useUrlParams } from '../../../helpers/urlParams';
import useApartmentFormValidationSchema from '../../ApartmentAutocomplete/useApartmentFormValidationSchema';

export function useGetGlobalHooks(hasFormItemKey) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { companyId, siteId } = useUrlParams();

  const formMethods = useForm({
    defaultValues: {
      apartment: [],
      floor: '',
      [hasFormItemKey]: false,
      name: '',
      number: '',
    },
    mode: 'onChange',
    resolver: yupResolver(useApartmentFormValidationSchema(companyId, siteId)),
    shouldUnregister: true,
  });

  return {
    dispatch,
    formatMessage,
    formMethods,
  };
}
