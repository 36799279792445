import Paper from '@mui/material/Paper';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Loader, Stepper, Title } from '../../../components';
import { TYPE } from '../../../config/subscription';
import actions from '../../../modules/subscription';
import ThreeStep from '../Common/ThreeStep';
import { getSubscriptionStepsIntl } from '../helpers';
import messages from '../messages';
import styles from '../styles';
import { ErrorAlertBox } from './Common/ErrorAlertBox';
import { useGetPromoCodeData } from './hooks/useGetPromoCodeData';
import { OneStep } from './OneStep/OneStep';
import TwoStep from './TwoStep/TwoStep';

export function PromoCode() {
  useEffect(() => {
    dispatch(actions.getPromoCodes());
  }, []);

  const [step, setStep] = useState(1);
  const {
    company,
    dispatch,
    formatMessage,
    promoCodeData,
    promoCodesDataIsLoading,
    promoCodesError,
    promoCodesOrderData,
    promoCodesOrderIsLoading,
    purchasePaymentResult,
  } = useGetPromoCodeData();

  return (
    <>
      <Title heading={<FormattedMessage {...messages.subscriptionPROMO_CODETitle} />} />
      <Stepper activeStep={step} steps={getSubscriptionStepsIntl(formatMessage)} />
      <Paper sx={styles.root}>
        {promoCodesError ? (
          <ErrorAlertBox />
        ) : (
          <>
            {(step === 1 || !promoCodesOrderData?.purchaseOrder?.orderContent?.length) &&
              (!promoCodesDataIsLoading && promoCodeData?.prices ? (
                <OneStep
                  canBeInvoiced={promoCodeData?.canBeInvoiced}
                  currency={promoCodeData?.currency}
                  onStepChange={setStep}
                  orderContent={promoCodesOrderData?.purchaseOrder?.orderContent}
                  prices={promoCodeData?.prices}
                  promoCodesOrderIsLoading={promoCodesOrderIsLoading}
                />
              ) : (
                <Loader alignItems="center" size={60} />
              ))}
            {step === 2 && promoCodesOrderData?.purchaseOrder?.orderContent.length > 0 && (
              <TwoStep
                canBeInvoiced={promoCodeData?.canBeInvoiced}
                company={company}
                creditLimit={promoCodeData?.creditLimit}
                currency={promoCodeData?.currency}
                isLoading={promoCodesDataIsLoading}
                onStepChange={setStep}
                orderContent={promoCodesOrderData.purchaseOrder.orderContent}
                paymentLocation={promoCodesOrderData.location}
                promoCodesOrder={promoCodesOrderData.purchaseOrder}
                purchasePaymentResult={purchasePaymentResult}
              />
            )}
            {step === 3 && (
              <ThreeStep
                paymentMethod={promoCodesOrderData.purchaseOrder.paymentMethod}
                purchasePaymentResult={purchasePaymentResult}
                subscription={TYPE.PROMO_CODE}
              />
            )}
          </>
        )}
      </Paper>
    </>
  );
}
