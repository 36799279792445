import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import NoRowsOverlayDataGrid from '../DataGrid/NoRowsOverlay';
import messages from './messages';

interface INoRowsSearchOverlay {
  onClearSearch: () => void;
  searchText?: string;
}

export function NoRowsSearchOverlay({ onClearSearch, searchText = '' }: INoRowsSearchOverlay) {
  return (
    <NoRowsOverlayDataGrid
      action={
        <Button color="primary" onClick={onClearSearch} startIcon={<CloseIcon />} sx={{ mt: 3 }} variant="outlined">
          <FormattedMessage {...messages.sitesDataGridNoRowsSearchOverlayClearSearch} />
        </Button>
      }
      headerText={<FormattedMessage {...messages.sitesDataGridNoRowsSearchOverlayHeaderText} />}
      text={
        <FormattedMessage
          {...messages.sitesDataGridNoRowsSearchOverlayText}
          values={{
            br: <br />,
            searchText,
          }}
        />
      }
    />
  );
}
