import { yupResolver } from '@hookform/resolvers/yup';
import DoneIcon from '@mui/icons-material/Done';
import { Button, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useGetSiteQuery } from '../../../apiRtk/be4feApi';
import { SiteGsmServiceDto, useUpdateSiteGsmServiceMutation } from '../../../apiRtk/coreApi';
import { Alert } from '../../../components';
import Checkbox from '../../../components/HookFormFields/Checkbox';
import { SITE_SERVICE } from '../../../config/sites';
import { useGetLoadedParams } from '../../../hooks/paramsHooks';
import { useModal } from '../../../hooks/useModal';
import { TYPE_DESCRIPTOR } from '../../../modules/notifications';
import { addSnackbarError, addSnackbarSuccess } from '../../../modules/notifications/actions';
import { loadSite } from '../../../modules/sites/actions';
import styles from '../styles';
import messages from './messages';
import { ShowGsmDiagramModal as ShowGsmDiagramModalComponent } from './ShowGsmDiagramModal';
import { getDefaultValues, getValidationSchema } from './validationSchema';

function Calling() {
  const dispatch = useDispatch();
  const { companyId, siteId } = useGetLoadedParams();
  const { data: siteData, isLoading: siteDataIsLoading } = useGetSiteQuery({
    companyId: companyId!,
    siteId: siteId!,
  });
  const { Modal: ShowGsmDiagramModal, onOpen: openShowGsmDiagramModal } = useModal({
    Modal: ShowGsmDiagramModalComponent,
  });

  const [updateSiteGsmService, { isLoading: updateSiteGsmServiceIsLoading }] = useUpdateSiteGsmServiceMutation();

  const formMethods = useForm({
    defaultValues: getDefaultValues(siteData),
    mode: 'onChange',
    resolver: yupResolver(getValidationSchema()),
  });

  function handleSubmit() {
    const formValues = formMethods.getValues();

    const siteGsmServiceDto: SiteGsmServiceDto = {
      active: formValues.gsmActive,
      type: SITE_SERVICE.GSM_CALLS,
    };

    updateSiteGsmService({
      companyId: companyId!.toString(),
      siteGsmServiceDto,
      siteId: siteId!.toString(),
    })
      .unwrap()
      .then(() => {
        dispatch(addSnackbarSuccess({ ...messages.siteSettingsCallingMsgSucc }));
        dispatch(loadSite(companyId, siteId));
      })
      .catch(() => {
        dispatch(addSnackbarError({ ...messages.siteSettingsCallingMsgError }, TYPE_DESCRIPTOR));
      });
  }

  useEffect(() => {
    if (siteData) {
      formMethods.reset(getDefaultValues(siteData));
    }
  }, [siteData]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <Paper sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item lg={4} md={12} xs={12}>
              <Typography fontWeight={700} mb={2}>
                <FormattedMessage {...messages.siteSettingsCallingTitle1} />
              </Typography>
              <Typography mb={2}>
                <FormattedMessage
                  {...messages.siteSettingsCallingText1}
                  values={{
                    url: (chunks) => (
                      <Button
                        color="primary"
                        disableRipple
                        onClick={() => openShowGsmDiagramModal()}
                        sx={{
                          '&:hover': {
                            background: 'none',
                            color: (theme) => theme.palette.primary.main,
                            textDecoration: 'underline',
                          },
                          color: (theme) => theme.palette.primary.light,
                          fontWeight: 400,
                          minWidth: 'auto',
                          p: 0,
                          textDecoration: 'underline',
                          textDecorationColor: (theme) => `${theme.palette.primary.light}40`,
                          textTransform: 'none',
                        }}
                      >
                        {chunks}
                      </Button>
                    ),
                  }}
                />
              </Typography>
              {!siteDataIsLoading && (
                <Checkbox
                  name="gsmActive"
                  label={
                    <Typography>
                      <FormattedMessage {...messages.siteSettingsCallingAllowCallingLabel} />
                    </Typography>
                  }
                />
              )}
              <Alert severity="info" variant="standard">
                <FormattedMessage {...messages.siteSettingsCallingAlert} />
              </Alert>
            </Grid>
            <Grid item lg={4} md={12} xs={12}>
              <Typography fontWeight={700} mb={2}>
                <FormattedMessage {...messages.siteSettingsCallingTitle2} />
              </Typography>
              <Typography>
                <FormattedMessage
                  {...messages.siteSettingsCallingText2}
                  values={{
                    li: (chunks) => <li>{chunks}</li>,
                    ul: (chunks) => <ul>{chunks}</ul>,
                  }}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} sx={styles.buttons}>
              <Button color="secondary" onClick={() => formMethods.reset()}>
                <FormattedMessage {...messages.siteSettingsCallingCancel} />
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={
                  !formMethods.formState.isValid || !formMethods.formState.isDirty || updateSiteGsmServiceIsLoading
                }
                startIcon={<DoneIcon />}
                variant="contained"
              >
                <FormattedMessage {...messages.siteSettingsCallingSubmit} />
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
      <ShowGsmDiagramModal />
    </FormProvider>
  );
}

export default Calling;
