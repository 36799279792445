import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { CURRENCY, PRICING } from '../../config/consts';
import { SITE_TYPE } from '../../config/sites';
import { LICENSE } from '../../config/subscription';
import { useSiteContext } from '../../context/useSiteContext';
import Alert from '../Alert';
import Currency from '../Currency';
import messages from './messages';
import { styles } from './styles';

const PricesInfo = ({ apartmentDeviceLimit, combinedMultiplier, currency, prices, siteType }) => {
  const messageValues = {
    text: (chunks) => (
      <Typography gutterBottom variant="body2">
        {chunks}
      </Typography>
    ),
    title: (chunks) => (
      <Typography gutterBottom variant="subtitle2">
        {chunks}
      </Typography>
    ),
  };

  const pricesValues = (price) => ({
    b: (chunks) => (
      <Typography color="primary" component="p" display="inline" variant="h4">
        {chunks}
      </Typography>
    ),
    price: <Currency currency={currency} price={price} />,
    small: (chunks) => (
      <Typography color="primary" component="p" display="inline">
        {chunks}
      </Typography>
    ),
  });

  const isBillingTypeApartment = useSiteContext().hasSiteBillingType('PER_APARTMENT');

  const pricingMessage = isBillingTypeApartment ? messages.pricesPricePerApartment : messages.pricesPricePerDevice;
  const examplePriceMessage = isBillingTypeApartment
    ? messages.pricesExamplePricePerApartment
    : messages.pricesExamplePricePerDevice;

  const getSiteType = () => (isBillingTypeApartment ? PRICING.PRICE_PER_APARTMENT : PRICING.PRICE_PER_DEVICE);

  const priceType = useMemo(() => {
    if (isBillingTypeApartment) {
      return 'APARTMENT_MOBILE_VIDEO';
    }
    return 'MOBILE_VIDEO';
  }, [siteType]);

  return (
    <>
      <Typography gutterBottom variant="body2">
        <FormattedMessage
          values={{ sup: (chunks) => <sup>{chunks}</sup> }}
          {...(isBillingTypeApartment ? messages.pricesIntroductionMdu : messages.pricesIntroductionNonMdu)}
        />
      </Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item sm={6} xs={12}>
          <Card sx={styles.card}>
            <CardContent>
              <Typography component="h6" display="block" gutterBottom sx={styles.uppercase}>
                <FormattedMessage {...messages.pricesMonthlySubscription} />
              </Typography>
              <FormattedMessage
                {...pricingMessage}
                values={pricesValues(prices?.[`${priceType}_MONTH`]?.unitFullPrice)}
              />

              <Typography color="secondary" component="p" variant="body2">
                <FormattedMessage
                  {...messages.pricesPriceInclVat}
                  values={{
                    price: <Currency currency={currency} price={prices?.[`${priceType}_MONTH`]?.unitFullPriceVat} />,
                  }}
                />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Card sx={styles.card}>
            <CardContent>
              <Typography component="h6" display="block" gutterBottom sx={styles.uppercase}>
                <FormattedMessage {...messages.pricesAnnualSubscription} />
              </Typography>
              <FormattedMessage
                {...pricingMessage}
                values={pricesValues(prices?.[`${priceType}_YEAR`]?.unitFullPrice)}
              />
              <Typography color="secondary" component="p" variant="body2">
                <FormattedMessage
                  {...messages.pricesPriceInclVat}
                  values={{
                    price: <Currency currency={currency} price={prices?.[`${priceType}_YEAR`]?.unitFullPriceVat} />,
                  }}
                />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Alert icon={<HelpOutlineIcon />} severity="info">
        <FormattedMessage
          {...examplePriceMessage}
          values={{
            deviceLimit: apartmentDeviceLimit,
            price: (
              <Currency
                currency={currency}
                price={
                  getSiteType() === PRICING.PRICE_PER_APARTMENT
                    ? 10 * prices?.[LICENSE.APARTMENT_MOBILE_VIDEO_MONTH]?.unitFullPrice
                    : prices?.[LICENSE.MOBILE_VIDEO_MONTH]?.unitFullPrice
                }
              />
            ),
            priceVat: (
              <Currency
                currency={currency}
                price={
                  getSiteType() === PRICING.PRICE_PER_APARTMENT
                    ? 10 * prices?.[LICENSE.APARTMENT_MOBILE_VIDEO_MONTH]?.unitFullPriceVat
                    : prices?.[LICENSE.MOBILE_VIDEO_MONTH]?.unitFullPriceVat
                }
              />
            ),
          }}
        />
      </Alert>
      <Typography component="p" sx={{ mt: 2 }} variant="caption">
        <FormattedMessage {...messages.pricesSubscriptionExplanation} />
      </Typography>
      <FormattedMessage
        {...(isBillingTypeApartment ? messages.pricesCreditPoolSystemMdu : messages.pricesCreditPoolSystemNonMdu)}
        values={{
          ...messageValues,
          credits: combinedMultiplier,
          strong: (chunks) => <strong>{chunks}</strong>,
        }}
      />
      <FormattedMessage {...messages.pricesNotEnoughCredits} values={messageValues} />
      <FormattedMessage {...messages.pricesAnnualSubscriptionCreditPool} values={messageValues} />
      <FormattedMessage {...messages.pricesMonthlySubscriptionCreditPool} values={messageValues} />
      <FormattedMessage {...messages.pricesAddAnotherDevice} values={messageValues} />
      <FormattedMessage {...messages.pricesRemoveDevice} values={messageValues} />
      <FormattedMessage {...messages.pricesReplaceDevice} values={messageValues} />
      <FormattedMessage {...messages.pricesPaymentMehtod} values={messageValues} />
      <FormattedMessage {...messages.pricesRecuringPaymentTermination} values={messageValues} />
    </>
  );
};

PricesInfo.propTypes = {
  apartmentDeviceLimit: PropTypes.number.isRequired,
  combinedMultiplier: PropTypes.number.isRequired,
  currency: PropTypes.string,
  prices: PropTypes.shape({
    [LICENSE.MOBILE_VIDEO_MONTH]: PropTypes.shape({
      chargeVat: PropTypes.bool,
      unitFullPrice: PropTypes.number,
      unitFullPriceVat: PropTypes.number,
    }),
    [LICENSE.MOBILE_VIDEO_YEAR]: PropTypes.shape({
      chargeVat: PropTypes.bool,
      unitFullPrice: PropTypes.number,
      unitFullPriceVat: PropTypes.number,
    }),
    [LICENSE.APARTMENT_MOBILE_VIDEO_MONTH]: PropTypes.shape({
      chargeVat: PropTypes.bool,
      unitFullPrice: PropTypes.number,
      unitFullPriceVat: PropTypes.number,
    }),
    [LICENSE.APARTMENT_MOBILE_VIDEO_YEAR]: PropTypes.shape({
      chargeVat: PropTypes.bool,
      unitFullPrice: PropTypes.number,
      unitFullPriceVat: PropTypes.number,
    }),
  }).isRequired,
  siteType: PropTypes.string.isRequired,
};

PricesInfo.defaultProps = {
  currency: CURRENCY.EUR,
};

export default PricesInfo;
