import { all, call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { PATCH_OPERATIONS } from '../../api';
import * as Api from '../../api/userApi';
import * as UserApiTS from '../../api/userApiTS';
import { ROLES } from '../../config/consts';
import { types as infraTypes } from '../../modules/infrastructure/types';
import apartmentActions, { selectors as apartmentSelectors, types as apartmentTypes } from '../apartments';
import { addApartment } from '../apartments/actions';
import * as apartmentHelpers from '../apartments/helpers';
import { Roles, RolesType } from '../commonTypes';
import { selectors as companySelectors } from '../company';
import { errorHelper, handleErrorLocation, responseValidateStatusOrThrow } from '../helpers';
import { LOCALE_PATH } from '../localization/helpers';
import { types as localizationTypes } from '../localization/types';
import { addSnackbarSuccess } from '../notifications/actions';
import { selectors as siteSelectors } from '../sites';
import { EMAIL_PATH, FIRST_NAME_PATH, LAST_NAME_PATH } from './helpers';
import { getRedirectPathForDeletedUser } from './helpersTS';
import actions, { helpers, helpersTS } from './index';
import messages from './messages';
import types, { CONTEXT } from './types';

export function* getCurrentUser() {
  try {
    const response = yield call(Api.getUser, 'current');
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({ payload: data, type: types.GET_CURRENT_USER_SUCCESS });
    yield put({ payload: data?.locale, type: localizationTypes.GET_CURRENT_USER_LANGUAGE_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.GET_CURRENT_USER_FAILURE, error));
  }
}

export function* getUsersList(action) {
  const { companyId, context, filter, order, page, params, rowsPerPage, siteId } = action;
  try {
    let response;

    switch (context) {
      case CONTEXT.SITE_USERS:
        response = yield call(Api.getSiteUsers, companyId, siteId, params);
        break;
      case CONTEXT.COMPANY_ADMINS:
        params.role = ROLES.COMPANY_ADMIN;
        response = yield call(Api.getCompanyUsers, companyId, siteId, params);
        break;
      default:
        throw new Error('No supported context.');
    }

    yield responseValidateStatusOrThrow(response);
    const { results, resultsFiltered, resultsTotal } = response.data;
    yield put({
      context,
      filter,
      order,
      page,
      payload: {
        data: results.map((user) => {
          const company = user?.companies?.find((item) => item.id === companyId);
          const site = company?.sites?.find((item) => item.id === siteId);
          return {
            ...user,
            role: helpers.getFeUserRoles(site?.roles || [], company?.roles || []),
          };
        }),
        resultsFiltered,
        resultsTotal,
      },
      rowsPerPage,
      type: types.GET_USERS_LIST_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_USERS_LIST_FAILURE, error, context));
  }
}

// eslint-disable-next-line max-statements
export function* deleteUserRequest({ context, user }) {
  try {
    const company = yield select(companySelectors.getCompanyData);
    const site = yield select(siteSelectors.getSiteData);
    const apartment = yield select(apartmentSelectors.getApartmentData);
    const currentSiteId = site.id;
    const currentCompanyId = company.id;
    const currentCompany = user?.companies?.find((item) => item.id === currentCompanyId);
    const currentSite = currentCompany?.sites?.find((item) => item.id === currentSiteId);
    const companyRoles = currentCompany?.roles || [];
    const siteRoles = currentSite?.roles || [];
    let response;
    const values = {
      grantedRoles: {
        companyRoles: [],
        siteRoles: [],
      },
      revokedRoles: {
        companyRoles: [],
        siteRoles: [],
      },
    };

    if (context === CONTEXT.SITE_USERS) {
      values.revokedRoles.siteRoles.push({
        siteId: site.id,
        types: siteRoles,
      });
      response = yield call(Api.deleteUser, user.id, values);
    } else if (context === CONTEXT.COMPANY_ADMINS) {
      values.revokedRoles.companyRoles.push({
        companyId: company.id,
        types: companyRoles,
      });
      response = yield call(Api.deleteUser, user.id, values);
    } else {
      throw new Error('No supported context.');
    }

    yield responseValidateStatusOrThrow(response);
    yield put(addSnackbarSuccess(messages.userSnackbarRemovedSuccessfully));
    yield put({ context, type: types.DELETE_USER_SUCCESS });
    yield put({
      payload: {
        to: getRedirectPathForDeletedUser(company.id, site?.id, apartment?.id),
      },
      type: infraTypes.SET_REDIRECT_DATA,
    });
  } catch (error) {
    yield put(errorHelper(types.DELETE_USER_FAILURE, error, context));
  } finally {
    yield put(actions.deleteUserHideModal());
  }
}

export function* detailUserRequest(action) {
  const { companyId, context, siteId = null, userId } = action;
  try {
    let response;

    if (context === CONTEXT.SITE_USERS) {
      response = yield call(UserApiTS.getSiteUserDetail, { companyId, siteId }, userId);
    } else if (context === CONTEXT.COMPANY_ADMINS) {
      response = yield call(Api.getCompanyAdminDetail, companyId, userId);
    } else {
      throw new Error('No supported context.');
    }

    yield responseValidateStatusOrThrow(response);
    yield put({
      context,
      payload: response.data,
      type: types.DETAIL_USER_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.DETAIL_USER_FAILURE, error, context));
    if (handleErrorLocation(error?.status)) {
      yield put({
        payload: {
          locationSubRoutesCheck: ['/users', '/admins'],
        },
        type: infraTypes.SET_REDIRECT_DATA,
      });
    }
  }
}

// eslint-disable-next-line max-statements
export function* setApartmentToUser({ apartments, newApartment, toast, userId }) {
  const companyId = yield select(companySelectors.getCompanyId);
  const siteId = yield select(siteSelectors.getSiteId);
  const floors = yield select(apartmentSelectors.getFloors);

  try {
    let addFloorResponse;
    if (newApartment) {
      yield put(addApartment(companyId, siteId, floors, newApartment, false));
      addFloorResponse = yield take([apartmentTypes.ADD_APARTMENT_SUCCESS]);
    }

    const addResponses = yield all(
      [...(addFloorResponse?.payload?.id ? [addFloorResponse?.payload?.id] : []), ...apartments.added].map(
        (apartmentId) =>
          call(
            Api.setRole,
            userId,
            apartmentHelpers.apartmentUserHandler(apartmentId, RolesType.GRANTED_ROLES, [Roles.RESIDENT])
          )
      )
    );

    const removeResponses = yield all(
      apartments.removed.map((apartmentId) =>
        call(
          Api.setRole,
          userId,
          apartmentHelpers.apartmentUserHandler(apartmentId, RolesType.REVOKED_ROLES, [Roles.RESIDENT])
        )
      )
    );

    for (const response of [...addResponses, ...removeResponses]) {
      yield responseValidateStatusOrThrow(response);
    }

    yield put({ type: types.SET_APARTMENT_TO_USER_SUCCESS });
    if (toast) {
      yield put(addSnackbarSuccess(messages.userSnackbarApartmentsSetSuccessfully));
    }
  } catch (error) {
    yield put(errorHelper(types.SET_APARTMENT_TO_USER_FAILURE, error));
  }
}

export function* revokeUserPinRequest({ userId }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);
    const response = yield call(Api.revokeUserPin, companyId, siteId, userId);
    yield responseValidateStatusOrThrow(response);
    yield put(addSnackbarSuccess(messages.userSnackbarPinRemovedSuccessfully));
    yield put({ type: types.REVOKE_USER_PIN_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.REVOKE_USER_PIN_FAILURE, error));
  }
}

export function* removeUserRfidCardRequest({ companyId, rfidId, siteId, userId }) {
  try {
    const response = yield call(Api.removeUserRfidCard, companyId, siteId, userId, rfidId);
    yield responseValidateStatusOrThrow(response);
    yield put(addSnackbarSuccess(messages.userSnackbarRfidCardRemovedSuccessfully));
    yield put({ type: types.REMOVE_USER_RFID_CARD_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.REMOVE_USER_RFID_CARD_FAILURE, error));
  }
}

export function* createUser({ context, formActions, grantedRoles, values }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);
    let response;
    if (context !== CONTEXT.COMPANY_ADMINS) {
      const data = {
        apartments:
          values.apartment[0]?.id !== values.newApartmentField
            ? values.apartment.flatMap((apartment) => apartment.id)
            : [],
        ...(values.email !== '' && { email: values.email }),
        ...(values.firstName !== '' && { firstName: values.firstName }),
        gsmCalls: values.gsmCalls,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        role: grantedRoles,
      };

      response = yield call(UserApiTS.createUser, { companyId, siteId }, data);
      yield responseValidateStatusOrThrow(response);
    } else {
      const data = {
        email: values.email,
        firstName: values.firstName,
        grantedRoles: {
          companyRoles: [
            {
              companyId,
              types: grantedRoles.companyRoles,
            },
          ],
          systemRoles: [],
        },
        gsmCalls: values.gsmCalls,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
      };

      response = yield call(Api.createUser, data);
      yield responseValidateStatusOrThrow(response);
    }
    if (values.apartment !== undefined && values.apartment.length > 0) {
      for (let i = 0; i < values.apartment.length; i++) {
        let apartmentId = values.apartment[i].id;
        if (apartmentId === values.newApartmentField) {
          const floors = yield select(apartmentSelectors.getFloors);
          yield put(
            apartmentActions.addApartment(
              companyId,
              siteId,
              floors,
              { floor: values.floor, name: values.name, number: values.number },
              false
            )
          );

          const addApartmentResponse = yield take([apartmentTypes.ADD_APARTMENT_SUCCESS]);
          apartmentId = addApartmentResponse.payload.id;
        }

        yield call(
          Api.setRole,
          response.data.id,
          apartmentHelpers.apartmentUserHandler(apartmentId, RolesType.GRANTED_ROLES, [Roles.RESIDENT])
        );
      }
    }
    yield put({ context, type: types.USER_SAVE_SUCCESS });

    yield put(addSnackbarSuccess(messages.userSnackbarDataSavedSuccessfully));
    if (Object.keys(formActions).length) {
      yield formActions.resetForm();
    }
  } catch (error) {
    yield put(errorHelper(types.USER_SAVE_FAILURE, error));
  } finally {
    if (Object.keys(formActions).length) {
      yield formActions.setSubmitting(false);
    }
  }
}

// eslint-disable-next-line max-statements
export function* editUser({ context, formActions, isCurrentUser, userId, values }) {
  try {
    let response;
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);
    const { apartments, id, newApartment } = values;

    if (apartments !== undefined && (apartments.added.length || apartments.removed.length || newApartment)) {
      yield call(setApartmentToUser, { apartments, newApartment, userId: id });
      yield put(apartmentActions.getApartmentsList(companyId, siteId));
    }

    const personalData = [
      { op: PATCH_OPERATIONS.REPLACE, path: FIRST_NAME_PATH, value: values.firstName },
      { op: PATCH_OPERATIONS.REPLACE, path: LAST_NAME_PATH, value: values.lastName },
      { op: PATCH_OPERATIONS.REPLACE, path: EMAIL_PATH, value: values.email },
      ...(values?.lang ? [{ op: PATCH_OPERATIONS.REPLACE, path: LOCALE_PATH, value: values.lang }] : []),
    ];

    if (isCurrentUser || context === CONTEXT.CURRENT_USER) {
      response = yield call(Api.updateProfilePatch, personalData);
      yield responseValidateStatusOrThrow(response);
      const { data } = response;
      yield put({ payload: data, type: types.SET_USER_DETAIL });
      yield put({ payload: data, type: types.SET_IDENTITY });
      yield put({ payload: data?.locale, type: localizationTypes.GET_CURRENT_USER_LANGUAGE_SUCCESS });
    } else {
      switch (context) {
        case CONTEXT.COMPANY_ADMINS:
          response = yield call(Api.updateAdminUserPatch, companyId, values.id ?? userId, personalData);
          break;

        case CONTEXT.SITE_USERS:
          response = yield call(Api.updateSiteUserPatch, companyId, siteId, values.id, personalData);
          break;

        default:
          throw new Error('No supported context.');
      }
      yield responseValidateStatusOrThrow(response);
      const { data } = response;
      yield put({ context, payload: data, type: types.SET_USER_DETAIL });
    }

    yield put(addSnackbarSuccess(messages.userSnackbarDataSavedSuccessfully));
    if (Object.keys(formActions).length) {
      yield formActions.resetForm();
    }
  } catch (error) {
    yield put(errorHelper(types.USER_SAVE_FAILURE, error));
  } finally {
    if (Object.keys(formActions).length) {
      yield formActions.setSubmitting(false);
    }
  }
}

export function* createSiteUser(newUser) {
  const companyId = yield select(companySelectors.getCompanyId);
  const siteId = yield select(siteSelectors.getSiteId);

  let response;
  try {
    response = yield call(Api.createUser, helpersTS.newSiteUserCreator({ companyId, newUser, siteId }));
    yield responseValidateStatusOrThrow(response);
  } catch (error) {
    // ignore
  }

  return response;
}

export function* setSiteUserRole({ data, userId }) {
  try {
    const response = yield call(Api.setRole, userId, data);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.SET_USER_ROLE_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.SET_USER_ROLE_FAILURE, error));
  }
}

export function* getImportUsersStatusRequest({ companyId, siteId }) {
  try {
    const response = yield call(Api.getImportUsersStatus, companyId, siteId);
    yield responseValidateStatusOrThrow(response);
    yield put({
      data: response.data,
      type: types.IMPORT_USERS_GET_STATUS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.IMPORT_USERS_GET_STATUS_FAILURE,
    });
  }
}

export function* importUsersStartRequest({ companyId, data, failedCallback, siteId, successCallback }) {
  try {
    const responseImport = yield call(Api.importUsersStart, companyId, siteId, data);
    yield responseValidateStatusOrThrow(responseImport);
    yield put({
      type: types.IMPORT_USERS_START_SUCCESS,
    });

    const responseStatus = yield call(Api.getImportUsersStatus, companyId, siteId);
    yield responseValidateStatusOrThrow(responseStatus);
    yield put({
      data: responseStatus.data,
      type: types.IMPORT_USERS_GET_STATUS_SUCCESS,
    });
    successCallback();
  } catch (error) {
    if (error.status === 409) {
      failedCallback();
    }
    yield put({
      type: types.IMPORT_USERS_START_FAILURE,
    });
  }
}

export function* importUsersAbortRequest({ companyId, siteId }) {
  try {
    const response = yield call(Api.importUsersAbort, companyId, siteId);
    yield responseValidateStatusOrThrow(response);
    yield put({
      type: types.IMPORT_USERS_ABORT_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.IMPORT_USERS_ABORT_FAILURE,
    });
  }
}

export function* getSiteUserDevices(action) {
  const { companyId, filter, order, page, params, rowsPerPage, siteId, userId } = action;
  try {
    const response = yield call(Api.getSiteUserDevicesRequest, companyId, siteId, userId, params);
    yield responseValidateStatusOrThrow(response);
    yield put({
      filter,
      order,
      page,
      payload: response.data,
      rowsPerPage,
      type: types.GET_SITE_USER_DEVICES_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_SITE_USER_DEVICES_FAILURE, error));
  }
}

export default function* userSagas() {
  yield all([
    takeLatest(types.GET_USERS_LIST_REQUEST, getUsersList),
    takeEvery(types.DELETE_USER_REQUEST, deleteUserRequest),
    takeEvery(types.DETAIL_USER_REQUEST, detailUserRequest),
    takeEvery(types.CREATE_USER_REQUEST, createUser),
    takeLatest(types.EDIT_USER_REQUEST, editUser),
    takeEvery(types.GET_CURRENT_USER_REQUEST, getCurrentUser),
    takeLatest(types.GET_SITE_USER_DEVICES_REQUEST, getSiteUserDevices),
    takeLatest(types.SET_APARTMENT_TO_USER_REQUEST, setApartmentToUser),
    takeEvery(types.REVOKE_USER_PIN_REQUEST, revokeUserPinRequest),
    takeEvery(types.REMOVE_USER_RFID_CARD_REQUEST, removeUserRfidCardRequest),
    takeEvery(types.IMPORT_USERS_GET_STATUS_REQUEST, getImportUsersStatusRequest),
    takeEvery(types.IMPORT_USERS_START_REQUEST, importUsersStartRequest),
    takeEvery(types.IMPORT_USERS_ABORT_REQUEST, importUsersAbortRequest),
    takeLatest(types.SET_USER_ROLE_REQUEST, setSiteUserRole),
  ]);
}
