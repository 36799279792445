import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React from 'react';
import LoadingButton from '../LoadingButton';

const styles = {
  root: {
    '& button:not(:first-of-type)': {
      marginLeft: 1,
    },
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 2,
  },
};

export interface IFormAction {
  disabled: boolean;
  onClick?: () => void;
  onPrevStep?: () => void;
  prevStepText?: React.ReactNode;
  submitLoading?: boolean;
  submitStartIcon?: React.ReactNode;
  submitText: React.ReactNode;
  type: 'submit' | 'button';
}

export function FormAction({
  disabled,
  onClick,
  onPrevStep,
  prevStepText,
  submitLoading,
  submitStartIcon,
  submitText,
  type,
}: IFormAction) {
  return (
    <Box sx={styles.root}>
      {prevStepText && onPrevStep && (
        <Button onClick={onPrevStep} type="button">
          {prevStepText}
        </Button>
      )}
      <LoadingButton
        color="primary"
        disabled={disabled}
        id="nextstep-button"
        isLoading={submitLoading}
        onClick={onClick || null}
        startIcon={submitStartIcon}
        type={type}
        variant="contained"
      >
        {submitText}
      </LoadingButton>
    </Box>
  );
}
