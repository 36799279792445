import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React from 'react';
import { TDevicesControlValues } from '../types';

const styles = {
  root: (theme: Theme) => ({
    '& .MuiButton-startIcon': {
      '& > *:first-of-type': {
        fontSize: theme.typography.fontSize * 5,
      },
      '& span::before': {
        display: 'unset',
      },
      color: 'secondary.main',
      marginLeft: 0,
      marginRight: 0,
    },
    '&:hover': {
      '& .MuiButton-startIcon, & span': {
        color: 'primary.main',
      },
      borderColor: 'primary.main',
    },
    borderColor: 'secondary.main',
    borderRadius: 0,
    height: '120px',
    mb: 5,
    mt: 3,
    position: 'relative',
    textAlign: 'center',
    textTransform: 'none',
    width: '120px',
  }),
  title: {
    lineHeight: '1.5rem',
    paddingTop: '30px',
    position: 'absolute',
    top: '95px',
  },
};

interface IDeviceProps {
  icon: React.ReactElement;
  onSelectDevice: (value: TDevicesControlValues) => void;
  title: React.ReactElement;
  value: TDevicesControlValues;
}

export function Device({ icon, onSelectDevice, title, value }: IDeviceProps) {
  return (
    <Button onClick={() => onSelectDevice(value)} startIcon={icon} sx={styles.root} variant="outlined">
      <Box component="span" sx={styles.title}>
        {title}
      </Box>
    </Button>
  );
}
