import AddIcon from '@mui/icons-material/Add';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import InfoIcon from '@mui/icons-material/Info';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, ApartmentDataGrid, DataGridActionButton, DataGridSearchField, Title } from '../../components';
import { SERVICE } from '../../config/devices';
import { SITE_TYPE } from '../../config/sites';
import { changeFilter, getDataGridSelector, useButtonText } from '../../helpers/dataGrid';
import useBreakpoints from '../../helpers/useBreakpoints';
import { useGetLoadedParams } from '../../hooks/paramsHooks';
import { useModal } from '../../hooks/useModal';
import actions, { NAME as APARTMENTS_MODULE, selectors } from '../../modules/apartments';
import { IFloor, INewApartment } from '../../modules/apartments/store';
import commonActions from '../../modules/common';
import { ApplicationContext, TId } from '../../modules/commonTypes';
import { selectors as sitesSelectors } from '../../modules/sites';
import ApartmentCreateDialog from './ApartmentCreateModal';
import { getFreeApartmentAlertMessage, isFreeApartmentAlertVisible } from './helpers';
import messages from './messages';

export function ApartmentsOverview() {
  const apartmentsTable = useSelector((state) => getDataGridSelector(state, APARTMENTS_MODULE, 'list'));
  const didInvalidFloors = useSelector(selectors.getDidInvalidFloors);
  const floors = useSelector(selectors.getFloors);
  const site = useSelector(sitesSelectors.getSiteData);

  const { companyId, siteId } = useGetLoadedParams(ApplicationContext.Site);
  const { downSm } = useBreakpoints();
  const dispatch = useDispatch();

  const [fulltext, setFulltext] = useState(apartmentsTable.filter.fulltext || '');
  const [showWarning, setShowWarning] = useState(true);

  useEffect(() => {
    dispatch(actions.getSiteFloorsList(companyId, siteId));
  }, [didInvalidFloors]);

  const onChangeFilter = ({ target }: React.ChangeEvent<{ name: string; value: string }>) => {
    const { name, value } = target;
    setFulltext(value);
    changeFilter(
      (onLoad: () => void, params: object[]) => dispatch(commonActions.search(onLoad, params)),
      actions.getSiteApartmentsList,
      {
        ...apartmentsTable,
        companyId,
        siteId,
      }
    )(name, value);
  };

  const handleOpenCreateModal = () => {
    dispatch(actions.getSiteFloorsList(companyId, siteId));
    onOpenApartmentCreateModal();
  };

  const { Modal: ApartmentCreateModal, onOpen: onOpenApartmentCreateModal } = useModal({
    Modal: ApartmentCreateDialog,
  });

  const licenceModel = site.services[SERVICE.MOBILE_VIDEO].licenceModel;
  const alertMessage = useMemo(() => {
    return getFreeApartmentAlertMessage(licenceModel);
  }, [licenceModel]);
  const isFreeApartmentAlertEnabled = isFreeApartmentAlertVisible(licenceModel, site.type);

  return (
    <>
      <Title
        heading={<FormattedMessage {...messages.apartmentsOverviewTitle} />}
        rightSide={<DataGridSearchField fulltext={fulltext} onChangeFilter={onChangeFilter} />}
        rightSideActions={
          <DataGridActionButton
            onClick={handleOpenCreateModal}
            startIcon={downSm ? <CreateNewFolderIcon /> : <AddIcon />}
            variant={downSm ? 'text' : 'contained'}
          >
            {useButtonText(messages.apartmentsOverviewCreateApartment, messages.apartmentsOverviewAddApartmentMobile)}
          </DataGridActionButton>
        }
      />
      {site.type === SITE_TYPE.UNKNOWN && showWarning && (
        <Alert onClose={() => setShowWarning(false)} severity="warning">
          <FormattedMessage {...messages.apartmentsOverviewWarning} values={{ limit: site.apartmentDeviceLimit }} />
        </Alert>
      )}
      {isFreeApartmentAlertEnabled && (
        <Alert icon={<InfoIcon />} severity="info">
          <FormattedMessage {...alertMessage?.text} values={{ count: alertMessage?.count }} />
        </Alert>
      )}
      {siteId === site?.id && (
        <ApartmentDataGrid
          {...apartmentsTable}
          companyId={companyId}
          floors={floors}
          onLoadApartments={(
            ...args: [
              companyId: TId,
              siteId: TId,
              page: number,
              rowsPerPage: number,
              filter: { fulltext: string },
              order: string,
            ]
          ) => dispatch(actions.getSiteApartmentsList(...args))}
          site={site}
        />
      )}
      <ApartmentCreateModal
        floors={floors}
        onCreateApartment={(companyId: TId, siteId: TId, floors: Array<IFloor>, values: INewApartment) =>
          dispatch(actions.addApartment(companyId, siteId, floors, values))
        }
      />
    </>
  );
}
