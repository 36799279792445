import PropTypes from 'prop-types';

export const mobileVideoOrderContentPropTypes = PropTypes.shape({
  licences: PropTypes.number,
  percentage: PropTypes.number,
  price: PropTypes.number,
  priceVat: PropTypes.number,
  product: PropTypes.string,
  unitFullPrice: PropTypes.number,
  unitFullPriceVat: PropTypes.number,
});

export const purchasePaymentResultPropTypes = PropTypes.shape({
  paymentResult: PropTypes.string,
  purchaseState: PropTypes.string,
});
