import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { OutlinedTextField } from '../../../../../../components/HookFormFields/TextField';
import { INameProps, maxYears, minYears } from '../../../hooks/types';
import messages from '../messages';

export function NumberOfYears({ name }: INameProps) {
  return (
    <OutlinedTextField
      fullWidth
      id="PromoBatchForm-yearsCount"
      InputLabelProps={{
        required: false,
        shrink: true,
      }}
      inputProps={{
        max: maxYears,
        min: minYears,
      }}
      InputProps={{
        endAdornment: (
          <Typography color="text.secondary">
            <FormattedMessage {...messages.promoBatchFormInputYearsCountAdornment} />
          </Typography>
        ),
      }}
      label={<FormattedMessage {...messages.promoBatchFormInputYearsCountLabel} />}
      margin="dense"
      name={`${name}.years`}
      required
      size="small"
      type="number"
    />
  );
}
