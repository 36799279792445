import PropTypes from 'prop-types';
import { PROMO_TYPES } from './consts';

export const orderItemPropTypes = PropTypes.shape({
  configuration: PropTypes.shape({
    devices: PropTypes.number,
    type: PropTypes.oneOf(Object.keys(PROMO_TYPES)),
    years: PropTypes.number,
  }),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  price: PropTypes.number,
});

export const orderItemConfigurationPropTypes = PropTypes.shape({
  devices: PropTypes.number,
  type: PropTypes.oneOf(Object.keys(PROMO_TYPES)),
  years: PropTypes.number,
});
