import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DeviceTypeLabel, TableFooter } from '../../../components';
import { table } from '../../../config/styles';
import { useDataGrid } from '../../../helpers/dataGrid';
import { DeviceType, TId } from '../../../modules/commonTypes';
import messages from '../messages';

type LoadMembersArgs = [
  companyId: TId,
  siteId: TId,
  page: number,
  rowsPerPage: number,
  filter: { fulltext: string },
  order: string,
];

type LoadMembersAction = {
  companyId: any;
  filter: { fulltext: string };
  order: string;
  page: number;
  params: object;
  rowsPerPage: number;
  siteId: any;
  type: string;
};

interface FieldProps {
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: number[];
}

interface PhonebookMemberItem {
  deviceType: string;
  id: number;
  name: string;
  sipNumber: string;
  type: DeviceType;
  active: boolean;
}

interface PhonebookMembersTableProps {
  data: PhonebookMemberItem[];
  onChangeFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
  field: FieldProps;
  onLoadMembers: (...args: LoadMembersArgs) => LoadMembersAction;
  companyId: number;
  siteId: number;
  filter: Record<string, any>;
  fulltext: string;
  isFetching: boolean;
  didInvalid: boolean;
  order: string;
  page: number;
  resultsFiltered: number;
  rowsPerPage: number;
}

const styles = {
  disabled: {
    opacity: 0.38,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    mb: 2,
  },
  inputSearch: {
    '& input': {
      p: 1.5,
    },
  },
  loading: {
    width: '100%',
  },
  loadingWrapper: {
    '& td': {
      m: 0,
      p: 0,
      width: '100%',
    },
    bgcolor: 'inherit !important',
  },
  noResults: {
    '& td': {
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  table: {
    ...table(),
    '& tr:hover': {
      cursor: 'pointer',
    },
  },
  title: {
    fontSize: '1.25rem',
    fontWight: 400,
    lineHeight: 1.125,
  },
};

function PhonebookMembersTable({
  companyId,
  data,
  didInvalid,
  field,
  filter,
  fulltext,
  isFetching,
  onChangeFilter,
  onLoadMembers,
  order,
  page,
  resultsFiltered,
  rowsPerPage,
  siteId,
}: PhonebookMembersTableProps) {
  const { onRowsPerPageChange, onSelectPage } = useDataGrid(
    onLoadMembers,
    {
      companyId,
      filter,
      order,
      page,
      rowsPerPage,
      siteId,
    },
    didInvalid
  );
  const { formatMessage } = useIntl();

  const handleSetValue = (values: number[]) => {
    const event = {
      target: {
        name: field.name,
        value: values.sort(),
      },
    };
    field.onChange(event as unknown as React.ChangeEvent<HTMLInputElement>);
  };

  const onSelect = (member: { id: number }) => {
    const { id } = member;
    const { value } = field;
    let values = value || [];
    if (value.includes(id)) {
      values = value.filter((item) => item !== id);
    } else {
      values.push(id);
    }
    handleSetValue(values);
  };

  const isAllSelected = () => {
    const { value } = field;
    const memberIds = data.map((item) => item.id).sort();
    const count = value.filter((item) => memberIds.includes(item)).length;
    return data.length !== 0 && data.length === count;
  };

  const onAllSelect = () => {
    const { value } = field;
    let values: number[] = [];
    if (!isAllSelected()) {
      values = Array.from(new Set([...value, ...data.map((item) => item.id)]));
    } else {
      const membersIds = data.map((item) => item.id).sort();
      values = value.filter((item) => !membersIds.includes(item));
    }
    handleSetValue(values);
  };

  const isSelected = (member: { id: number }) => {
    const { id } = member;
    const { value } = field;
    return value.includes(id);
  };

  return (
    <>
      <Box sx={styles.header}>
        <Typography sx={styles.title} variant="h6">
          <FormattedMessage {...messages.phonebookMembersTableTitle} />
        </Typography>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          name="fulltext"
          onChange={onChangeFilter}
          placeholder={formatMessage(messages.phonebookMembersTableSearchPlaceholder)}
          sx={styles.inputSearch}
          type="search"
          value={fulltext}
          variant="outlined"
        />
      </Box>
      <Table size="small" sx={styles.table}>
        <TableHead>
          <TableRow aria-checked={isAllSelected()} hover onClick={() => onAllSelect()} role="checkbox">
            <TableCell padding="checkbox">
              <Checkbox
                checked={isAllSelected()}
                color="primary"
                disabled={data.length === 0}
                inputProps={{ 'aria-label': 'Select all' }}
                onChange={() => onAllSelect()}
              />
            </TableCell>
            <TableCell>
              <FormattedMessage {...messages.phonebookMembersTableColumnsName} />
            </TableCell>
            <TableCell>
              <FormattedMessage {...messages.phonebookMembersTableColumnsDeviceId} />
            </TableCell>
            <TableCell>
              <FormattedMessage {...messages.phonebookMembersTableColumnsType} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isFetching && data.length === 0 && (
            <TableRow sx={styles.noResults}>
              <TableCell colSpan={4}>
                <FormattedMessage {...messages.phonebookMembersTableNoResults} />
              </TableCell>
            </TableRow>
          )}
          {data.map((item: PhonebookMemberItem) => (
            <TableRow
              aria-checked={isSelected(item)}
              hover
              key={item.id}
              onClick={() => onSelect(item)}
              role="checkbox"
              selected={isSelected(item)}
              sx={{ ...(!item.active && styles.disabled) }}
              tabIndex={-1}
            >
              <TableCell padding="checkbox">
                <Checkbox checked={isSelected(item)} color="primary" />
              </TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.sipNumber}</TableCell>
              <TableCell>
                <DeviceTypeLabel type={item.type} deviceType={item.deviceType} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter
          colSpan={4}
          onRowsPerPageChange={onRowsPerPageChange}
          onSelectPage={onSelectPage}
          page={page}
          resultsFiltered={resultsFiltered}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 15]}
        />
      </Table>
    </>
  );
}

export default PhonebookMembersTable;
