import { defineMessages } from 'react-intl';

export default defineMessages({
  ImsLoginErrorIncorrectCredentialsEditProfile: {
    defaultMessage: 'Too many incorrect login attempts. Please, try again or reset your password.',
    id: 'ims.login.error.incorrectCredentials.editProfile',
  },
  ImsNewPasswordInputHelperText: {
    defaultMessage: 'Use at least: 8 characters, 1 uppercase letter, 1 lowercase letter, 1 digit',
    id: 'ims.newPassword.input.helperText',
  },
  ImsNewPasswordSuccess: {
    defaultMessage: 'New password set successfully',
    id: 'ims.newPassword.success',
  },
  SharedLabelCurrentPassword: {
    defaultMessage: 'Current password',
    id: 'shared.label.currentPassword',
  },

  UserChangePasswordDialogImsFormActionsCancel: {
    defaultMessage: 'CANCEL',
    id: 'userChangePasswordDialog.ims.formActions.cancel',
  },
  UserChangePasswordDialogImsFormActionsSubmit: {
    defaultMessage: 'Change password',
    id: 'userChangePasswordDialog.ims.formActions.submit',
  },
  UserChangePasswordDialogImsLabelNewPassword: {
    defaultMessage: 'New password',
    id: 'userChangePasswordDialog.ims.label.newPassword',
  },
  UserChangePasswordDialogImsTitle: {
    defaultMessage: 'Change password',
    id: 'userChangePasswordDialog.ims.title',
  },
  UserChangePasswordDialogImsWarningAppLogout: {
    defaultMessage: `If you're using the My2N mobile app, changing your password will log you out of it.`,
    id: 'userChangePasswordDialog.ims.warningAppLogout',
  },
});
