import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { BillingInfoModal, IconMaterial, Title, VatInfoText } from '../../components';
import { SUPPORT } from '../../config/address';
import { useModal } from '../../hooks/useModal';
import actions from '../../modules/billing';
import { getCountryName } from './helpers';
import messages from './messages';
import { getCompanyDataSelector } from './selectors';
import styles from './styles/BillingInfo';

const BillingInfo = ({ data, isFilled }) => {
  const { Modal: BillingInfoUModal, onOpen: onOpenBillingInfoUModal } = useModal({
    Modal: BillingInfoModal,
  });
  return (
    <>
      <Title heading={<FormattedMessage {...messages.billingInfoTitle} />} />
      <Paper sx={styles.wrapper}>
        <Box sx={styles.header}>
          {data.billingInfoEditable && (
            <Button
              color="primary"
              id="billinginfo-button"
              onClick={onOpenBillingInfoUModal}
              startIcon={<EditIcon />}
              sx={styles.editBillingInfoBtn}
              variant="contained"
            >
              <FormattedMessage {...messages.billingInfoEditBillingInfo} />
            </Button>
          )}
          {isFilled && (
            <Typography variant="h6">
              <IconMaterial name="domain" /> {data.name}
            </Typography>
          )}
          {!isFilled && (
            <Typography>
              <FormattedMessage {...messages.billingInfoPleaseComplete} />
            </Typography>
          )}
        </Box>
        {isFilled && (
          <>
            <Typography sx={styles.marginHeadline} variant="h6">
              <FormattedMessage {...messages.billingInfoAddressTitle} />
            </Typography>
            <Box component="dl" sx={styles.list}>
              <FormattedMessage tagName="dt" {...messages.billingInfoAddressStreet} />
              <dd>{data.address.street}</dd>
              <FormattedMessage tagName="dt" {...messages.billingInfoAddressTitle} />
              <dd>{`${data.address.postalCode} ${data.address.city}`.trim()}</dd>
              <FormattedMessage tagName="dt" {...messages.billingInfoAddressCountry} />
              <dd>{getCountryName(data.address.country)}</dd>
            </Box>
            <Typography sx={styles.marginHeadline} variant="h6">
              <FormattedMessage {...messages.billingInfoCurrencyAndVatTitle} />
            </Typography>
            <Box component="dl" sx={styles.list}>
              <FormattedMessage tagName="dt" {...messages.billingInfoCurrencyAndVatCurrency} />
              <dd>{data.currency}</dd>
              {data.vatId && <FormattedMessage tagName="dt" {...messages.billingInfoCurrencyAndVatVatNumber} />}
              {data.vatId && <dd>{data.vatId}</dd>}
            </Box>
            <VatInfoText />
            {!data.billingInfoEditable && (
              <Typography sx={styles.link}>
                <FormattedMessage {...messages.billingInfoCurrencyAndVatDescription} />
                <Link href={`mailto:${SUPPORT}`} rel="noopener noreferrer" target="_blank">
                  {SUPPORT}
                </Link>
                {'.'}
              </Typography>
            )}
          </>
        )}
      </Paper>
      <BillingInfoUModal />
    </>
  );
};

BillingInfo.propTypes = {
  data: PropTypes.shape({
    address: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
      postalCode: PropTypes.string,
      street: PropTypes.string,
    }),
    billingInfoEditable: PropTypes.bool.isRequired,
    currency: PropTypes.string,
    name: PropTypes.string,
    vatId: PropTypes.string,
  }).isRequired,
  isFilled: PropTypes.bool.isRequired,
  onEditBillingShowModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => getCompanyDataSelector(state);

const mapDispatchToProps = {
  onEditBillingShowModal: actions.editBillingShowModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingInfo);
