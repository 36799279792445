import { defineMessages } from 'react-intl';

export default defineMessages({
  siteSettingsCallingAlert: {
    defaultMessage: `To use 3rd party SIP calls, you’ll need to set up a GSM provider on your own and make sure you have an active contract.`,
    description: 'Calling - alert',
    id: 'siteSettings.calling.alert',
  },
  siteSettingsCallingAllowCallingLabel: {
    defaultMessage: 'Allow GSM calls on this site',
    description: 'Calling - checkbox label',
    id: 'siteSettings.calling.allowCalling.label',
  },
  siteSettingsCallingCancel: {
    defaultMessage: 'Cancel',
    description: 'Calling - cancel button',
    id: 'siteSettings.calling.cancel',
  },
  siteSettingsCallingMsgError: {
    defaultMessage: 'There was an error, please try again',
    description: 'Calling - error message',
    id: 'siteSettings.calling.msgError',
  },
  siteSettingsCallingMsgSucc: {
    defaultMessage: 'The configuration was successfully saved',
    description: 'Calling - success message',
    id: 'siteSettings.calling.msgSucc',
  },
  siteSettingsCallingSubmit: {
    defaultMessage: 'Save changes',
    description: 'Calling - submit button',
    id: 'siteSettings.calling.submit',
  },
  siteSettingsCallingText1: {
    defaultMessage:
      `3rd party SIP calls allow visitors to reach tenants on their mobile phones via the public network and open the door by entering a code on their phone.` +
      `<p>For a clearer understanding of how this works, please refer to the <url>diagram</url>.</p>`,
    description: 'Calling - text',
    id: 'siteSettings.calling.text1',
  },
  siteSettingsCallingText2: {
    defaultMessage:
      `Before you can start using 3rd party SIP calls, there are several important steps you need to complete:<ul><li>` +
      `Activate 3rd party SIP calls for individual devices</li><li>Set SIP profile via Remote Configuration for individual devices</li>` +
      `<li>Provide the users' mobile phones in user detail</li>` +
      `<li>Set up switch codes on intercoms and notify your tenants</li></ul>`,
    description: 'Calling - text 2',
    id: 'siteSettings.calling.text2',
  },
  siteSettingsCallingTitle1: {
    defaultMessage: '3rd party SIP calls',
    description: 'Calling - title',
    id: 'siteSettings.calling.title1',
  },
  siteSettingsCallingTitle2: {
    defaultMessage: 'SIP calls set up',
    description: 'Calling - title 2',
    id: 'siteSettings.calling.title2',
  },
});
