import CheckIcon from '@mui/icons-material/Check';
import Stack from '@mui/material/Stack';
import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { IModalBase } from '../../modules/commonTypes';
import { editUserPassword } from '../../modules/user/actionsTS';
import { IUserBase } from '../../modules/user/store';
import ActionModal from '../_DesignSystemWrappers/Modal/ActionModal';
import { FilledWarningBar } from '../_DesignSystemWrappers/WarningBar/FilledWarningBar';
import { PasswordInput } from '../HookFormFields/PasswordInput';
import { IChangePasswordFormValues, useGetGlobalHooks } from './hooks/useGetGlobalHooks';
import messages from './messages';

const UserChangePasswordDialog = ({ onClose, open }: IModalBase) => {
  const { currentUser, dispatch, formatMessage, formMethods } = useGetGlobalHooks();
  useEffect(() => {
    if (formMethods.getFieldState('passwordRepeat').isDirty) {
      formMethods.trigger('passwordRepeat');
    }
  }, [formMethods.watch('password')]);

  function onSubmit(values: IChangePasswordFormValues) {
    dispatch(
      editUserPassword({
        currentUser: {
          email: currentUser.email,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
        } as IUserBase,
        password: values.password,
      })
    );
    onClose();
  }

  return (
    <ActionModal
      icon={<CheckIcon />}
      isPrimaryButtonDisabled={!formMethods.formState.isValid}
      onClose={onClose}
      open={open}
      primaryButtonAction={formMethods.handleSubmit(onSubmit)}
      primaryButtonText={formatMessage(messages.userChangePasswordDialogFormActionsSubmit)}
      secondaryButtonText={formatMessage(messages.userChangePasswordDialogFormActionsCancel)}
      title={formatMessage(messages.userChangePasswordDialogTitle)}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Stack direction="column">
            <Stack direction="column" gap={2}>
              <FilledWarningBar>
                <FormattedMessage {...messages.UserChangePasswordDialogImsWarningAppLogout}></FormattedMessage>
              </FilledWarningBar>
              <PasswordInput
                id="password"
                label={formatMessage(messages.userChangePasswordDialogLabelPassword)}
                name="password"
                required
                fullWidth
              />
            </Stack>
            <PasswordInput
              id="passwordRepeat"
              label={formatMessage(messages.userChangePasswordDialogLabelRepeatPassword)}
              name="passwordRepeat"
              required
              fullWidth
            />
          </Stack>
        </form>
      </FormProvider>
    </ActionModal>
  );
};

export default UserChangePasswordDialog;
