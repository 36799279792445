import CheckIcon from '@mui/icons-material/Check';
import { MultistepDialog } from '@my2n/multistep-dialog';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { IModalBase } from '../../modules/commonTypes';
import { useGetCreateSiteModalHooks } from './hooks/useGetCreateSiteModalHooks';
import messages from './messages';
import { CreateSiteStep } from './steps/CreateSiteStep/CreateSiteStep';
import { SelectSiteCategoryStep } from './steps/SelectSiteCategoryStep/SelectSiteCategoryStep';
import { SelectSiteTypeStep } from './steps/SelectSiteTypeStep/SelectSiteTypeStep';

export interface ISiteModalProps extends IModalBase {
  isFirstLogin?: boolean;
  isNewCompany?: boolean;
}

export function CreateSiteModal({ isFirstLogin, isNewCompany, onClose, open }: ISiteModalProps) {
  const { formatMessage, formMethods, handleSubmit, isLoading, submitButtonText } = useGetCreateSiteModalHooks(onClose);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <MultistepDialog
          testId="site-modal"
          title={formatMessage(messages.CreateSiteModalTitle)}
          backButtonLabel={formatMessage(messages.CreateSiteModalBack)}
          cancelButtonLabel={formatMessage(messages.CreateSiteModalCancel)}
          nextButtonLabel={formatMessage(messages.CreateSiteModalNext)}
          orderedSteps={[
            {
              children: <CreateSiteStep isFirstLogin={isFirstLogin} isNewCompany={isNewCompany} />,
              isNextDisabled:
                formMethods.formState.errors['name'] !== undefined ||
                !formMethods.formState.isDirty ||
                formMethods.getValues('name') === '',
            },
            {
              children: <SelectSiteCategoryStep />,
              isNextDisabled: formMethods.getValues('siteCategory') === undefined,
            },
            {
              children: <SelectSiteTypeStep />,
            },
          ]}
          submitButton={{
            icon: <CheckIcon />,
            isDisabled: !formMethods.formState.isValid || isLoading,
            label: submitButtonText,
          }}
          onClose={onClose}
          open={open}
          onSubmit={formMethods.handleSubmit(handleSubmit)}
        />
      </form>
    </FormProvider>
  );
}
