import Grid from '@mui/material/Grid';
import React from 'react';
import { NumberOfSubscriptions } from './Fields/NumberOfSubscriptions';
import { NumberOfYears } from './Fields/NumberOfYears';
import { TotalPrice } from './Fields/TotalPrice';
import { TotalValue } from './Fields/TotalValue';
import { VoucherName } from './Fields/VoucherName';

export interface IRowTwoProps {
  currency: string;
  name: string;
  unitFullPrice: number;
  batchIndex: number;
}

export function RowTwo({ batchIndex, currency, name, unitFullPrice }: IRowTwoProps) {
  return (
    <Grid container item justifyContent={{ lg: 'flex-start', sm: 'between-space' }} spacing={3}>
      <Grid item lg={3} sm={4} xs={12}>
        <VoucherName name={name} />
      </Grid>
      <Grid item lg={2} sm={3} xs={6}>
        <NumberOfYears name={name} />
      </Grid>
      <Grid item lg={2} sm={5} xs={6}>
        <NumberOfSubscriptions name={name} batchIndex={batchIndex} />
      </Grid>
      <Grid
        item
        lg={0}
        sm={4}
        sx={{
          display: {
            lg: 'none',
            md: 'block',
            sm: 'block',
            xs: 'none',
          },
        }}
        xs={0}
      >
        <span>{''}</span>
      </Grid>
      <Grid item lg={3} sm={4} xs={6}>
        <TotalValue batchIndex={batchIndex} />
      </Grid>
      <Grid item lg={2} sm={4} xs={6}>
        <TotalPrice batchIndex={batchIndex} currency={currency} unitFullPrice={unitFullPrice} />
      </Grid>
    </Grid>
  );
}
