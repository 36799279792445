import Badge from '@mui/material/Badge';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MOBILE_VIDEO_STATUS } from '../../config/consts';
import { useSiteContext } from '../../context/useSiteContext';
import messages from './messages';

const styles = {
  badge: {
    '& .MuiBadge-badge': {
      backgroundColor: 'success.main',
      zIndex: 'auto',
    },
    cursor: 'pointer',
  },
  paper: {
    padding: 1,
  },
  title: {
    fontWeight: 'bold',
  },
  tooltip: {
    backgroundColor: 'transparent',
    maxWidth: '160px',
  },
};

export interface IDeviceForFreeTooltipProps {
  children: JSX.Element;
  isTooltip: boolean;
  status: string;
}

function DeviceForFreeTooltip({ children, isTooltip, status }: IDeviceForFreeTooltipProps) {
  const isPaymentModelPerApartment = useSiteContext().hasSiteBillingType('PER_APARTMENT');

  return isTooltip &&
    (status === MOBILE_VIDEO_STATUS.STANDARD ||
      status === MOBILE_VIDEO_STATUS.TRIAL_ACTIVE ||
      status === MOBILE_VIDEO_STATUS.TEMPORARY) ? (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: styles.tooltip,
        },
      }}
      placement="right-start"
      title={
        <Paper sx={styles.paper}>
          <Typography color="primary" sx={styles.title} variant="body2">
            <FormattedMessage {...messages.deviceForFreeTooltipFree} />
          </Typography>
          <Typography color="secondary" variant="caption">
            <FormattedMessage
              {...(isPaymentModelPerApartment
                ? messages.deviceForFreeTooltipApartmentsFree
                : messages.deviceForFreeTooltipDevicesFree)}
            />
          </Typography>
        </Paper>
      }
    >
      <Badge sx={styles.badge} variant="dot">
        {children}
      </Badge>
    </Tooltip>
  ) : (
    children
  );
}

export default DeviceForFreeTooltip;
