import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import NoRowsOverlayDataGrid from '../DataGrid/NoRowsOverlay';
import messages from './messages';

export function NoRowsOverlay({ onCreateSite }: { onCreateSite: () => void }) {
  return (
    <NoRowsOverlayDataGrid
      action={
        <Button color="primary" onClick={onCreateSite} startIcon={<AddIcon />} sx={{ mt: 3 }} variant="contained">
          <FormattedMessage {...messages.sitesDataGridNoRowsOverlayButton} />
        </Button>
      }
      headerText={<FormattedMessage {...messages.sitesDataGridNoRowsOverlayHeaderText} />}
      text={
        <FormattedMessage
          {...messages.sitesDataGridNoRowsOverlayText}
          values={{
            br: <br />,
          }}
        />
      }
    />
  );
}
