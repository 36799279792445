import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import React from 'react';
import { ReactSVG } from 'react-svg';

const styles = {
  description: {
    p: 'auto',
  },
  img: {
    display: { md: 'block', xs: 'none' },
  },
  root: {
    '& .wrapperSvg': {
      '& svg': {
        width: '104px',
      },
      display: 'block',
      mr: 3,
      pl: 3,
      pt: 2,
      width: '104px',
    },
    backgroundColor: 'background.default',
    border: 1,
    borderColor: 'primary.main',
    borderRadius: '4px',
    color: 'primary.main',
    mx: 0,
    my: 1,
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    mb: 'auto',
    pl: 2,
  },
  wrapper: {
    flex: 1,
    px: { md: 2, xs: 0 },
    py: 2,
  },
};

interface ISiteTypeDescription {
  title: React.JSX.Element;
  icon: string;
  description: React.JSX.Element;
}

export function SiteTypeDescription({ description, icon, title }: ISiteTypeDescription) {
  return (
    <Box
      alignItems="flex-start"
      display="flex"
      sx={{
        ...styles.root,
      }}
    >
      <Box sx={styles.img}>
        <ReactSVG className="wrapperSvg" src={icon} />
      </Box>
      <Box alignSelf="baseline" display="flex" flexDirection="column" sx={styles.wrapper}>
        <Typography gutterBottom sx={styles.title}>
          {title}
        </Typography>
        <Typography component="div" sx={styles.description}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
}
