import { SITE_TYPE } from '../../config/sites';
import { BILLING_TYPE } from '../../config/subscription';
import { isStandardMVType } from '../../helpers/mobileVideo';
import { IMVLicenceModel } from '../../modules/sites/store';
import messages from './messages';

export function isFreeApartmentAlertVisible(licenceModel: IMVLicenceModel, siteType: string) {
  return (
    licenceModel.billingType === BILLING_TYPE.PER_APARTMENT &&
    isStandardMVType(licenceModel.type, licenceModel.status) &&
    licenceModel.unusedLicencesCount !== 0 &&
    siteType !== SITE_TYPE.OTHER
  );
}

export function getFreeApartmentAlertMessage(licenceModel: IMVLicenceModel) {
  if (licenceModel.unusedFreeLicencesWithIntercomCount === 0) {
    if (licenceModel.unusedLicencesCount === 1) {
      return {
        text: messages.apartmentsOverviewWithout2NIntercomUnusedLicensesOne,
      };
    } else {
      return {
        count: licenceModel.unusedLicencesCount,
        text: messages.apartmentsOverviewWithout2NIntercomUnusedLicensesMoreThanOne,
      };
    }
  } else {
    if (licenceModel.unusedLicencesCount === 1) {
      return { text: messages.apartmentsOverviewWith2NIntercomUnusedLicensesOne };
    } else {
      return {
        count: licenceModel.unusedLicencesCount,
        text: messages.apartmentsOverviewWith2NIntercomUnusedLicensesMoreThanOne,
      };
    }
  }
}
