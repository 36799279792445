import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import CreateMultiAutocomplete from '../CreateMultiAutocomplete';
import ApartmentForm from './ApartmentForm';
import Group from './Group';
import messages from './messages';
import Option from './Option';
import Tag from './Tag';

function ApartmentAutocomplete({ apartmentFormStyle, hasFormItemKey, newItemId, optionsData }) {
  const { control, getValues, watch } = useFormContext();
  const { formatMessage } = useIntl();

  const options = useMemo(
    () => [
      {
        id: newItemId,
        label: formatMessage(messages.ApartmentAutocompleteSelectNewItemLabel),
      },
      ...optionsData,
    ],
    [optionsData]
  );

  return (
    <CreateMultiAutocomplete
      createForm={watch(hasFormItemKey) && <ApartmentForm control={control} apartmentFormStyle={apartmentFormStyle} />}
      createFormVisibilityKey={hasFormItemKey}
      customOption={(renderProps, option) => (
        <Option
          key={option.id}
          newItemId={newItemId}
          option={option}
          optionLabelKey="label"
          renderProps={renderProps}
        />
      )}
      customTag={(tagValue, getTagProps) => (
        <Tag getTagProps={getTagProps} key={tagValue.id} newItemId={newItemId} tagValue={tagValue} />
      )}
      helperText={formatMessage(messages.apartmentAutocompleteHelperText)}
      label={formatMessage(messages.ApartmentAutocompleteSelectLabel)}
      name="apartment"
      newItemId={newItemId}
      optionGroupKey="floor"
      optionLabelKey="label"
      options={options}
      placeholder={
        !getValues()?.apartment?.length ? formatMessage(messages.apartmentAutocompleteSelectPlaceholder) : ''
      }
      renderGroup={(data) => <Group group={data.group || ''} items={data.children} key={data.key} />}
    />
  );
}

ApartmentAutocomplete.propTypes = {
  apartmentFormStyle: PropTypes.shape({}),
  hasFormItemKey: PropTypes.string.isRequired,
  newItemId: PropTypes.string.isRequired,
  optionsData: PropTypes.arrayOf(
    PropTypes.shape({
      floor: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
      name: PropTypes.string,
      number: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ApartmentAutocomplete;
