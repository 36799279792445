import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { ChangeSiteTypeChecksResponse, SiteFeature } from '../../../../apiRtk/be4feApi';
import { SITE_CATEGORY } from '../../../../config/sites';
import Alert from '../../../Alert';
import { IManageSiteTypeModalValues } from '../../hooks/useGetChangeSiteTypeModalHooks';
import { ValidationIssuesItem } from './components/ValidationIssuesItem';
import messages from './messages';

interface IValidationIssuesStep {
  manageSiteTypeValidationResult: ChangeSiteTypeChecksResponse;
}

export function ValidationIssuesStep({ manageSiteTypeValidationResult }: IValidationIssuesStep) {
  const { getValues } = useFormContext<IManageSiteTypeModalValues>();
  const isResidential = getValues('siteCategory') === SITE_CATEGORY.RESIDENTIAL;
  const { actualApartmentDeviceLimit, defaultApartmentDeviceLimit, enabledFeatures, failedToEnableFeatures } =
    manageSiteTypeValidationResult;

  function maintainsSiteFeature(featureToCheck: SiteFeature) {
    return enabledFeatures.includes(featureToCheck) || failedToEnableFeatures.includes(featureToCheck);
  }

  return (
    <Stack mb={2} spacing={2}>
      <Alert severity="warning">
        <FormattedMessage
          {...(isResidential
            ? messages.changeSiteTypeModalErrorAlertResidential
            : messages.changeSiteTypeModalErrorAlertCommercial)}
        />
      </Alert>
      <Typography>
        <FormattedMessage
          {...(isResidential
            ? messages.changeSiteTypeModalErrorTextResidential
            : messages.changeSiteTypeModalErrorTextCommercial)}
        />
      </Typography>
      <Stack spacing={1}>
        {maintainsSiteFeature('NON_INTERCOM_DEVICE_FORBIDDEN_OUTSIDE_APARTMENT') && (
          <ValidationIssuesItem
            description={<FormattedMessage {...messages.changeSiteTypeModalErrorRequirement2} />}
            errorCondition={failedToEnableFeatures.includes('NON_INTERCOM_DEVICE_FORBIDDEN_OUTSIDE_APARTMENT')}
          />
        )}
        <ValidationIssuesItem
          errorCondition={actualApartmentDeviceLimit !== defaultApartmentDeviceLimit}
          description={
            <FormattedMessage
              {...messages.changeSiteTypeModalErrorRequirement3}
              values={{
                count: defaultApartmentDeviceLimit,
                link: (chunks) => <Link href="mailto:customercare@2n.com">{chunks}</Link>,
              }}
            />
          }
        />
      </Stack>
    </Stack>
  );
}
