import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { LoadingButton } from '../../../../components';
import actions from '../../../../modules/subscription';
import { GP_WEBPAY, INVOICE, PROMO } from '../../constants';
import { PromoTotalPriceBar } from '../Common/PromoTotalPriceBar';
import { getFullPrice, getSubscriptionTypeBasedOnProduct } from '../hooks/helpers';
import { IBatch, IOrderItem, IPrices, IPromo, PROMO_TYPES, SUBSCRIPTION_TYPE } from '../hooks/types';
import { useGetPromoCodeData } from '../hooks/useGetPromoCodeData';
import { getValidationSchema } from '../hooks/validationSchema';
import { AddBatchItem } from './AddBatchItem';
import messages from './messages';
import PromoBatchForm from './PromoBatchForm';

export interface IOneStepProps {
  canBeInvoiced: boolean;
  currency: string;
  onStepChange: (value: number) => void;
  orderContent: IOrderItem[];
  prices: IPrices;
  promoCodesOrderIsLoading: boolean;
}

export interface IItem {
  id: number;
  name: string;
  subscriptionType: SUBSCRIPTION_TYPE;
  subscriptions: number;
  years: number;
}

export interface IFormValues {
  array: IItem[];
}

export function OneStep({
  canBeInvoiced,
  currency,
  onStepChange,
  orderContent,
  prices,
  promoCodesOrderIsLoading,
}: IOneStepProps) {
  const { dispatch, formatMessage } = useGetPromoCodeData();
  const defaultBatch = {
    id: 0,
    name: `Voucher ${new Date().getFullYear()}`,
    subscriptions: 10,
    subscriptionType: SUBSCRIPTION_TYPE.DEFAULT,
    years: 1,
  };

  const transformCurrentBatch = (order: IOrderItem[]): IBatch[] => {
    const batch: IBatch[] = [];

    if (order !== undefined) {
      order.forEach((item) =>
        batch.push({
          id: item.id,
          name: item.name,
          subscriptions: item?.configuration?.devices,
          subscriptionType: getSubscriptionTypeBasedOnProduct(item?.configuration?.type),
          years: item?.configuration?.years,
        })
      );
    }

    return batch.length ? batch : [{ ...defaultBatch }];
  };

  const [currentBatch] = useState(transformCurrentBatch(orderContent));
  const [id, setId] = useState(defaultBatch.id);

  const formMethods = useForm<IFormValues>({
    defaultValues: {
      array: currentBatch,
    },
    mode: 'onChange',
    resolver: yupResolver(getValidationSchema(formatMessage)),
  });

  const { append, fields, remove } = useFieldArray({
    control: formMethods.control,
    name: 'array',
  });

  const onAddBatch = () => {
    const newId = id + 1;
    append({ ...defaultBatch, id: newId });
    setId(newId);
  };

  const onHandleSubmit = (values: IFormValues) => {
    const promo: IPromo = {
      content: [],
      paymentMethod: canBeInvoiced ? INVOICE : GP_WEBPAY,
    };

    values.array.forEach((item) => {
      promo.content.push({
        configuration: {
          devices: item.subscriptions,
          type:
            item.subscriptionType === SUBSCRIPTION_TYPE.DEVICE
              ? PROMO_TYPES.MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD
              : PROMO_TYPES.MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT,
          years: item.years,
        },
        name: item.name,
        size: 1,
        type: PROMO,
      });
    });
    dispatch(actions.setPromoCodesOrder(promo));
    onStepChange(2);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onHandleSubmit)}>
        <PromoBatchForm currency={currency} prices={prices} fields={fields} removeFromArray={remove} />
        <AddBatchItem onAddBatch={onAddBatch} />
        <Box mt={2}>
          <PromoTotalPriceBar
            currency={currency}
            price={getFullPrice(formMethods.watch('array'), prices).price}
            priceVat={getFullPrice(formMethods.watch('array'), prices).priceVat}
            subscriptionsCount={getFullPrice(formMethods.watch('array'), prices).subscriptionsCount}
          />
        </Box>
        <Box component="div" width="100%">
          <Box display="flex" justifyContent="flex-end" pt={2}>
            <LoadingButton
              color="primary"
              disabled={!formMethods.formState.isValid}
              isLoading={promoCodesOrderIsLoading}
              type="submit"
              variant="contained"
            >
              <FormattedMessage {...messages.submitButtonLabel} />
            </LoadingButton>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}
