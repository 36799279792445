import { api } from './api';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateSiteGsmService: build.mutation<UpdateSiteGsmServiceApiResponse, UpdateSiteGsmServiceApiArg>({
      query: (queryArg) => ({
        body: queryArg.siteGsmServiceDto,
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/services/GSM_CALLS`,
      }),
    }),
  }),
  overrideExisting: false,
});

export type UpdateSiteGsmServiceApiResponse = /** status 200 updated Site GSM Service */ SiteGsmServiceDto;

export type SiteGsmServiceDto = {
  active?: boolean;
  type: string;
};

export type UpdateSiteGsmServiceApiArg = {
  siteId: string;
  companyId: string;
  siteGsmServiceDto: SiteGsmServiceDto;
};

export { injectedRtkApi as coreApi };

export const { useUpdateSiteGsmServiceMutation } = injectedRtkApi;
