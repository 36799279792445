import { yupResolver } from '@hookform/resolvers/yup';
import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { CodeMaskedInput, LoadingButton } from '../../../../components';
import { OutlinedTextField } from '../../../../components/HookFormFields/TextField';
import messages from './messages';

export interface ICodeVerificationProps {
  isLoading: boolean;
  onCodeVerification: (data: string) => void;
}

export interface ICodeVerificationFormValues {
  code: string;
}

export function CodeVerification({ isLoading, onCodeVerification }: ICodeVerificationProps) {
  const { formatMessage } = useIntl();
  const formMethods = useForm<ICodeVerificationFormValues>({
    defaultValues: {
      code: '',
    },
    mode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        code: Yup.string()
          .required(formatMessage(messages.codeVerificationCodeInputRequired))
          .matches(
            /^([0-9,a-z,A-Z]{4})-([0-9,a-z,A-Z]{4})-([0-9,a-z,A-Z]{4})-([0-9,a-z,A-Z]{4})$/,
            formatMessage(messages.codeVerificationCodeInputInvalid)
          ),
      })
    ),
  });

  const handleCodeVerification = (data: ICodeVerificationFormValues) => onCodeVerification(data.code);

  return (
    <Box py={2}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleCodeVerification)}>
          <Grid alignContent="stretch" container direction="column" spacing={1}>
            <Grid item xs>
              <Box maxWidth={256}>
                <OutlinedTextField
                  name="code"
                  placeholder="****-****-****-****"
                  required
                  label={formatMessage(messages.codeVerificationCodeInputLabel)}
                  fullWidth
                  inputProps={{
                    style: {
                      fontFamily: 'monospace',
                      fontSize: 16,
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ inputComponent: CodeMaskedInput }}
                />
              </Box>
            </Grid>
            <Grid item>
              <LoadingButton
                color="primary"
                disabled={!formMethods.formState.isValid || isLoading}
                isLoading={isLoading}
                startIcon={<DoneIcon />}
                type="submit"
                variant="contained"
              >
                <FormattedMessage {...messages.codeVerificationButton} />
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
}
