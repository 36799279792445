import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import React, { useState } from 'react';
import { SiteBasicInfo } from '../../apiRtk/be4feApi';
import { useUrlParams } from '../../helpers/urlParams';
import CompanySwitcher from './CompanySwitcher';
import SideBarNavigation from './SideBarNavigation';
import { SiteSwitcherMobile } from './SiteSwitcherMobile';
import { styles } from './styles/Sidebar';

export interface ISidebarMobileProps {
  isVersionWarningDisplayed: boolean;
  onClose: () => void;
  onOpenCreateCompanyModal: () => void;
  onOpenCreateSiteModal: () => void;
  sitesList?: SiteBasicInfo[];
}

export function SidebarMobile({
  isVersionWarningDisplayed,
  onClose,
  onOpenCreateCompanyModal,
  onOpenCreateSiteModal,
  sitesList,
}: ISidebarMobileProps) {
  const { companyId } = useUrlParams();
  const defaultOpenState = { company: false, site: false };
  const [open, setOpen] = useState(defaultOpenState);

  return (
    <Drawer
      onClose={onClose}
      open
      sx={{
        ...(isVersionWarningDisplayed && styles.withVersionWarning),
      }}
      variant="temporary"
    >
      <Box
        display={{ md: 'none', xs: 'block' }}
        py={1}
        sx={{
          ...(isVersionWarningDisplayed && styles.withVersionWarning),
        }}
      >
        <CompanySwitcher
          onDrawerClose={onClose}
          onSetOpen={(state) => setOpen({ ...defaultOpenState, company: state })}
          open={open.company}
          onOpenCreateCompanyModal={onOpenCreateCompanyModal}
        />
        {open.company && <Divider sx={{ mb: 1 }} />}
        {companyId !== undefined && (
          <SiteSwitcherMobile
            onDrawerClose={onClose}
            onOpenCreateSiteModal={onOpenCreateSiteModal}
            onSetOpen={(state) => setOpen({ ...defaultOpenState, site: state })}
            open={open.site}
            sitesList={sitesList}
          />
        )}
      </Box>
      <Divider />
      <SideBarNavigation onCloseSidebarMobile={onClose} />
    </Drawer>
  );
}
