import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { DataGridSearchField, DeviceDataGrid, Title } from '../../components';
import { changeFilter, dataGridProps, getDataGridSelector } from '../../helpers/dataGrid';
import { useUrlParams } from '../../helpers/urlParams';
import { useModal } from '../../hooks/useModal';
import commonActions from '../../modules/common';
import actions, { NAME as DEVICE_MODULE } from '../../modules/devices';
import VerifyDeviceModal from '../VerifyDeviceModal';
import messages from './messages';

const NotVerifiedDevices = ({ notVerifiedDevicesTable, onLoadNotVerifiedDevices, onSearch }) => {
  const [fulltext, setFulltext] = useState(notVerifiedDevicesTable.filter.fulltext || '');
  const { companyId, siteId } = useUrlParams();

  const { Modal: VerifyModal, onOpen: onOpenVerifyModal } = useModal({
    Modal: VerifyDeviceModal,
  });

  const onChangeFilter = ({ target }) => {
    const { name, value } = target;
    setFulltext(value);
    changeFilter(onSearch, actions.getNotVerifiedDevicesRequest, {
      ...notVerifiedDevicesTable,
      companyId,
      siteId,
    })(name, value);
  };

  return (
    <>
      <Title
        heading={<FormattedMessage {...messages.notVerifiedDevicesTitle} />}
        rightSide={<DataGridSearchField fulltext={fulltext} onChangeFilter={onChangeFilter} />}
      />
      <DeviceDataGrid
        {...notVerifiedDevicesTable}
        companyId={companyId}
        onLoadDevices={onLoadNotVerifiedDevices}
        siteId={siteId}
        onOpenVerify={onOpenVerifyModal}
      />
      <VerifyModal />
    </>
  );
};

NotVerifiedDevices.propTypes = {
  notVerifiedDevicesTable: dataGridProps.isRequired,
  onLoadNotVerifiedDevices: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  notVerifiedDevicesTable: getDataGridSelector(state, DEVICE_MODULE, 'notVerifiedDevices'),
});

const mapDispatchToProps = {
  onLoadNotVerifiedDevices: actions.getNotVerifiedDevicesRequest,
  onSearch: commonActions.search,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotVerifiedDevices);
