import { defineMessages } from 'react-intl';

export default defineMessages({
  AAmodalChangeLabelErrorMandatory: {
    defaultMessage: 'Value is mandatory',
    id: 'AAmodal.changeLabel.errorMandatory',
  },
  AAmodalChangeLabelErrorTooLong: {
    defaultMessage: 'Label is too long',
    id: 'AAmodal.changeLabel.errorTooLong',
  },
  aamodalNewGuestPinnameErrRequired: {
    defaultMessage: 'Visitor name is required',
    id: 'AAmodal.newGuest.PINname.errRequired',
  },
  aamodalNewGuestPinnameErrTooLong: {
    defaultMessage: "Visitor's name is too long",
    id: 'AAmodal.newGuest.PINname.errTooLong',
  },
  assignRfidCardErrorCardAssigned: {
    defaultMessage: 'This card is assigned to another user',
    description: 'RFID assign error - card is already assigned',
    id: 'assignRfidCardErrorCardAssigned',
  },
  assignRfidCardErrorWrongFormat: {
    defaultMessage: 'Enter the card code in the correct format',
    description: 'RFID assign error - wrong format of card code',
    id: 'assignRfidCardErrorWrongFormat',
  },
  assignRfidCardErrorWrongLength: {
    defaultMessage: 'Enter the correct card code',
    description: 'RFID assign error - wrong length of card code',
    id: 'assignRfidCardErrorWrongLength',
  },
  createApartmentUserValidationSchemaEmailExists: {
    defaultMessage: 'This email is taken, choose a different one',
    description: 'Create apartment user - email already exists',
    id: 'AAmodal.newUser.mailErrUnique',
  },
  createSiteModalValidationSchemaExistName: {
    defaultMessage: 'This site name is already used.',
    id: 'createSiteModalValidationSchemaExistName',
  },
  createSiteModalValidationSchemaMaxLengthName: {
    defaultMessage: 'Site name is too long.',
    id: 'createSiteModalValidationSchemaMaxLengthName',
  },
  createSiteModalValidationSchemaRequired: {
    defaultMessage: 'Value is mandatory.',
    id: 'createSiteModalValidationSchemaRequired',
  },
  createUserValidationSchemaExistsEmail: {
    defaultMessage: 'User with this email address already exists in this Site.',
    description: 'Create user - new user email already exists',
    id: 'createUserValidationSchema.existsEmail',
  },
  createUserValidationSchemaInvalidEmail: {
    defaultMessage: 'Wrong email format',
    description: 'Create user - new user email has wrong format',
    id: 'shared.form.ErrMailFormat',
  },
  createUserValidationSchemaInvalidValue: {
    defaultMessage: 'Invalid value.',
    description: 'Create user - invalid Value',
    id: 'createUserValidationSchema.invalidValue',
  },
  createUserValidationSchemaMaxLengthFirstName: {
    defaultMessage: 'First name is too long.',
    description: 'Create user - new user first name is too long',
    id: 'createUserValidationSchema.maxLength.firstName',
  },
  createUserValidationSchemaMaxLengthLastName: {
    defaultMessage: 'Last name is too long.',
    description: 'Create user - new user last name is too long',
    id: 'createUserValidationSchema.maxLength.lastName',
  },
  createUserValidationSchemaRequiredEmail: {
    defaultMessage: 'Enter email.',
    description: 'Create user - new user email is required',
    id: 'createUserValidationSchema.requiredEmail',
  },
  createUserValidationSchemaRequiredFirstName: {
    defaultMessage: 'Enter first name.',
    description: 'Create user - new user first name is required',
    id: 'createUserValidationSchema.firstName',
  },
  createUserValidationSchemaRequiredLastName: {
    defaultMessage: 'Enter last name.',
    description: 'Create user - new user last name is required',
    id: 'createUserValidationSchema.lastName',
  },
  personalInfoMobileErrFormat: {
    defaultMessage: 'Use the correct format (6–15 digits)',
    description: 'Personal info - Mobile phone error message',
    id: 'personalInfo.mobilePhone.errFormat',
  },
  personalInfoMobileHelperText: {
    defaultMessage: 'Use international format of the number, e.g. 00420123456789',
    description: 'Personal info - Mobile phone helper text',
    id: 'personalInfo.mobilePhone.helperText',
  },
  registerPasswordNoDigit: {
    defaultMessage: '1 digit',
    description: 'Register - password no digit',
    id: 'register.password.noDigit',
  },
  registerPasswordNoLowercase: {
    defaultMessage: '1 lowercase letter',
    description: 'Register - password no lowercase',
    id: 'register.password.noLowercase',
  },
  registerPasswordNoRequiredLength: {
    defaultMessage: '8 characters',
    description: 'Register - password not valid length',
    id: 'register.password.noRequiredLength',
  },
  registerPasswordNoUppercase: {
    defaultMessage: '1 uppercase letter',
    description: 'Register - password no uppercase',
    id: 'register.password.noUppercase',
  },
  registerPasswordNoValidAny: {
    defaultMessage: 'Use at least: ',
    description: 'Register - password some unmet condition',
    id: 'register.password.noValidAny',
  },
  registerPasswordRequired: {
    defaultMessage: 'Value is mandatory',
    description: 'Register - password is mandatory',
    id: 'register.password.required',
  },
  useApartmentFormValidationSchemaExistNumber: {
    defaultMessage: 'An apartment with this number already exists.',
    description: 'Apartment data form - number exists',
    id: 'useApartmentFormValidationSchemaExistNumber',
  },
  useApartmentFormValidationSchemaInvalidValue: {
    defaultMessage: 'Invalid value',
    description: 'Apartment data form - invalid value',
    id: 'useApartmentFormValidationSchemaInvalidValue',
  },
  useApartmentFormValidationSchemaMaxLengthFloor: {
    defaultMessage: 'Floor name is too long',
    description: 'Apartment data form - floor name is too long',
    id: 'useApartmentFormValidationSchemaMaxLengthFloor',
  },
  useApartmentFormValidationSchemaMaxLengthName: {
    defaultMessage: 'Name is too long',
    description: 'Apartment data form - name is too long',
    id: 'useApartmentFormValidationSchemaMaxLengthName',
  },
  useApartmentFormValidationSchemaMaxLengthNumber: {
    defaultMessage: 'Number is too long',
    description: 'Apartment data form - number is too long',
    id: 'useApartmentFormValidationSchemaMaxLengthNumber',
  },
  useApartmentFormValidationSchemaRequiredFloor: {
    defaultMessage: 'Enter the floor number',
    description: 'Apartment data form - floor is required',
    id: 'useApartmentFormValidationSchemaRequiredFloor',
  },
  useApartmentFormValidationSchemaRequiredNumber: {
    defaultMessage: 'Enter the apartment number',
    description: 'Apartment data form - number is required',
    id: 'useApartmentFormValidationSchemaRequiredNumber',
  },
  usersFormMobilePhoneErrFormat: {
    defaultMessage: 'Use the correct format (6–15 digits)',
    description: 'User form - mobile phone error message',
    id: 'personalInfo.mobilePhone.errFormat',
  },
  validationMaxLength: {
    defaultMessage: 'Value is too long.',
    id: 'validation.maxLength',
  },
  validationRequired: {
    defaultMessage: 'Value is mandatory',
    id: 'validation.required',
  },
});
