import React from 'react';
import { DeviceTypeEnum } from '../../modules/devices/store/storeTypes';
import { AxisDeviceForm } from './AxisDeviceForm/AxisDeviceForm';
import AxisDoorStationForm from './AxisDoorStationForm';
import { DeviceSwitcherForm } from './DeviceSwitcherForm/DeviceSwitcherForm';
import { HipmoForm } from './HipmoForm/HipmoForm';
import ThirdPartyForm from './ThirdPartyForm';
import { IDeviceModal } from './types';
import { X2NDeviceForm } from './X2NDeviceForm/X2NDeviceForm';

export interface IDeviceFormSwitch extends IDeviceModal {
  onClearDevice: () => void;
}

export function DeviceFormSwitch(props: IDeviceFormSwitch): JSX.Element {
  switch (props.deviceType) {
    case DeviceTypeEnum.HIPMO:
      return (
        <HipmoForm
          onClose={props.onClose}
          onSelectDevice={props.onSelectDevice}
          onClearModal={props.onClearDevice}
          open={props.open}
        />
      );
    case DeviceTypeEnum.THIRD_PARTY:
      return (
        <ThirdPartyForm
          onClose={props.onClose}
          onSelectDevice={props.onSelectDevice}
          onClearModal={props.onClearDevice}
          open={props.open}
        />
      );
    case DeviceTypeEnum.DOOR_STATION:
      return <AxisDoorStationForm onClose={props.onClose} onSelectDevice={props.onSelectDevice} open={props.open} />;
    case 'AXIS_DEVICE':
      return (
        <AxisDeviceForm
          onClearModal={props.onClearDevice}
          onClose={props.onClose}
          onSelectDevice={props.onSelectDevice}
          open={props.open}
        />
      );
    case DeviceTypeEnum.AXIS_ANSWERING_UNIT:
    case 'X2N':
      return (
        <X2NDeviceForm
          deviceType={props.deviceType}
          onClose={props.onClose}
          onSelectDevice={props.onSelectDevice}
          onClearModal={props.onClearDevice}
          open={props.open}
        />
      );
    default:
      return <DeviceSwitcherForm {...props} />;
  }
}
