import { SERVICE } from '../../config/devices';
import types from './types';

export const initialListSite = {
  data: [],
  error: null,
  filter: { fulltext: '' },
  isFetching: false,
  order: 'name',
  page: 1,
  resultsFiltered: 0,
  resultsTotal: 0,
  rowsPerPage: 10,
};

export const initialState = {
  dashboard: {
    data: {},
    didInvalid: false,
    error: null,
    isFetching: false,
  },
  data: {},
  deleteModal: {
    isFetching: false,
    show: false,
    site: {},
  },
  didInvalid: false,
  editModal: {
    isFetching: false,
    show: false,
    site: {},
    values: {
      name: '',
    },
  },
  error: undefined,
  isFetching: false,
  lastVisitedSite: {
    error: null,
    isFetching: false,
  },
  list: {
    ...initialListSite,
    didInvalid: false,
  },
  recentSites: {
    ...initialListSite,
    rowsPerPage: 5,
  },
  siteUsers: {
    data: [],
    error: null,
    isFetching: false,
  },
};

const reducers = {
  [types.GET_SITES_USERS_REQUEST]: (state) => ({
    ...state,
    siteUsers: {
      ...initialState.siteUsers,
      isFetching: true,
    },
  }),

  [types.GET_SITES_USERS_SUCCESS]: (state, { payload }) => ({
    ...state,
    siteUsers: {
      ...initialState.siteUsers,
      data: payload,
    },
  }),

  [types.GET_SITES_USERS_FAILURE]: (state, { error }) => ({
    ...state,
    siteUsers: {
      ...initialState.siteUsers,
      error,
    },
  }),

  [types.EDIT_SITE_SHOW_MODAL]: (state, { site }) => ({
    ...state,
    editModal: {
      ...initialState.editModal,
      show: true,
      site,
      values: {
        name: site.name || '',
      },
    },
  }),

  [types.EDIT_SITE_HIDE_MODAL]: (state) => ({
    ...state,
    editModal: initialState.editModal,
  }),

  [types.EDIT_SITE_FORM_SUCCESS]: (state) => ({
    ...state,
    didInvalid: true,
    editModal: initialState.editModal,
    list: {
      ...state.list,
      didInvalid: true,
    },
    recentSites: {
      ...state.recentSites,
      didInvalid: true,
    },
  }),

  [types.EDIT_SITE_FORM_REQUEST]: (state) => ({
    ...state,
    editModal: {
      ...state.editModal,
      isFetching: true,
    },
  }),

  [types.EDIT_SITE_FORM_FAILURE]: (state) => ({
    ...state,
    editModal: initialState.editModal,
  }),

  [types.DELETE_SITE_SHOW_MODAL]: (state, { site }) => ({
    ...state,
    deleteModal: {
      isFetching: false,
      show: true,
      site,
    },
  }),

  [types.DELETE_SITE_HIDE_MODAL]: (state) => ({
    ...state,
    deleteModal: {
      ...state.deleteModal,
      show: false,
    },
  }),

  [types.DELETE_SITE_REQUEST]: (state) => ({
    ...state,
    deleteModal: {
      ...state.deleteModal,
      isFetching: true,
    },
  }),

  [types.DELETE_SITE_SUCCESS]: (state) => ({
    ...state,
    deleteModal: {
      isFetching: false,
      show: false,
      site: {},
    },
    didInvalid: true,
    list: {
      ...initialState.list,
      didInvalid: true,
    },
    recentSites: {
      ...state.recentSites,
      didInvalid: true,
    },
  }),

  [types.DELETE_SITE_FAILURE]: (state) => ({
    ...state,
    deleteModal: {
      ...state.deleteModal,
      isFetching: false,
    },
  }),

  [types.LOAD_SITE_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
  }),

  [types.LOAD_SITE_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: payload,
    didInvalid: false,
    isFetching: false,
  }),

  [types.LOAD_SITE_FAILURE]: (state, { error }) => ({
    ...initialState,
    error,
  }),

  [types.GET_SITES_REQUEST]: (state, { context }) => ({
    ...state,
    [context]: {
      ...initialState[context],
      isFetching: true,
    },
  }),

  [types.GET_SITES_RECENT_REQUEST]: (state, { context }) => ({
    ...state,
    [context]: {
      ...initialState[context],
      isFetching: true,
    },
  }),

  [types.GET_SITES_SUCCESS]: (state, { payload, ...action }) => ({
    ...state,
    [action.context]: {
      ...state[action.context],
      data: payload.data || [],
      didInvalid: false,
      filter: action.filter,
      isFetching: false,
      order: action.order,
      page: action.page,
      resultsFiltered: payload.resultsFiltered || 0,
      resultsTotal: payload.resultsTotal || 0,
      rowsPerPage: action.rowsPerPage,
    },
    didInvalid: false,
  }),

  [types.GET_SITES_FAILURE]: (state) => ({
    ...state,
    list: initialState.list,
  }),

  [types.GET_SITE_DASHBOARD_REQUEST]: (state) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      isFetching: true,
    },
  }),

  [types.GET_SITE_DASHBOARD_SUCCESS]: (state, { payload }) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      data: {
        ...payload,
        apartmentStatesCounts: {
          ...payload.apartmentStatesCounts,
        },
      },
      didInvalid: false,
      isFetching: false,
    },
  }),

  [types.GET_SITE_DASHBOARD_FAILURE]: (state, { error }) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      didInvalid: false,
      error,
      isFetching: false,
    },
  }),

  [types.DEACTIVATE_MV_RECURRING_PAYMENT]: (state) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      data: {
        ...state.dashboard?.data,
        services: {
          ...state.dashboard?.data?.services,
          [SERVICE.MOBILE_VIDEO]: {
            ...state.dashboard?.data?.services?.[SERVICE.MOBILE_VIDEO],
            subscribed: false,
          },
        },
      },
    },
  }),

  [types.INVALIDATE_SITE]: (state) => ({ ...state, didInvalid: true }),

  [types.GET_CURRENT_SITE_LICENSE_MODEL_REQUEST]: (state) => ({
    ...state,
    data: {
      ...state.data,
      services: {
        ...state.data?.services,
        [SERVICE.MOBILE_VIDEO]: {
          ...state.data?.services?.[SERVICE.MOBILE_VIDEO],
          licenceModel: {
            ...state.data?.services?.[SERVICE.MOBILE_VIDEO]?.licenceModel,
            isFetching: true,
          },
        },
      },
    },
  }),

  [types.GET_CURRENT_SITE_LICENSE_MODEL_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      services: {
        ...state.data?.services,
        [SERVICE.MOBILE_VIDEO]: {
          ...state.data?.services?.[SERVICE.MOBILE_VIDEO],
          licenceModel: {
            ...payload,
            isFetching: false,
          },
        },
      },
    },
  }),

  [types.GET_CURRENT_SITE_LICENSE_MODEL_FAILURE]: (state, { error }) => ({
    ...state,
    error,
  }),

  [types.SET_SITE_LANGUAGE_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      locale: payload,
    },
  }),

  [types.SET_SITE_TYPE_SUCCESS]: (state) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      didInvalid: true,
    },
    data: {
      ...state.data,
      didInvalid: true,
    },
    didInvalid: true,
    list: {
      ...state.list,
      didInvalid: true,
    },
    recentSites: {
      ...state.recentSites,
      didInvalid: true,
    },
  }),

  [types.SET_LAST_VISITED_SITE_REQUEST]: (state) => ({
    ...state,
    lastVisitedSite: {
      ...state.lastVisitedSite,
      isFetching: true,
    },
  }),

  [types.SET_LAST_VISITED_SITE_SUCCESS]: (state) => ({
    ...state,
    lastVisitedSite: {
      ...state.lastVisitedSite,
      error: null,
      isFetching: false,
    },
  }),

  [types.SET_LAST_VISITED_SITE_FAILURE]: (state, { error }) => ({
    ...state,
    lastVisitedSite: {
      ...state.lastVisitedSite,
      error,
      isFetching: false,
    },
  }),

  [types.INVALIDATE_SITE_DASHBOARD]: (state) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      didInvalid: true,
    },
  }),

  [types.CLEAR_SITE_DATA]: (state) => ({
    ...state,
    data: {
      roles: [],
      services: [],
    },
  }),
};

const reducer = (state = initialState, action = {}) => {
  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
};

export default reducer;
