import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import { IGetCompanyResponse } from '../../apiRtk/legacyApi/company/company';
import messages from './messages';

export enum FormFieldNames {
  City = 'city',
  Country = 'country',
  Currency = 'currency',
  Name = 'name',
  PostalCode = 'postalCode',
  Street = 'street',
  VatNumber = 'vatNumber',
}

export function getDefaultValues(billingData: IGetCompanyResponse | undefined) {
  return {
    [FormFieldNames.City]: billingData?.address?.city ? billingData?.address?.city : '',
    [FormFieldNames.Country]: billingData?.address?.country ? billingData?.address?.country : '',
    [FormFieldNames.Currency]: billingData?.currency ? billingData.currency : '',
    [FormFieldNames.Name]: billingData?.name ? billingData.name : '',
    [FormFieldNames.PostalCode]: billingData?.address?.postalCode ? billingData?.address?.postalCode : '',
    [FormFieldNames.Street]: billingData?.address?.street ? billingData?.address?.street : '',
    [FormFieldNames.VatNumber]: billingData?.vatId ? billingData.vatId : '',
  };
}

export function getValidationSchema(formatMessage: IntlShape['formatMessage']) {
  const required = formatMessage(messages.editBillingModalValidationRequired);
  const maxLength = formatMessage(messages.editBillingModalValidationMaxLength);
  const maxStringLength = 255;
  const maxStringLengthName = 100;
  const maxStringLengthCity = 50;
  const maxStringLengthPostalCode = 15;

  return Yup.object().shape({
    [FormFieldNames.City]: Yup.string().max(maxStringLengthCity, maxLength).required(required),
    [FormFieldNames.Country]: Yup.string().required(required),
    [FormFieldNames.Currency]: Yup.string().required(required),
    [FormFieldNames.Name]: Yup.string().max(maxStringLengthName, maxLength).required(required),
    [FormFieldNames.PostalCode]: Yup.string().max(maxStringLengthPostalCode, maxLength).required(required),
    [FormFieldNames.Street]: Yup.string().max(maxStringLength, maxLength).required(required),
    [FormFieldNames.VatNumber]: Yup.string().max(maxStringLength, maxLength),
  });
}
