import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import messages from './messages';
import { maxSubscriptions, maxYears, minSubscriptions, minYears, SUBSCRIPTION_TYPE } from './types';

export function getValidationSchema(formatMessage: IntlShape['formatMessage']) {
  const minYearLength = formatMessage(messages.validationMinYearLength, { year: minYears });
  const maxYearLength = formatMessage(messages.validationMaxYearLength, { year: maxYears });
  const minSubscriptionLength = formatMessage(messages.validationMinSubscriptionLength, {
    subscription: minSubscriptions,
  });
  const maxSubscriptionLength = formatMessage(messages.validationMaxSubscriptionLength, {
    subscription: maxSubscriptions,
  });
  const requiredSubscriptionType = formatMessage(messages.validationRequiredSubscriptionType);
  const requiredName = formatMessage(messages.validationRequiredName);
  const required = formatMessage(messages.validationRequired);

  return Yup.object().shape({
    array: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number().required(),
          name: Yup.string().required(requiredName),
          subscriptions: Yup.number()
            .required(required)
            .typeError(required)
            .max(maxSubscriptions, maxSubscriptionLength)
            .min(minYears, minSubscriptionLength),
          subscriptionType: Yup.mixed<SUBSCRIPTION_TYPE>().required(requiredSubscriptionType),
          years: Yup.number()
            .required(required)
            .typeError(required)
            .max(maxYears, maxYearLength)
            .min(minYears, minYearLength),
        })
      )
      .required()
      .test('SubscriptionTypeIsNotDefault', (array) => {
        return array.every((item) => item.subscriptionType !== SUBSCRIPTION_TYPE.DEFAULT);
      }),
  });
}
