import http, { encode } from './index';

export const getSite = async (company, site) => http.get(`/fe/companies/${encode(company)}/sites/${encode(site)}`);

export const deleteSite = async (company, site) => http.delete(`/companies/${encode(company)}/sites/${encode(site)}`);

export const getSites = async (company, params = {}) =>
  http.get(`/companies/${encode(company)}/sites`, {
    params,
  });

export const editSite = async (company, site, values) =>
  http.put(`/companies/${encode(company)}/sites/${encode(site)}`, values);

export const verifySite = async (company, name) =>
  http.head(`/companies/${encode(company)}/sites`, { params: { name } });

export const viewSite = async (siteId) => http.put('/users/current/lastSite', { id: siteId });

export const getSiteUsersNames = async (company, site) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/users/names`);

export const getSiteDashboard = async (company, site) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/dashboard`);
