import FilterNoneIcon from '@mui/icons-material/FilterNone';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PROMO_TYPES } from '../../containers/Subscription/PromoCode/consts';
import { getVoucherValue } from '../../containers/Subscription/PromoCode/hooks/helpers';
import BatchSwitchPanel from '../BatchSwitchPanel';
import { VIEW } from '../BatchSwitchPanel/consts';
import CodesTextCopyField from '../CodesTextCopyField';
import Loader from '../Loader';
import Modal from '../Modal';
import messages from './messages';
import SubscriptionInfo from './SubscriptionInfo';
import ValidityInfo from './ValidityInfo';

const PromoDetailModal = ({ data, isPaymentDataLoading, loadPaymentData, onClose, paymentData, show }) => {
  const [view, setView] = useState(VIEW.ACTIVE);
  useEffect(() => {
    if (show) {
      loadPaymentData(data.payment.id, data.id);
    }
  }, [show]);

  const filterCode = (code) => {
    if (view === VIEW.ACTIVE) {
      return code.state === 'CREATED';
    }
    if (view === VIEW.USED) {
      return code.state === 'USED';
    }
    if (view === VIEW.CANCELED) {
      return code.state === 'CANCELLED';
    }
    return view === VIEW.ALL;
  };

  const order = useMemo(
    () => data?.payment?.orderContent?.find((item) => item.id === paymentData.orderItemId),
    [data?.payment?.orderContent, paymentData.orderItemId]
  );

  const onCopyAllCodes = () => {
    const codes = paymentData?.codes.map((item) => item.code);
    copy(codes.join(''));
  };

  const subscriptions = useMemo(() => {
    if (order) {
      if (Object.values(PROMO_TYPES).indexOf(order.configuration?.type) === -1) {
        return order.configuration?.credit;
      }
      return getVoucherValue(order.configuration.years, order.configuration.devices);
    }
    return null;
  }, [
    order?.configuration?.type,
    order?.configuration?.credit,
    order?.configuration?.years,
    order?.configuration?.devices,
  ]);

  return (
    <Modal
      actions={
        <>
          <Button color="primary" onClick={onClose}>
            <FormattedMessage {...messages.promoDetailModalClose} />
          </Button>
          <Button color="primary" onClick={onCopyAllCodes} startIcon={<FilterNoneIcon />} variant="contained">
            <FormattedMessage {...messages.promoDetailModalCopyAllButton} />
          </Button>
        </>
      }
      fullWidth
      maxWidth="md"
      name="promo-detail-modal"
      onClose={onClose}
      open={show}
      title={<FormattedMessage {...messages.promoDetailModalTitle} values={{ name: data.name }} />}
    >
      {isPaymentDataLoading ? (
        <Loader alignItems="center" />
      ) : (
        paymentData?.id && (
          <>
            {order && (
              <SubscriptionInfo
                count={order.size}
                currency={data.payment.currency}
                price={order.price}
                priceVat={order.priceVat}
                subscriptions={subscriptions}
                type={order.configuration?.type}
              />
            )}
            <Box pt={1}>
              <ValidityInfo
                invoiceId={data.payment.id}
                invoiceName={paymentData.invoice}
                validityFrom={paymentData.dateCreated}
                validityTo={paymentData.validityTo}
              />
            </Box>
            <BatchSwitchPanel
              currentView={view}
              onSelectView={setView}
              stats={paymentData.stats}
              views={[VIEW.ACTIVE, VIEW.USED, VIEW.CANCELED, VIEW.ALL]}
            />
            <CodesTextCopyField
              data={paymentData?.codes.filter(filterCode).map((code) => ({
                closedAt: code?.closedAt,
                code: code.code,
                context: code.context || {},
                credit: paymentData.configuration.credit,
                dateCreated: paymentData.dateCreated,
                state: code.state,
                validityTo: paymentData.validityTo,
              }))}
              usedView={view === VIEW.USED}
            />
          </>
        )
      )}
    </Modal>
  );
};

PromoDetailModal.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    payment: PropTypes.shape({
      currency: PropTypes.string,
      id: PropTypes.string,
      orderContent: PropTypes.arrayOf(
        PropTypes.shape({
          configuration: PropTypes.shape({
            credit: PropTypes.number,
            devices: PropTypes.number,
            type: PropTypes.string,
            years: PropTypes.number,
          }),
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          price: PropTypes.number,
          priceVat: PropTypes.number,
          size: PropTypes.number,
        })
      ),
    }),
  }).isRequired,
  isPaymentDataLoading: PropTypes.bool.isRequired,
  loadPaymentData: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  paymentData: PropTypes.shape({
    codes: PropTypes.arrayOf(
      PropTypes.shape({
        closedAt: PropTypes.string,
        code: PropTypes.string,
        context: PropTypes.string,
        state: PropTypes.string,
      })
    ),
    configuration: PropTypes.shape({
      credit: PropTypes.number,
    }),
    dateCreated: PropTypes.string,
    id: PropTypes.number,
    invoice: PropTypes.string,
    orderItemId: PropTypes.number,
    stats: PropTypes.shape({}),
    validityTo: PropTypes.string,
  }).isRequired,
  show: PropTypes.bool,
};

PromoDetailModal.defaultProps = {
  show: false,
};

export default PromoDetailModal;
