import { coreApi } from './coreApi';

export type CoreApiTagTypes = 'site-settings';

export const enhancedCoreApi = coreApi.enhanceEndpoints<CoreApiTagTypes>({
  endpoints: {
    updateSiteGsmService: {
      invalidatesTags: [{ id: 'site', type: 'site-settings' }],
    },
  },
});
