import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../../messages';
import { IUnitPurchaseProps } from '../../UnitPropsTS';

const styles = {
  priceVat: {
    pl: 1,
  },
};

export interface IPriceMessageProps {
  currency: string;
  payment: IUnitPurchaseProps;
}

export function PriceMessage({ currency, payment }: IPriceMessageProps) {
  const { formatNumber } = useIntl();
  return (
    <>
      <Typography>{formatNumber(payment.unitFullPrice, { currency, style: 'currency' })}</Typography>
      <Typography color="secondary" sx={styles.priceVat} variant="body2">
        <FormattedMessage
          {...messages.mobileVideoLicenseSubscriptionPricePriceVat}
          values={{
            priceVat: formatNumber(payment.unitFullPriceVat, { currency, style: 'currency' }),
          }}
        />
      </Typography>
    </>
  );
}
