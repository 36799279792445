import { Alert, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FE_ROLES, FORM } from '../../config/consts';
import { getCurrentRole } from '../../helpers/user';
import apartmentActions from '../../modules/apartments';
import { CONTEXT } from '../../modules/user';
import CreateOrEditModalDialog from '../_DesignSystemWrappers/Modal/CreateOrEditModalDialog';
import ApartmentAutocomplete from '../ApartmentAutocomplete';
import Checkbox from '../HookFormFields/Checkbox';
import TextField from '../HookFormFields/TextField';
import UserRoleSelect from '../UserRoleSelect';
import { getInitialFormData, getSubmitButtonText, getSubmitFormCallback, getTitle } from './helpers/utils';
import { useGetApartmentFormData } from './hooks/getApartmentFormData';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import messages from './messages';

function UserModal({ context, data, isCurrentUser, isEdit, onClose, open, showApartmentField }) {
  const newItemId = FORM.NEW_ITEM_ID;
  const hasFormItemKey = FORM.HAS_FORM;
  const [userRole, setSiteUserRole] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const formData = useGetApartmentFormData();

  const validation = {
    companyId: formData.companyId,
    siteId: formData.siteId,
  };
  const { dispatch, formatMessage, formMethods } = useGetGlobalHooks(
    isEdit,
    context,
    isCurrentUser,
    data,
    validation,
    formData.companyId,
    formData.siteId,
    userRole
  );
  const phoneNumberValue = formMethods.watch('phoneNumber');
  const isLoginLessOrCompanyAdmin = userRole === FE_ROLES.LOGIN_LESS || userRole === FE_ROLES.COMPANY_ADMIN;

  useEffect(() => {
    if (!open) {
      return;
    }

    if (isEdit) {
      setSiteUserRole('');
    } else if (!isEdit && context === CONTEXT.SITE_USERS) {
      setSiteUserRole(FE_ROLES.SITE_USER);
    } else if (!isEdit && context === CONTEXT.COMPANY_ADMINS) {
      setSiteUserRole(FE_ROLES.COMPANY_ADMIN);
    }

    if (showApartmentField) {
      dispatch(apartmentActions.getApartmentsList(formData.companyId, formData.siteId));
    }

    formMethods.reset(getInitialFormData(formData.initialData, hasFormItemKey, data));
  }, [open]);

  useEffect(() => {
    if (!phoneNumberValue) {
      formMethods.setValue('gsmCalls', false);
    }
  }, [phoneNumberValue]);

  useEffect(() => {
    formMethods.reset(getInitialFormData(formData.initialData, hasFormItemKey, data));
  }, [userRole]);

  const submitForm = getSubmitFormCallback({
    context,
    data,
    dispatch,
    formMethods,
    hasFormItemKey,
    initialData: formData.initialData,
    isCurrentUser,
    isEdit,
    newItemId,
    onClose,
    setSubmitting,
    userRole,
    validation,
  });

  return (
    <FormProvider {...formMethods}>
      <CreateOrEditModalDialog
        isEdit={isEdit}
        open={open}
        primaryButtonAction={formMethods.handleSubmit(submitForm)}
        primaryButtonText={formatMessage(getSubmitButtonText(isEdit, context))}
        secondaryButtonAction={onClose}
        secondaryButtonText={formatMessage(messages.userModalFormActionsCancel)}
        onClose={onClose}
        submitDisabled={
          !formMethods.formState.isValid ||
          (!isCurrentUser && !userRole && context !== CONTEXT.COMPANY_ADMINS) ||
          isSubmitting ||
          !Object.keys(formMethods.formState.dirtyFields).length
        }
        title={formatMessage(getTitle(isEdit, isCurrentUser, context))}
      >
        <form onSubmit={formMethods.handleSubmit(submitForm)}>
          {!isCurrentUser && (
            <UserRoleSelect
              companyName={formData.companyName}
              onChange={setSiteUserRole}
              userRole={userRole || getCurrentRole(data, validation, context)}
            />
          )}
          {userRole !== FE_ROLES.LOGIN_LESS && (
            <TextField
              control={formMethods.control}
              disabled={isEdit}
              fullWidth
              label={formatMessage(messages.userModalLabelEmail)}
              name="email"
              required={!isEdit}
              sx={{ pb: 2 }}
              variant="outlined"
            />
          )}
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <TextField
                control={formMethods.control}
                fullWidth
                label={formatMessage(messages.userModalLabelFirstName)}
                name="firstName"
                required={userRole !== FE_ROLES.LOGIN_LESS}
                variant="outlined"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                control={formMethods.control}
                fullWidth
                label={formatMessage(messages.userModalLabelLastName)}
                name="lastName"
                required
                variant="outlined"
              />
            </Grid>
          </Grid>
          {!isEdit && !isLoginLessOrCompanyAdmin && (
            <>
              <TextField
                control={formMethods.control}
                fullWidth
                label={formatMessage(messages.usersFormMobilePhoneLabel)}
                name="phoneNumber"
                variant="outlined"
                sx={{ mt: 2 }}
              />
              {formData.siteData?.services?.GSM_CALLS?.active && (
                <Tooltip title={<FormattedMessage {...messages.usersFormAllowCallingTooltip} />}>
                  <div>
                    <Checkbox
                      name="gsmCalls"
                      disabled={!phoneNumberValue}
                      label={
                        <Typography color={phoneNumberValue ? 'textPrimary' : 'secondary'}>
                          <FormattedMessage {...messages.usersFormAllowCallingLabel} />
                        </Typography>
                      }
                      sx={{ mb: 3, ml: 2 }}
                    />
                  </div>
                </Tooltip>
              )}
            </>
          )}
          {showApartmentField && (
            <ApartmentAutocomplete
              hasFormItemKey={hasFormItemKey}
              newItemId={newItemId}
              optionsData={formData.optionsData}
            />
          )}
          {phoneNumberValue && formMethods.watch('gsmCalls') && formMethods.watch('apartment').length === 0 && (
            <Alert severity="warning">
              <FormattedMessage {...messages.usersFormAllowCallingWarning} />
            </Alert>
          )}
        </form>
      </CreateOrEditModalDialog>
    </FormProvider>
  );
}

UserModal.propTypes = {
  context: PropTypes.string.isRequired,
  data: PropTypes.shape({
    companies: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      })
    ),
    email: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lastName: PropTypes.string,
  }),
  isCurrentUser: PropTypes.bool,
  isEdit: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  showApartmentField: PropTypes.bool,
};

UserModal.defaultProps = {
  data: {
    companies: [],
    email: '',
    firstName: '',
    id: null,
    lastName: '',
  },
  isCurrentUser: false,
  isEdit: false,
  open: false,
  showApartmentField: false,
};

export default UserModal;
