export enum SITE_TYPE {
  FAMILY_HOME = 'FAMILY_HOME',
  MDU = 'MDU',
  MIXED_USE = 'MIXED_USE',
  OFFICE = 'OFFICE',
  OTHER = 'OTHER',
  SINGLE_VILLA = 'SINGLE_VILLA',
  UNKNOWN = 'UNKNOWN',
}

export enum SITE_CATEGORY {
  COMMERCIAL = 'COMMERCIAL',
  DEPRECATED = 'DEPRECATED',
  RESIDENTIAL = 'RESIDENTIAL',
}

export enum SITE_SERVICE {
  ACCESS_CONTROL = 'ACCESS_CONTROL',
  ACS = 'ACS',
  ELEVATOR_INTEGRATION = 'ELEVATOR_INTEGRATION',
  GSM_CALLS = 'GSM_CALLS',
  LOCAL_CALLS = 'LOCAL_CALLS',
  MOBILE_VIDEO = 'MOBILE_VIDEO',
  NOTIFICATION = 'NOTIFICATION',
  REMOTE_CONFIGURATION = 'REMOTE_CONFIGURATION',
  INTERCOM_MONITORING = 'INTERCOM_MONITORING',
  REMOTE_API_ACCESS = 'REMOTE_API_ACCESS',
  CREDENTIALS = 'CREDENTIALS',
  RAC = 'RAC',
}
