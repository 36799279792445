import * as yup from 'yup';
import { SiteWithAcCredentialsSettingsResponse } from '../../../apiRtk/be4feApi';

export function getDefaultValues(siteData: SiteWithAcCredentialsSettingsResponse | undefined) {
  return {
    gsmActive: siteData?.services?.GSM_CALLS?.active ?? false,
  };
}

export function getValidationSchema() {
  return yup.object().shape({
    gsmActive: yup.boolean(),
  });
}
