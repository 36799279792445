import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Alert, ButtonLink } from '../../components';
import { MOBILE_VIDEO_STATUS, PATHS } from '../../config/consts';
import { useSiteContext } from '../../context/useSiteContext';
import { getIsValidUntil, getTerminationTime, TIME_UNIT } from '../../helpers/mobileVideo';
import { selectors } from '../../modules/sites';
import { useAppContext } from '../App/AppContextProvider';
import messages from './messages';

const getErrorDisabledData = () => ({
  action: (
    <Button component="a" href="mailto:customercare@2n.com" size="small">
      <FormattedMessage {...messages.alertBoxContactCustomerCare} />
    </Button>
  ),
  message: <FormattedMessage {...messages.alertBoxPlanDisabled} />,
});

const getErrorExpiredData = () => ({
  action: (
    <Button component="a" href="mailto:customercare@2n.com" size="small">
      <FormattedMessage {...messages.alertBoxContactCustomerCare} />
    </Button>
  ),
  message: <FormattedMessage {...messages.alertBoxPlanExpired} />,
});

// eslint-disable-next-line max-statements
const AlertBox = ({
  companyId,
  licenceModel,
  notVerifiedDevices,
  onActivateService,
  onGetNotVerifiedDevices,
  onOpenAvailableCreditModal,
  siteId,
  subscribed,
}) => {
  const { feSiteRoles } = useAppContext();
  const isBillingTypeApartment = useSiteContext().hasSiteBillingType('PER_APARTMENT');
  useEffect(() => {
    onGetNotVerifiedDevices(companyId, siteId);
  }, [companyId, siteId]);

  const getErrorTrialExpiredData = () => ({
    action:
      licenceModel?.requirePaymentCount === 0 && licenceModel?.unlicensedCount > licenceModel?.requirePaymentCount ? (
        <Tooltip placement="right-start" title={<FormattedMessage {...messages.alertBoxKeepUsingDevicesForFree} />}>
          <Button onClick={onActivateService} size="small">
            <FormattedMessage {...messages.alertBoxActivateService} />
          </Button>
        </Tooltip>
      ) : (
        feSiteRoles.isSiteAdminPayment && (
          <ButtonLink
            size="small"
            to={PATHS.SUBSCRIPTION_MOBILE_VIDEO.replace(':companyId', companyId).replace(':siteId', siteId)}
          >
            <FormattedMessage {...messages.alertBoxPurchaseSubscription} />
          </ButtonLink>
        )
      ),
    helperText: (licenceModel?.requirePaymentCount !== 0 ||
      licenceModel?.unlicensedCount <= licenceModel?.requirePaymentCount) && (
      <FormattedMessage {...messages.alertBoxPurchaseSubscriptionHelperText} />
    ),
    message: <FormattedMessage {...messages.alertBoxFreeTrialExpired} />,
  });

  const getErrorStandardExpiredData = () => ({
    action:
      licenceModel?.requirePaymentCount === 0 && licenceModel?.unlicensedCount > licenceModel?.requirePaymentCount ? (
        <Tooltip placement="right-start" title={<FormattedMessage {...messages.alertBoxKeepUsingDevicesForFree} />}>
          <Button onClick={onActivateService} size="small">
            <FormattedMessage {...messages.alertBoxActivateService} />
          </Button>
        </Tooltip>
      ) : (
        feSiteRoles.isSiteAdminPayment && (
          <ButtonLink
            size="small"
            to={PATHS.SUBSCRIPTION_MOBILE_VIDEO.replace(':companyId', companyId).replace(':siteId', siteId)}
          >
            <FormattedMessage {...messages.alertBoxPurchaseSubscription} />
          </ButtonLink>
        )
      ),
    helperText: (licenceModel?.requirePaymentCount !== 0 ||
      licenceModel?.unlicensedCount <= licenceModel?.requirePaymentCount) && (
      <FormattedMessage {...messages.alertBoxPurchaseSubscriptionHelperText} />
    ),
    message: <FormattedMessage {...messages.alertBoxSubscriptionExpired} />,
  });

  const getErrorTemporaryData = () => {
    const time = getTerminationTime(licenceModel?.poolExpiration || licenceModel?.validityTo, TIME_UNIT.HOURS);
    return {
      action: (
        <ButtonLink
          size="small"
          to={PATHS.SUBSCRIPTION_MOBILE_VIDEO.replace(':companyId', companyId).replace(':siteId', siteId)}
        >
          <FormattedMessage {...messages.alertBoxPurchaseSubscription} />
        </ButtonLink>
      ),
      helperText: <FormattedMessage values={{ time }} {...messages.alertBoxTemporaryHelperText} />,
      message: <FormattedMessage values={{ time }} {...messages.alertBoxTemporary} />,
    };
  };

  const getErrorSubscription = () => {
    let data = null;
    if (licenceModel?.status === MOBILE_VIDEO_STATUS.DISABLED) {
      data = getErrorDisabledData();
    } else if (licenceModel?.status === MOBILE_VIDEO_STATUS.EXPIRED) {
      data = getErrorExpiredData();
    } else if (licenceModel?.status === MOBILE_VIDEO_STATUS.TRIAL_EXPIRED) {
      data = getErrorTrialExpiredData();
    } else if (licenceModel?.status === MOBILE_VIDEO_STATUS.STANDARD_EXPIRED) {
      data = getErrorStandardExpiredData();
    } else if (licenceModel?.status === MOBILE_VIDEO_STATUS.TEMPORARY && licenceModel?.requirePaymentCount !== 0) {
      data = getErrorTemporaryData();
    }
    return data;
  };

  const getOneNotVerifiedDeviceData = () => ({
    actionMessageKey: messages.alertBoxVerifyDevice,
    message: (
      <FormattedMessage
        values={{
          name: notVerifiedDevices[0].name,
          strong: (chunks) => <strong>{chunks}</strong>,
        }}
        {...messages.alertBoxNotVerifiedDeviceOne}
      />
    ),
  });

  const getTwoNotVerifiedDeviceData = () => ({
    actionMessageKey: messages.alertBoxVerifyDevices,
    message: (
      <FormattedMessage
        values={{
          name: `${notVerifiedDevices[0].name}, ${notVerifiedDevices[1].name}`,
          strong: (chunks) => <strong>{chunks}</strong>,
        }}
        {...messages.alertBoxNotVerifiedDeviceTwo}
      />
    ),
  });

  const getMoreNotVerifiedDeviceData = () => ({
    actionMessageKey: messages.alertBoxVerifyDevices,
    message: (
      <FormattedMessage
        values={{
          count: notVerifiedDevices.length,
          strong: (chunks) => <strong>{chunks}</strong>,
        }}
        {...messages.alertBoxNotVerifiedDeviceMore}
      />
    ),
  });

  const getErrorVerificationDevices = () => {
    if (notVerifiedDevices.length > 0) {
      let data = null;
      if (notVerifiedDevices.length === 1) {
        data = getOneNotVerifiedDeviceData();
      } else if (notVerifiedDevices.length === 2) {
        data = getTwoNotVerifiedDeviceData();
      } else if (notVerifiedDevices.length > 2) {
        data = getMoreNotVerifiedDeviceData();
      }
      const { actionMessageKey, message } = data;
      return {
        action: (
          <ButtonLink
            size="small"
            to={PATHS.NOT_VERIFIED_DEVICES.replace(':companyId', companyId).replace(':siteId', siteId)}
          >
            <FormattedMessage {...actionMessageKey} />
          </ButtonLink>
        ),
        helperText: <FormattedMessage {...messages.alertBoxNotVerifiedDevicesHelperText} />,
        message,
      };
    }
    return null;
  };

  const getErrorAlertBox = () => {
    const data = getErrorSubscription() || getErrorVerificationDevices();
    if (data) {
      const { action, helperText = null, message } = data;
      return (
        <Alert action={action} icon={<WarningIcon />} severity="error" title={message}>
          {helperText}
        </Alert>
      );
    }
    return null;
  };

  const getMvTerminateWarningMessage = (
    oneDayMessageKey,
    moreDaysMessageKey,
    oneHourMessageKey,
    moreHoursMessageKey
  ) => {
    let message;
    let time = getTerminationTime(licenceModel?.poolExpiration || licenceModel?.validityTo, TIME_UNIT.DAYS);
    if (time === 1) {
      message = <FormattedMessage {...oneDayMessageKey} />;
    } else if (time === 0) {
      time = getTerminationTime(licenceModel?.poolExpiration || licenceModel?.validityTo, TIME_UNIT.HOURS);
      if (time === 1) {
        message = <FormattedMessage {...oneHourMessageKey} />;
      } else {
        message = <FormattedMessage values={{ time }} {...moreHoursMessageKey} />;
      }
    } else {
      message = <FormattedMessage values={{ time }} {...moreDaysMessageKey} />;
    }
    return message;
  };

  const getWarningActiveData = () => ({
    action: (
      <Button component="a" href="mailto:customercare@2n.com" size="small">
        <FormattedMessage {...messages.alertBoxContactCustomerCare} />
      </Button>
    ),
    message: getMvTerminateWarningMessage(
      messages.alertBoxMvServiceSoonTerminateDay,
      messages.alertBoxMvServiceSoonTerminateDays,
      messages.alertBoxMvServiceSoonTerminateHour,
      messages.alertBoxMvServiceSoonTerminateHours
    ),
  });

  const getWarningStandardData = () => ({
    action: (
      <ButtonLink
        size="small"
        to={PATHS.SUBSCRIPTION_MOBILE_VIDEO.replace(':companyId', companyId).replace(':siteId', siteId)}
      >
        <FormattedMessage {...messages.alertBoxActivateRecurringPayment} />
      </ButtonLink>
    ),
    helperTextKey: messages.alertBoxRecurringPaymentHelperText,
    message: getMvTerminateWarningMessage(
      messages.alertBoxMvServiceLastDay,
      messages.alertBoxMvServiceLastDays,
      messages.alertBoxMvServiceLastHour,
      messages.alertBoxMvServiceLastHours
    ),
  });

  const getWarningTrialActiveData = () => {
    const time = getTerminationTime(licenceModel?.poolExpiration || licenceModel?.validityTo, TIME_UNIT.DAYS);
    return {
      action: feSiteRoles.isSiteAdminPayment && (
        <ButtonLink
          size="small"
          to={PATHS.SUBSCRIPTION_MOBILE_VIDEO.replace(':companyId', companyId).replace(':siteId', siteId)}
        >
          <FormattedMessage {...messages.alertBoxPurchaseSubscription} />
        </ButtonLink>
      ),
      helperTextKey: messages.alertBoxPurchaseSubscriptionHelperText,
      message:
        time === 0 || time === 1 ? (
          <FormattedMessage {...messages.alertBoxFreeTrialLastDay} />
        ) : (
          <FormattedMessage values={{ time }} {...messages.alertBoxFreeTrialLastDays} />
        ),
    };
  };

  const getWarningOtherData = () => ({
    action: feSiteRoles.isSiteAdminPayment && (
      <ButtonLink
        size="small"
        to={PATHS.SUBSCRIPTION_MOBILE_VIDEO.replace(':companyId', companyId).replace(':siteId', siteId)}
      >
        <FormattedMessage {...messages.alertBoxPurchaseSubscription} />
      </ButtonLink>
    ),
    message: getMvTerminateWarningMessage(
      messages.alertBoxMvServiceLastDay,
      messages.alertBoxMvServiceLastDays,
      messages.alertBoxMvServiceLastHour,
      messages.alertBoxMvServiceLastHours
    ),
  });

  const getWarningSubscription = () => {
    let data = null;
    if (licenceModel?.requirePaymentCount !== 0 && getIsValidUntil(licenceModel?.status, licenceModel, subscribed)) {
      if (licenceModel?.status === MOBILE_VIDEO_STATUS.ACTIVE && !licenceModel?.allPaidLicencesCoveredByFreeLicences) {
        data = getWarningActiveData();
      } else if (licenceModel?.status === MOBILE_VIDEO_STATUS.TRIAL_ACTIVE) {
        data = getWarningTrialActiveData();
      } else if (licenceModel?.status === MOBILE_VIDEO_STATUS.STANDARD) {
        data = getWarningStandardData();
      } else {
        data = getWarningOtherData();
      }
    }
    return data;
  };

  const getWarningEnoughCreditsData = () => ({
    action: (
      <Button onClick={onOpenAvailableCreditModal} size="small">
        <FormattedMessage {...messages.alertBoxUseAvailableCredits} />
      </Button>
    ),
    helperTextKey: messages.alertBoxMvNotActivatedHelperText,
    message: (
      <FormattedMessage
        {...(isBillingTypeApartment ? messages.alertBoxMvNotActivatedApartment : messages.AlertBoxMvNotActivatedDevice)}
        values={{ count: licenceModel?.unlicensedCount }}
      />
    ),
  });

  const getWarningNotEnoughCreditsData = () => ({
    action: feSiteRoles.isSiteAdminPayment && (
      <ButtonLink
        size="small"
        to={PATHS.SUBSCRIPTION_MOBILE_VIDEO.replace(':companyId', companyId).replace(':siteId', siteId)}
      >
        <FormattedMessage {...messages.alertBoxPurchaseSubscription} />
      </ButtonLink>
    ),
    helperTextKey: messages.alertBoxPurchaseSubscriptionHelperText,
    message: (
      <FormattedMessage
        {...(isBillingTypeApartment ? messages.alertBoxMvNotActivatedApartment : messages.AlertBoxMvNotActivatedDevice)}
        values={{ count: licenceModel?.unlicensedCount }}
      />
    ),
  });

  const getWarningCredits = () => {
    let data = null;
    if (licenceModel?.status === MOBILE_VIDEO_STATUS.STANDARD && licenceModel?.unlicensedCount > 0) {
      if (licenceModel?.additional <= licenceModel?.licencesPool) {
        data = getWarningEnoughCreditsData();
      } else {
        data = getWarningNotEnoughCreditsData();
      }
    }
    return data;
  };

  const getWarningAlertBox = () => {
    const data = getWarningCredits() || getWarningSubscription();
    if (data) {
      const { action, helperTextKey = null, message } = data;
      return (
        <Alert action={action} severity="warning" title={message}>
          {helperTextKey && <FormattedMessage {...helperTextKey} />}
        </Alert>
      );
    }
    return null;
  };

  return getErrorAlertBox() || getWarningAlertBox();
};

AlertBox.propTypes = {
  companyId: PropTypes.number.isRequired,
  licenceModel: PropTypes.shape({
    additional: PropTypes.number,
    allPaidLicencesCoveredByFreeLicences: PropTypes.bool,
    licencesPool: PropTypes.number,
    poolExpiration: PropTypes.string,
    status: PropTypes.string,
    unlicensedCount: PropTypes.number,
    validityTo: PropTypes.string,
  }).isRequired,
  notVerifiedDevices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onActivateService: PropTypes.func.isRequired,
  onGetNotVerifiedDevices: PropTypes.func.isRequired,
  onOpenAvailableCreditModal: PropTypes.func.isRequired,
  siteId: PropTypes.number.isRequired,
  subscribed: PropTypes.bool.isRequired,
};

export default AlertBox;
